import React from "react";

// Custom Components
import CustomDrawer from "components/Drawer/CustomDrawer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// MUI Components
import { IconButton } from "@mui/material";

// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// Constants
import { Icons, Colors } from "utils/Constants";

// 3rd party library
import PropTypes from "prop-types";

export default function ViewTableDrawer({ openDrawer, closeDrawer, drawerWidth, title, children }) {
  return (
    <CustomDrawer defaultAnchor={openDrawer} width={drawerWidth} onDrawerClose={closeDrawer}>
      <MDBox>
        <MDBox
          sx={{ backgroundColor: Colors.PRIMARY, padding: pxToRem(15) }}
          px={pxToRem(24)}
          p={pxToRem(10)}
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <IconButton
            sx={{ cursor: "pointer", color: "beige", marginRight: "15px" }}
            fontSize="medium"
            onClick={closeDrawer}
          >
            {Icons.CROSS}
          </IconButton>
          <MDTypography
            sx={{
              color: "var(--white-900, #ffffff)",
              fontFamily: "Inter",
              fontSize: pxToRem(20),
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: pxToRem(28),
              textAlign: "center",
            }}
          >
            {title}
          </MDTypography>
        </MDBox>
        <MDBox p={pxToRem(24)}>{children}</MDBox>
      </MDBox>
    </CustomDrawer>
  );
}

ViewTableDrawer.propTypes = {
  openDrawer: PropTypes.bool,
  closeDrawer: PropTypes.func.isRequired,
  drawerWidth: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

ViewTableDrawer.defaultProps = {
  openDrawer: false,
  children: null,
  drawerWidth: "70%",
};
