import React, { useEffect, useState } from "react";

import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";

// Custom Components
import FullScreenImageComponent from "components/ViewFullImage/ViewImage";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import DeleteModal from "examples/modal/deleteModal/deleteModal";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import FontComponent from "components/Responsive/fonts";
import CardBreakPoint from "components/Responsive/BreakPoints";

// Constant
import Constants, { Colors, FeatureTags, Icons, ButtonTitles, ModalContent } from "utils/Constants";

// Table Data
import DataTable from "examples/Tables/DataTable";
import ReportStatusData from "layouts/report/data/userReportStatusData";
import ReportOverViewData from "layouts/report/data/reportOverviewData";

// Redux Component
import { Feature } from "flagged";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "redux/Slice/Notification";
import { updateReportUserStatus, resetReportDetailsState } from "redux/Slice/Report";
import { getReportById, updateReportStatus, deleteUserReport } from "redux/Thunks/Report";
import { exportReportPdf } from "redux/Thunks/Filter";

// 3rd party library
import PropTypes from "prop-types";

export default function ReportCardLayout({ children, handleReload }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(true);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [userReportDelete, setUserReportDelete] = useState({
    open: false,
    id: "",
  });
  const { reportDetails, reportDetailsLoading } = useSelector((state) => state.report);
  const ConfigData = useSelector((state) => state.config);
  const permission = ConfigData?.screens?.[6]?.screensInfo?.agreement;
  const customFontSize = FontComponent({ sizes: CardBreakPoint.baseTitleBreakPoint });

  useEffect(() => {
    if (location.state) {
      const { reportId, fetchData } = location.state || { reportId: "", fetchData: false };
      if (fetchData) {
        (async () => {
          const body = {
            userReports: reportId,
          };
          await dispatch(getReportById(body));
        })();
      }
    } else {
      navigate("/client/report");
    }
    return dispatch(resetReportDetailsState());
  }, [location]);

  const handleReportStatusUpdate = async (event) => {
    const { value } = event.target;
    const reportId = location?.state?.reportId;
    const body = {
      userReports: reportId,
      status: value,
    };
    const res = await dispatch(updateReportStatus(body));
    if (res?.payload.status === 200) {
      await dispatch(updateReportUserStatus({ status: value, type: "main" }));
      dispatch(
        openSnackbar({
          message: Constants.REPORT_STATUS_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: Constants.REPORT_STATUS_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const determineStatus = () => {
    const userReports = reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.userReportStatus;
    if (userReports?.some((report) => report.status === "closed")) {
      return "closed";
    }
    if (userReports?.some((report) => report.status === "submitted")) {
      return "submitted";
    }
    return "open";
  };

  const newStateStatus = determineStatus();

  const handleSignatureView = (url) => {
    setFullScreenImage(url);
  };

  const handleCloseFullView = () => {
    setFullScreenImage(null);
  };

  const handleOpenReportDelete = (id) => {
    setUserReportDelete({
      open: true,
      id,
    });
  };

  const handleUserReportDelete = async () => {
    const res = await dispatch(deleteUserReport(userReportDelete.id));
    if (res.payload.status === 200) {
      await dispatch(
        openSnackbar({ message: Constants.USER_REPORT_DELETE_SUCCESS, notificationType: "success" })
      );
      setUserReportDelete({ open: false, id: "" });
      if (reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.userReportStatus?.length === 1) {
        navigate("/client/report");
      } else {
        handleReload();
      }
    } else {
      await dispatch(
        openSnackbar({ message: Constants.REPORT_DELETE_ERROR, notificationType: "error" })
      );
      setUserReportDelete({ open: false, id: "" });
    }
  };

  const { columns, rows } = ReportStatusData(
    reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.userReportStatus,
    handleSignatureView,
    permission,
    handleOpenReportDelete
  );
  const { overViewCoumns, overViewRows } = ReportOverViewData(
    reportDetails?.[0]?.[Constants.MONGOOSE_ID],
    handleReportStatusUpdate,
    newStateStatus,
    permission
  );

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
  });

  const handleExport = async (format) => {
    setExportLoading(true);
    const currentDate = new Date();
    const filename = `Reynard_report_${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.${format}`;
    const body = {
      userReportIds: location?.state?.reportId,
    };
    const res = await dispatch(exportReportPdf(body));
    if (res.error) {
      dispatch(
        openSnackbar({ message: Constants.SOMETHING_WENT_WRONG, notificationType: "error" })
      );
      setExportLoading(false);
      return;
    }
    const url = window.URL.createObjectURL(res.payload);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setExportLoading(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between">
        <PageTitle title="Report Details" />
        {location?.pathname === "/client/report/report-details" &&
          (() => (
            <MDBox display="flex">
              <BasicButton
                title={exportLoading ? ButtonTitles.EXPORTING : ButtonTitles.EXPORT}
                icon={Icons.EXPORT}
                background={Colors.WHITE}
                border
                color={Colors.BLACK}
                disabled={exportLoading}
                action={() => handleExport("pdf")}
              />
              <BasicButton
                icon={Icons.RELOAD}
                background={Colors.WHITE}
                border
                color={Colors.BLACK}
                action={handleReload}
              />
            </MDBox>
          ))()}
      </MDBox>
      <Feature name={FeatureTags.SETUP_REPORT}>
        {!showLoader ? (
          <MDBox>
            <Card id="delete-account" sx={{ mt: 3, px: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <MDBox>
                    <MDBox p={2} display="flex" justifyContent="space-between" alignItems="center">
                      <MDTypography
                        p={pxToRem(7)}
                        variant="h6"
                        fontWeight="medium"
                        sx={{ fontSize: customFontSize }}
                      >
                        Report
                      </MDTypography>
                    </MDBox>
                    <DataTable
                      table={{ columns: overViewCoumns, rows: overViewRows }}
                      isSorted={false}
                      entriesPerPage={{ defaultValue: 4 }}
                      showTotalEntries={false}
                      noEndBorder
                      loading={reportDetailsLoading}
                      licenseRequired
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={12}>
                  {/* Users Reports Status table */}
                  <MDBox p={2} display="flex" justifyContent="space-between" alignItems="center">
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      sx={{ fontSize: customFontSize }}
                    >
                      User Report Status
                    </MDTypography>
                  </MDBox>
                  <MDBox sx={{ marginBottom: pxToRem(16) }}>
                    <Grid item xs={12}>
                      <MDBox>
                        <DataTable
                          table={{ columns, rows }}
                          isSorted={false}
                          entriesPerPage={{ defaultValue: 4 }}
                          showTotalEntries={false}
                          noEndBorder
                          loading={reportDetailsLoading}
                          licenseRequired
                        />
                      </MDBox>
                    </Grid>
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
            {children}
          </MDBox>
        ) : (
          <MDBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {Icons.LOADING2}
          </MDBox>
        )}
        <FullScreenImageComponent
          fullScreenImage={fullScreenImage}
          handleCloseFullView={handleCloseFullView}
          image={fullScreenImage}
          src={fullScreenImage}
        />
        {userReportDelete.open && (
          <DeleteModal
            open={userReportDelete}
            title={ModalContent.USER_REPORT_DELETE_TITLE}
            message={ModalContent.USER_REPORT_DELETE_MESSAGE}
            handleClose={() => setUserReportDelete({ open: false, id: "" })}
            handleDelete={handleUserReportDelete}
          />
        )}
      </Feature>
    </DashboardLayout>
  );
}

ReportCardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  handleReload: PropTypes.func,
};

ReportCardLayout.defaultProps = {
  handleReload: () => {},
};
