import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip"; // Ensure to import Tooltip from the correct library
import MDTypography from "components/MDTypography";
import FontComponent from "components/Responsive/fonts";

function Ellipsis({ maxWidth, title, children, style }) {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);
  const fontSize = FontComponent({ sizes: {} });

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        const hasOverflow = textRef.current.scrollWidth > textRef.current.clientWidth;
        setIsOverflowing(hasOverflow);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow); // Re-check on resize
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [children, maxWidth]);

  const content = (
    <MDTypography
      ref={textRef}
      sx={{
        maxWidth,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        display: "inline-block",
        fontSize,
        ...style,
      }}
    >
      {children}
    </MDTypography>
  );

  return isOverflowing ? <Tooltip title={title}>{content}</Tooltip> : content;
}

Ellipsis.propTypes = {
  maxWidth: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
};

Ellipsis.defaultProps = {
  style: {},
};

export default Ellipsis;
