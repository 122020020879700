import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { Icons, defaultData, Colors } from "utils/Constants";
import Author from "components/Table/Author";
import Status from "components/Table/Status2";
import moment from "moment";

export default function Projects(
  projectList,
  handleOpenNewModal,
  setModalType,
  editLists,
  setEditLists,
  handleDelete
) {
  const [rows, setRows] = useState([]);
  const mongooseId = "_id";

  const handleEdit = (item) => {
    setModalType("Update");
    setEditLists({ ...editLists, project: item });
    handleOpenNewModal("Project");
  };

  useEffect(() => {
    if (projectList) {
      const list = projectList.map((item) => {
        const temp = {
          name: <Author name={item.title} maxContent={defaultData.MEDIUM_CONTENT_LENGTH} />,
          createdAt: <Author name={moment(item.createdAt).format(defaultData.WEB_DATE_FORMAT)} />,
          projectNumber: (
            <Author name={item.projectNumber} maxContent={defaultData.MEDIUM_CONTENT_LENGTH} />
          ),
          client: <Author name={item.client} maxContent={defaultData.MEDIUM_CONTENT_LENGTH} />,
          status: (
            <Status
              color={
                (item?.status.replace("_", " ") === "open" && Colors.DARK_GREEN) ||
                (item?.status.replace("_", " ") === "closed" && Colors.DARK_RED) ||
                (item?.status.replace("_", " ") === "completed" && Colors.DARK_YELLOW)
              }
              bgColor={
                (item?.status.replace("_", " ") === "open" && Colors.LIGHT_GREEN) ||
                (item?.status.replace("_", " ") === "closed" && Colors.LIGHT_RED) ||
                (item?.status.replace("_", " ") === "completed" && Colors.LIGHT_YELLOW)
              }
              title={`${item?.status.replace("_", " ")}`}
            />
          ),
          action: (
            <MDBox>
              <IconButton
                color="secondary"
                fontSize="medium"
                onClick={() => handleEdit(item)}
                sx={{ cursor: "pointer" }}
                disabled={!item?.isDeletable || false}
              >
                {Icons.EDIT}
              </IconButton>{" "}
              &nbsp;
              <IconButton
                color="secondary"
                fontSize="medium"
                onClick={() => handleDelete("Project", item[mongooseId])}
                sx={{ cursor: "pointer" }}
                disabled={!item?.isDeletable || false}
              >
                {Icons.DELETE}
              </IconButton>
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [projectList]);
  return {
    projectColumns: [
      { Header: "Project Name", accessor: "name", align: "left" },
      { Header: "Created At", accessor: "createdAt", align: "left" },
      { Header: "Project Number", accessor: "projectNumber", align: "left", width: "10%" },
      { Header: "Client", accessor: "client", align: "left" },
      { Header: "Status", accessor: "status", align: "left", width: "10%" },
      { Header: "Action", accessor: "action", align: "center", width: "10%" },
    ],
    projectRows: rows,
  };
}
