/* eslint-disable react/function-component-definition */

// Material ui Components
import { Button } from "@mui/material";

// 3rd party library
import PropTypes from "prop-types";

// Custom function
import pxToRem from "assets/theme/functions/pxToRem";
import FontComponent from "components/Responsive/fonts";

// Constants
import { Icons, ButtonTitles } from "utils/Constants";

export default function ResetFilterButton({ handleReset, disabled }) {
  const fontSize = FontComponent({ sizes: {} });

  return (
    <Button
      sx={{
        mt: 5.5,
        mr: 1,
        backgroundColor: "#fff",
        "&:hover": {
          backgroundColor: "#fff",
        },
        fontSize,
        textTransform: "capitalize",
        height: pxToRem(40),
      }}
      variant="outlined"
      color="info"
      onClick={handleReset}
      startIcon={Icons.RESET_FILTER}
      disabled={disabled}
    >
      {ButtonTitles.RESET_FILTER}
    </Button>
  );
}

ResetFilterButton.defaultProps = {
  handleReset: () => {},
  disabled: false,
};
// Add propTypes validation
ResetFilterButton.propTypes = {
  handleReset: PropTypes.func,
  disabled: PropTypes.bool, // Ensure disabled is a boolean and is required
};

// Add defaultProps to set default value for disabled prop
