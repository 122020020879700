/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `page` value is used for a page routing. 
  4. The `type` key with the `divider` value is used for a divider between Sidenav items.
  5. The `name` key is used for the name of the route on the Sidenav.
  6. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  7. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  8. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  9. The `route` key is used to store the route location which is used for the react router.
  10. The `href` key is used to store the external links location.
  11. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  12. The `component` key is used to store the component of its route.
*/

// Admin layouts
import QHSEManagement from "layouts/qhseManagement";
import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/sign-in";
import ResetPassword from "layouts/authentication/reset-password";
import ForgotPassword from "layouts/authentication/forgot-password";
import Profile from "layouts/profile";
import ProjectManagement from "layouts/projectManagement";
import DALShift from "layouts/dalShiftDetails";
import ShiftDetails from "layouts/dalShiftDetails/shiftDetails";
import Dpr from "layouts/dpr";
import Report from "layouts/report";
import ReportCalculation from "layouts/reportCalculation";
import WFMWizard from "layouts/wfmwizard";
import SetupCardCategory from "layouts/wfmwizard/HealthandSafety/SetupCardCategory";
import QhseConfiguration from "layouts/wfmwizard/HealthandSafety/QhseConfiguration";
import Setuplicenses from "layouts/wfmwizard/Organization/Setuplicenses";
import SetupProject from "layouts/wfmwizard/DailyActivity/setupProject/SetupProject";
import Usermanagement from "layouts/wfmwizard/System/userManagement";
import Medicalmanagement from "layouts/wfmwizard/System/medicalManagement";
import Rolemanagement from "layouts/wfmwizard/System/RoleManagement";
import Certificatemanagement from "layouts/wfmwizard/System/CertificateManagement";
import RoleAggrement from "layouts/wfmwizard/System/RoleAgreement";
import Feedback from "layouts/feedback/Feedback";
import ResourceManagement from "layouts/resourceManagemnet";
import Resources from "layouts/resources";
import EquipmentManagement from "layouts/equipmentManagement";
import Approver from "layouts/Approver/allEquipment";
import Warehouse from "layouts/warehouse";
import WarehouseListing from "layouts/warehouse/WarehouseListing";
import WarehouseDetails from "layouts/warehouse/WarehouseDetails";
import WMOrderManagement from "layouts/wmOrdersManagement/Order";
import WMOrderDetails from "layouts/wmOrdersManagement/OrderDetails";
import WMReturnOrderDetails from "layouts/wmOrdersManagement/ReturnOrderDetails";
import ProductDetails from "layouts/resources/productDetails";
import SetupEquipment from "layouts/wfmwizard/Equipment/SetupEquipment";
import NotificationManagement from "layouts/wfmwizard/Actions/systemNotification";
import PersonnelManagement from "layouts/personnelManagement";
import CertificateApproval from "layouts/personnelManagement/CertificateApproval";
import TrainingMatrix from "layouts/personnelManagement/TrainingMatrix";
import ProjectEquipment from "layouts/projectEquipment";
import ReturnEquipmentCart from "layouts/returnCart";
import ProfileFunctions from "layouts/wfmwizard/System/ProfileFunctions";
import ReportDetails from "layouts/report/reportDetails";
import ReportAnswerDetails from "layouts/report/reportAnswerDetails";
import Toolboxtalk from "layouts/toolboxTalk";

// Super Admin Layouts
import Home from "layouts/superadmin/dashboard";
import ClientProfile from "layouts/superadmin/profile";
import LicenseApproval from "layouts/superadmin/LicenseApprovals/LicenseApproval";
import SetupReport from "layouts/wfmwizard/DailyActivity/setupReport/SetupReport";
import ReportQuestions from "layouts/wfmwizard/DailyActivity/setupReport/ReportQuestions";

// @mui icons
import Icon from "@mui/material/Icon";
import { LicensePermission, defaultData } from "utils/Constants";

export const authenticationRoute = [
  {
    type: "page",
    name: "Sign In",
    key: "sign-in",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">SignIn</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "page",
    name: "Forgot Password",
    key: "forgot-password",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">Forgot Password</Icon>,
    route: "/authentication/forgot-password",
    component: <ForgotPassword />,
  },
  {
    type: "page",
    name: "Reset Password",
    key: "reset-password",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">Reset Password</Icon>,
    route: "/authentication/reset-password/:id/:token",
    component: <ResetPassword />,
  },
];

const adminRoutes = [
  {
    type: "collapse",
    name: "Project Management",
    key: "client/project-management",
    parent: "",
    license: [LicensePermission.PROJECT_MANAGEMENT],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/client/project-management",
    component: <ProjectManagement />,
  },
  {
    type: "collapse",
    name: "Shift Details",
    key: "client/shifts",
    parent: "Project Management",
    license: [],
    permissions: [LicensePermission.SHIFT_DETAILS],
    requiredRole: [],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/shifts",
    component: <DALShift />,
  },
  {
    type: "collapse",
    name: "Toolbox Talk",
    key: "client/toolbox-talk",
    parent: "Project Management",
    license: [],
    permissions: [LicensePermission.SHIFT_DETAILS],
    requiredRole: [],
    icon: <Icon fontSize="small">mark_unread_chat_alt_outlined</Icon>,
    route: "/client/toolbox-talk",
    component: <Toolboxtalk />,
  },
  {
    type: "page",
    name: "Shift Details",
    key: "client/shifts",
    parent: "Project Management",
    license: [],
    permissions: [LicensePermission.SHIFT_DETAILS],
    requiredRole: [],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/shifts/:id",
    component: <ShiftDetails />,
  },
  {
    type: "collapse",
    name: "Report",
    key: "client/report",
    parent: "Project Management",
    license: [],
    permissions: [LicensePermission.REPORT],
    requiredRole: [],
    icon: <Icon fontSize="small">report</Icon>,
    route: "/client/report",
    component: <Report />,
  },
  {
    type: "collapse",
    name: "Project Tracker",
    key: "client/project-tracker",
    parent: "Project Management",
    license: [],
    requiredRole: [],
    permissions: [LicensePermission.REPORT],
    icon: <Icon fontSize="small">assessment_outlined</Icon>,
    route: "/client/project-tracker",
    component: <ReportCalculation />,
  },
  {
    type: "page",
    name: "Report",
    key: "client/report/report-details",
    parent: "Project Management",
    license: [],
    permissions: [LicensePermission.REPORT],
    requiredRole: [],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/report/report-details",
    component: <ReportDetails />,
  },
  {
    type: "page",
    name: "Report",
    key: "client/report/report-details/report-answer-details",
    parent: "Project Management",
    license: [],
    permissions: [LicensePermission.REPORT],
    requiredRole: [],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/report/report-details/report-answer-details",
    component: <ReportAnswerDetails />,
  },
  {
    type: "collapse",
    name: "DPR",
    key: "client/dpr",
    parent: "Project Management",
    license: [],
    permissions: [LicensePermission.DPR],
    requiredRole: [],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/dpr",
    component: <Dpr />,
  },
  {
    type: "collapse",
    name: "QHSE Management",
    key: "client/qhse-management",
    parent: "",
    license: [LicensePermission.QHSE_MANAGEMENT],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">ballot</Icon>,
    route: "/client/qhse-management",
    component: <QHSEManagement />,
  },
  {
    type: "collapse",
    name: "QHSE Cards",
    key: "client/qhse-cards",
    parent: "QHSE Management",
    license: [],
    permissions: [
      LicensePermission.SAFE_CARD,
      LicensePermission.UNSAFE_CARD,
      LicensePermission.NCR_CARD,
      LicensePermission.INCIDENT_CARD,
    ],
    requiredRole: [],
    icon: <Icon fontSize="small">style</Icon>,
    route: "/client/qhse-cards",
    component: <Dashboard />,
  },

  {
    type: "collapse",
    name: "Feedback",
    key: "client/feedback",
    parent: "",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">feedback</Icon>,
    route: "/client/feedback",
    component: <Feedback />,
  },

  {
    type: "collapse",
    name: "Resource Management",
    key: "client/resource-management",
    parent: "",
    license: [],
    permissions: [LicensePermission.RESOURCE],
    requiredRole: [],
    icon: <Icon fontSize="small">engineering</Icon>,
    route: "/client/resource-management",
    component: <ResourceManagement />,
  },
  {
    type: "collapse",
    name: "Resources",
    key: "client/resources",
    parent: "Resource Management",
    license: [],
    permissions: [LicensePermission.RESOURCE],
    requiredRole: [],
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/client/resources",
    component: <ResourceManagement />,
  },
  {
    type: "collapse",
    name: "Approvals",
    key: "client/approvals",
    parent: "Resource Management",
    license: [],
    permissions: [LicensePermission.RESOURCE],
    requiredRole: [],
    icon: <Icon fontSize="small">approval</Icon>,
    route: "/client/approvals",
    component: <ResourceManagement />,
  },
  {
    type: "collapse",
    name: "Equipment Management",
    key: "client/equipment-management",
    parent: "",
    license: [LicensePermission.EQUIPMENT_MANAGEMENT],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">construction</Icon>,
    route: "/client/equipment-management",
    component: <EquipmentManagement />,
  },
  {
    type: "collapse",
    name: "Inventory",
    key: "client/inventory",
    parent: "Equipment Management",
    license: [],
    permissions: [LicensePermission.INVENTORY],
    requiredRole: [],
    icon: <Icon fontSize="small">store</Icon>,
    route: "/client/inventory",
    component: <ProductDetails />,
  },
  {
    type: "collapse",
    name: "Register",
    key: "client/register-equipment",
    parent: "Equipment Management",
    license: [],
    permissions: [LicensePermission.INVENTORY],
    requiredRole: [],
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/client/register-equipment",
    component: <Resources />,
  },
  {
    type: "page",
    name: "Register",
    key: "client/register",
    parent: "Equipment Management",
    license: [],
    permissions: [LicensePermission.INVENTORY],
    requiredRole: [],
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/client/update-equipment/:checkEquipmentId",
    component: <Resources />,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "client/orders",
    parent: "Equipment Management",
    license: [],
    permissions: [LicensePermission.WAREHOUSE],
    requiredRole: [],
    icon: <Icon fontSize="small">warehouse</Icon>,
    route: "/client/orders",
    component: <WMOrderManagement />,
  },
  {
    type: "page",
    name: "Order Details",
    key: "client/warehouse",
    parent: "Equipment Management",
    license: [],
    permissions: [LicensePermission.WAREHOUSE],
    requiredRole: [],
    icon: <Icon fontSize="small">warehosue</Icon>,
    route: "/client/orders/order-details/:id",
    component: <WMOrderDetails />,
  },
  {
    type: "page",
    name: "Return Order Details",
    key: "client/warehouse",
    parent: "Equipment Management",
    license: [],
    permissions: [LicensePermission.WAREHOUSE],
    requiredRole: [],
    icon: <Icon fontSize="small">warehosue</Icon>,
    route: "/client/orders/return-orders-details/:id",
    component: <WMReturnOrderDetails />,
  },
  {
    type: "collapse",
    name: "Project Inventory",
    key: "client/project-inventory",
    parent: "Equipment Management",
    license: [],
    permissions: [LicensePermission.INVENTORY],
    requiredRole: [],
    icon: <Icon fontSize="small">shop_outlined</Icon>,
    route: "/client/project-inventory",
    component: <ProjectEquipment />,
  },
  {
    type: "collapse",
    name: "Project Orders",
    key: "client/project-orders",
    parent: "Equipment Management",
    license: [],
    permissions: [LicensePermission.ORDER_APPROVAL],
    requiredRole: [],
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/client/project-orders",
    component: <Approver />,
  },
  {
    type: "page",
    name: "Add Warehouse",
    key: "client/add-warehouse",
    parent: "Equipment Management",
    license: [],
    permissions: [LicensePermission.WAREHOUSE],
    requiredRole: [],
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/client/setting/add-warehouse",
    component: <Warehouse />,
  },
  {
    type: "page",
    name: "Warehouse",
    key: "client/warehouse",
    parent: "Equipment Management",
    license: [],
    permissions: [LicensePermission.WAREHOUSE],
    requiredRole: [],
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/client/setting/warehouse",
    component: <WarehouseListing />,
  },
  {
    type: "page",
    name: "Warehouse Details",
    key: "client/warehouse",
    parent: "Equipment Management",
    license: [],
    permissions: [LicensePermission.WAREHOUSE],
    requiredRole: [],
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/client/setting/warehouse/:id",
    component: <WarehouseDetails />,
  },
  {
    type: "collapse",
    name: "Return Cart",
    key: "client/return-cart",
    parent: "Equipment Management",
    license: [],
    permissions: [LicensePermission.INVENTORY],
    requiredRole: [],
    icon: <Icon fontSize="small">assignment_return</Icon>,
    route: "/client/return-cart",
    component: <ReturnEquipmentCart />,
  },
  {
    type: "page",
    name: "Profile",
    key: "client/profile",
    parent: "",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">person</Icon>,
    route: "/client/profile",
    component: <Profile />,
  },
  {
    type: "page",
    name: "Profile",
    key: "client/profile",
    parent: "",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">person</Icon>,
    route: "/client/setting/user-management/profile/:id",
    component: <Profile />,
  },

  {
    type: "collapse",
    name: "Personnel Management",
    key: "client/personnel-management",
    parent: "",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">manage_accounts</Icon>,
    route: "/client/personnel-management",
    component: <PersonnelManagement />,
  },
  {
    type: "collapse",
    name: "Certificate Approval",
    key: "client/certificate-approval",
    parent: "Personnel Management",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">workspace_premium</Icon>,
    route: "/client/certificate-approval",
    component: <CertificateApproval />,
  },
  {
    type: "collapse",
    name: "Training Matrix",
    key: "client/training-matrix",
    parent: "Personnel Management",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">person outlined</Icon>,
    route: "/client/training-matrix",
    component: <TrainingMatrix />,
  },

  {
    type: "collapse",
    name: "Settings",
    key: "client/setting",
    parent: "",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/client/setting",
    component: <WFMWizard />,
  },
  {
    type: "page",
    name: "Category",
    key: "client/setting",
    parent: "",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/setting/category",
    component: <SetupCardCategory />,
  },
  {
    type: "page",
    name: "QHSe Configuration",
    key: "client/setting/configuration",
    parent: "",
    license: [],
    permissions: ["Safe", "Unsafe", "NCR", "Incident"],
    requiredRole: [],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/setting/configuration",
    component: <QhseConfiguration />,
  },
  {
    type: "page",
    name: "Setup Licenses",
    key: "admin/wizard-licenses",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/setting/wizard-licenses",
    component: <Setuplicenses />,
  },
  {
    type: "page",
    name: "Setup Project",
    key: "admin/project-setup",
    license: [],
    permissions: [],
    requiredRole: [
      defaultData.ADMIN_ROLE,
      defaultData.SUPER_ADMIN_ROLE,
      defaultData.PROJECT_MANAGER,
    ],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/setting/project-setup",
    component: <SetupProject />,
  },
  {
    type: "page",
    name: "Setup Report",
    key: "admin/wizard-report",
    license: [],
    permissions: [],
    requiredRole: [defaultData.ADMIN_ROLE, defaultData.SUPER_ADMIN_ROLE],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/setting/setup-report",
    component: <SetupReport />,
  },
  {
    type: "page",
    name: "Parameter & Figure",
    key: "admin/wizard-parameter-figure",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/setting/setup-report/:setupReportId/questions",
    component: <ReportQuestions />,
  },
  {
    type: "page",
    name: "User Management",
    key: "admin/user-management",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/setting/user-management",
    component: <Usermanagement />,
  },
  {
    type: "page",
    name: "Medical Management",
    key: "admin/medical-management",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/setting/medical-management",
    component: <Medicalmanagement />,
  },
  {
    type: "page",
    name: "Role Management",
    key: "admin/role-management",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/setting/role-management",
    component: <Rolemanagement />,
  },
  {
    type: "page",
    name: "Project Certificate Management",
    key: "admin/certificate -management",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/setting/certificate-management",
    component: <Certificatemanagement />,
  },
  {
    type: "page",
    name: "Role Aggrement",
    key: "admin/role-management",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/setting/role-management/:id",
    component: <RoleAggrement />,
  },
  {
    type: "page",
    name: "Setup Equipment",
    key: "admin/equipment-setup",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/setting/equipment-setup",
    component: <SetupEquipment />,
  },
  {
    type: "page",
    name: "Notification Management",
    key: "client/system-notification",
    parent: "",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/client/setting/system-notification",
    component: <NotificationManagement />,
  },
  {
    type: "page",
    name: "Profile Functions",
    key: "admin/profile-functions",
    license: [],
    permissions: [],
    requiredRole: [],
    icon: <Icon fontSize="small">add card</Icon>,
    route: "/client/setting/profile-functions",
    component: <ProfileFunctions />,
  },
];

export const superAdminRoute = [
  {
    type: "collapse",
    name: "Home",
    key: "admin/home",
    parent: "",
    requiredRole: [],
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/admin/home",
    component: <Home />,
  },
  {
    type: "page",
    name: "Profile",
    key: "profile",
    requiredRole: [],
    icon: <Icon fontSize="small">person2</Icon>,
    route: "/admin/profile/:id",
    component: <ClientProfile />,
  },
  {
    type: "collapse",
    name: "License Approval",
    key: "admin/license-approval",
    parent: "",
    requiredRole: [],
    icon: <Icon fontSize="small">check_circle</Icon>,
    route: "/admin/license-approval",
    component: <LicenseApproval />,
  },
];

export default adminRoutes;
