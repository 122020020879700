import React, { useEffect, useState } from "react";

// Material Common Components
import ConfirmationModal from "examples/modal/Confirmation/Confirmation";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import DeleteModal from "examples/modal/deleteModal/deleteModal";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import BasicModal from "examples/modal/BasicModal/BasicModal";
import CustomButton from "examples/NewDesign/CustomButton";
import pxToRem from "assets/theme/functions/pxToRem";
import PageTitle from "examples/NewDesign/PageTitle";
import FTextField from "components/Form/FTextField";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// Material Components
import { Divider, FormControlLabel, Radio, RadioGroup } from "@mui/material";

// Table
import ProfileFunctionsData from "layouts/wfmwizard/System/data/profileFunctionsData";
import DataTable from "examples/Tables/DataTable";

// Redux
import getProfileFunctions, {
  createProfileFunctions,
  updateProfileFunctions,
  profileFunctionDeleteThunk,
} from "redux/Thunks/ProfileFunctions";
import { openSnackbar } from "redux/Slice/Notification";
import { useDispatch } from "react-redux";

// Utils
import Constants, {
  PageTitles,
  ButtonTitles,
  Colors,
  Icons,
  defaultData,
  ModalContent,
} from "utils/Constants";
import { paramCreater, numericFieldValidation } from "utils/methods/methods";
import Validations from "utils/Validations/index";

function ProfileFunctions() {
  const [filters, setFilters] = useState([
    {
      inputLabel: "Status",
      list: [
        { [Constants.MONGOOSE_ID]: "All", title: "All" },
        { [Constants.MONGOOSE_ID]: "true", title: "Active" },
        { [Constants.MONGOOSE_ID]: "false", title: "In Active" },
      ],
      selectedValue: "All",
    },
  ]);
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const [profileFunction, setProfileFunction] = useState({
    type: "new",
    openModal: false,
    body: {
      name: "",
      sortOrder: "",
      isActive: true,
    },
    errors: {},
    openDeleteModal: false,
    openConfirmationModal: false,
    editDeleteId: "",
  });
  const dispatch = useDispatch();
  const [next, setNext] = useState(0);
  const [loading, setLoading] = useState(false);
  const [profileFunctionError, setProfileFunctionError] = useState({});
  const [profileFunctionsData, setProfileFunctionsData] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(Constants.PENDING);

  const handleFilter = async (filterVale = filters) => {
    setTablePagination({ ...tablePagination, page: 0 });
    const paramData = {
      page: defaultData.PAGE,
      perPage: tablePagination.perPage,
      sort: "asc",
      isActive: filterVale[0].selectedValue === "All" ? "" : filterVale[0].selectedValue,
    };

    const res = await dispatch(getProfileFunctions(paramCreater(paramData)));
    setLoadingStatus(Constants.FULFILLED);
    setProfileFunctionsData(res.payload.data.data);
  };

  const handleResetFilter = () => {
    setFilters((prev) => [{ ...prev[0], selectedValue: "All" }, ...prev.slice(1)]);
    handleFilter([{ ...filters[0], selectedValue: "All" }, ...filters.slice(1)]);
  };

  const handleFilterChange = (e) => {
    const temp = [...filters];
    const { value } = e.target;
    const index = filters.findIndex((filter) => filter.inputLabel === e.target.name);
    temp[index].selectedValue = value;
    setFilters(temp);
    handleFilter(temp);
  };

  const handleTablePagination = async () => {
    const data = {
      page: next + 1,
      perPage: tablePagination.perPage,
      sort: "asc",
      isActive: filters[0].selectedValue === "All" ? "" : filters[0].selectedValue,
    };
    const res = await dispatch(getProfileFunctions(paramCreater(data)));
    if (res.payload.status === 200) {
      setProfileFunctionsData([...profileFunctionsData, ...res.payload.data.data]);
      setNext(res.payload.data.data.length > 0 ? next + 1 : next);
    }
  };

  useEffect(() => {
    (async () => {
      handleFilter();
    })();
  }, []);

  const handleEdit = (data) => {
    setProfileFunction((prevData) => ({
      ...prevData,
      type: "update",
      openModal: true,
      body: { ...data.body },
      editDeleteId: data.id,
    }));
  };

  const handleDelete = (id) => {
    setProfileFunction((prev) => ({ ...prev, openDeleteModal: true, editDeleteId: id }));
  };
  const handleCloseProfileFunctions = () => {
    setProfileFunction((prev) => ({
      ...prev,
      type: "new",
      openModal: false,
      body: {
        name: "",
        isActive: true,
      },
      errors: {},
    }));
    setProfileFunctionError({});
  };

  const handleCloseDeleteProfileFunctionModal = () =>
    setProfileFunction((prev) => ({ ...prev, openDeleteModal: false }));

  const handleCloseConfirmationlModal = () =>
    setProfileFunction((prev) => ({ ...prev, openConfirmationModal: false }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileFunction((prev) => ({ ...prev, body: { ...prev.body, [name]: value } }));
  };

  const profileFunctionsValidation = () => {
    const { body } = profileFunction;
    const errors = {};
    const questionError = Validations.validate("basic", body?.name);
    const sortOrderError = Validations.validate("basic2", body?.sortOrder?.toString());
    if (questionError !== "") {
      errors.name = questionError;
    }
    if (sortOrderError !== "") {
      errors.sortOrder = sortOrderError;
    }
    setProfileFunctionError(errors);
    return Object.values(errors).filter((val) => val !== "").length === 0;
  };

  const handleCreateProfileFunctions = async () => {
    if (profileFunctionsValidation()) {
      setLoading(true);
      const { body } = profileFunction;
      const res = await dispatch(createProfileFunctions(body));
      if (res.payload.status === 200) {
        handleFilter();
        dispatch(
          openSnackbar({
            message: Constants.PROFILE_FUNCTIONS_CREATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        handleCloseProfileFunctions();
      } else if (res.payload.status === 400) {
        const temp = { ...profileFunctionError };
        temp.name = res.payload.data.message;
        setProfileFunctionError(temp);
      } else {
        dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
      setLoading(false);
    }
  };

  const handleUpdateProfileFunctions = async () => {
    setLoading(true);
    if (profileFunctionsValidation()) {
      const { body } = profileFunction;
      const res = await dispatch(updateProfileFunctions(body));
      if (res.payload.status === 200) {
        handleFilter();
        dispatch(
          openSnackbar({
            message: Constants.PROFILE_FUNCTIONS_UPDATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        handleCloseProfileFunctions();
      } else if (res.payload.status === 400) {
        const temp = { ...profileFunctionError };
        temp.name = res.payload.data.message;
        setProfileFunctionError(temp);
      } else {
        dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
    }
    setLoading(false);
  };

  const handleUpdateStatus = async () => {
    const { body } = profileFunction;
    const res = await dispatch(updateProfileFunctions(body));
    if (res.payload.status === 200) {
      handleFilter();
      dispatch(
        openSnackbar({
          message: Constants.PROFILE_FUNCTIONS_UPDATE_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      handleCloseConfirmationlModal();
    } else {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const handleDeleteProfileFunction = async () => {
    const { editDeleteId } = profileFunction;
    const res = await dispatch(profileFunctionDeleteThunk(editDeleteId));
    if (res.payload.status === 200) {
      handleFilter();
      await dispatch(
        openSnackbar({
          message: Constants.PROFILE_FUNCTIONS_DELETE_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      handleCloseDeleteProfileFunctionModal();
    } else {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const handleStatusChange = (event, data) => {
    setProfileFunction((prev) => ({
      ...prev,
      openConfirmationModal: true,
      body: { ...data.body },
      editDeleteId: data?.id,
    }));
  };

  const handleReload = async () => {
    setLoadingStatus(Constants.PENDING);
    handleFilter();
  };
  const { columns, rows } = ProfileFunctionsData(
    profileFunctionsData,
    handleEdit,
    handleDelete,
    handleStatusChange
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <PageTitle title={PageTitles.PROFILE_FUNCTION} />
        <MDBox display="flex" flexDirection="row">
          <CustomButton
            title={ButtonTitles.PROFILE_FUNCTION}
            icon="add_circle_outline"
            background={Colors.PRIMARY}
            color={Colors.WHITE}
            openModal={() => setProfileFunction((prev) => ({ ...prev, openModal: true }))}
          />
          <Divider
            orientation="vertical"
            sx={{
              backgroundColor: "var(--gray-300, #D0D5DD)",
              height: "auto",
              marginLeft: pxToRem(16),
              marginRight: 0,
            }}
          />
          <BasicButton
            icon={Icons.RELOAD}
            background={Colors.WHITE}
            border
            color={Colors.BLACK}
            action={handleReload}
          />
        </MDBox>
      </MDBox>
      <Divider sx={{ marginTop: 2 }} />
      <MDBox display="flex" alignItems="center">
        <MDBox
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="start"
          sx={{ flexDirection: "row", mr: 2 }}
        >
          {filters?.map((val) => (
            <FilterDropdown
              key={val.inputLabel}
              label={val.inputLabel}
              name={val.inputLabel}
              defaultValue={val?.selectedValue}
              value={val?.selectedValue}
              handleChange={handleFilterChange}
              menu={val.list}
            />
          ))}

          <BasicButton
            icon={Icons.RESET_FILTER}
            background={Colors.WHITE}
            border
            color={Colors.BLACK}
            title={ButtonTitles.RESET_FILTER}
            action={handleResetFilter}
            style={{ marginTop: pxToRem(45) }}
          />
        </MDBox>
      </MDBox>
      <MDBox mt={3} mb={3}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          pagination={{ variant: "gradient", color: "info" }}
          loading={loadingStatus}
          currentPage={tablePagination.page}
          handleTablePagination={handleTablePagination}
          handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
        />
      </MDBox>
      {/* Create and Update Profile Function modal */}
      <BasicModal
        open={profileFunction.openModal}
        handleClose={handleCloseProfileFunctions}
        title={
          profileFunction.type === "new"
            ? ModalContent.NEW_PROFILE_FUNCTION_TITLE
            : ModalContent.UPDATE_PROFILE_FUNCTION_TITLE
        }
        disabled={loading}
        actionButton={
          (profileFunction.type === "new" && !loading && ButtonTitles.SUBMIT) ||
          (profileFunction.type === "new" && loading && ButtonTitles.SUBMIT_LOADING) ||
          (profileFunction.type === "update" && !loading && ButtonTitles.UPDATE) ||
          (profileFunction.type === "update" && loading && ButtonTitles.UPDATE_LOADING)
        }
        handleAction={
          profileFunction.type === "new"
            ? handleCreateProfileFunctions
            : handleUpdateProfileFunctions
        }
      >
        <FTextField
          label="Name*"
          placeholder="Name"
          id="name"
          name="name"
          type="text"
          error={Boolean(profileFunctionError?.name)}
          helperText={profileFunctionError?.name}
          value={profileFunction?.body?.name}
          handleChange={handleChange}
          marginBottom={2}
        />
        <FTextField
          label="Sort Order*"
          placeholder="Sort Order"
          id="sortOrder"
          name="sortOrder"
          type="text"
          error={Boolean(profileFunctionError?.sortOrder)}
          helperText={profileFunctionError?.sortOrder}
          value={profileFunction?.body?.sortOrder}
          handleChange={handleChange}
          marginBottom={2}
          onKeyPress={(e) => numericFieldValidation(e)}
        />
        {profileFunction.type === "new" ? (
          <MDBox>
            <MDTypography
              variant="caption"
              mb={1}
              mt={2}
              sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
            >
              Is Active?
            </MDTypography>
            <RadioGroup
              name="isActive"
              value={profileFunction.body.isActive}
              onChange={handleChange}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel label="Yes" value="true" control={<Radio />} />
              <FormControlLabel
                sx={{ marginLeft: "8px" }}
                label="No"
                value="false"
                control={<Radio />}
              />
            </RadioGroup>
          </MDBox>
        ) : null}
      </BasicModal>
      {/* Delete Modal for Profile Function */}
      <DeleteModal
        open={profileFunction.openDeleteModal}
        title={ModalContent.PROFILE_FUNCTION_DELETE_TITLE}
        message={ModalContent.PROFILE_FUNCTION_DELETE_MESSAGE}
        handleClose={handleCloseDeleteProfileFunctionModal}
        handleDelete={handleDeleteProfileFunction}
      />
      {/* Confirmation Modal for Profile Function Status */}
      <ConfirmationModal
        open={profileFunction.openConfirmationModal}
        title={ModalContent.PROFILE_FUNCTION_STATUS_TITLE}
        message={
          profileFunction.body.isActive
            ? ModalContent.PROFILE_FUNCTION_ACTIVE_STATUS_MESSAGE
            : ModalContent.PROFILE_FUNCTION_INACTIVE_STATUS_MESSAGE
        }
        handleClose={handleCloseConfirmationlModal}
        handleAction={handleUpdateStatus}
      />
    </DashboardLayout>
  );
}

export default ProfileFunctions;
