import React, { useEffect, useState } from "react";

// 3rd party library
import { Feature } from "flagged";

// Material components
import MDBox from "components/MDBox";
import { Box, Checkbox, Divider, FormControlLabel, Grid, IconButton } from "@mui/material";

// Custom components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import CustomButton from "examples/NewDesign/CustomButton";
import DeleteModal from "examples/modal/deleteModal/deleteModal";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import pxToRem from "assets/theme/functions/pxToRem";
import ImageUpload from "components/ImageUpload/imageUpload";
import FTextField from "components/Form/FTextField";
import FDropdown, { FormDropdownModal } from "components/Dropdown/FDropdown";
import pattern from "utils/Patterns";

// Table Data
import ReportQuestionsData from "layouts/wfmwizard/DailyActivity/setupReport/data/ReportQuestionsData";

// Constants
import Constants, {
  Icons,
  PageTitles,
  ButtonTitles,
  Colors,
  ModalContent,
  FeatureTags,
  defaultData,
} from "utils/Constants";
import DataTable from "examples/Tables/DataTable";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getReportTypeById,
  createQuestions,
  deleteReportQuestions,
  updateReportType,
  getAllQuestionTypes,
  updateQuestions,
} from "redux/Thunks/Report";
import { openSnackbar } from "redux/Slice/Notification";
import styled from "@emotion/styled";
import MDTypography from "components/MDTypography";
import Validator from "utils/Validations";

// Card Ui
const StyledMDBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),

  borderRadius: theme.spacing(1),
  border: "1px solid #E0E6F5",
  background: "var(--base-white, #FFF)",

  /* Shadow/sm */
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
  padding: theme.spacing(`${pxToRem(24)} ${pxToRem(30)}`), // Default padding for all breakpoints
}));

const initalSupportedContentState = {
  subText: "",
  path: "",
  isPrintable: true,
  key: 0,
  error: {
    subText: "",
    path: "",
  },
};

const intialoptionsState = {
  key: 0,
  title: "",
};

const initialRangeState = {
  min: 0,
  max: 0,
};

const initialAnswerTitleState = {
  key: 0,
  value: "",
  isActive: true,
};

const answerstype = {
  title: [initialAnswerTitleState],
  isPrintable: true,
  key: 0,
  parameterType: "",
  numberOfAnswers: 1,
  options: [],
  range: initialRangeState,
  error: {
    title: "",
    parameterType: "",
    numberOfAnswers: "",
  },
};

const intialQuestionsState = {
  title: "Add New Question",
  disableField: false,
  displayBtn: "flex",
  openForm: false,
  body: {
    title: "",
    sortOrder: 0,
    duration: 0,
    supportedContent: [JSON.parse(JSON.stringify(initalSupportedContentState))],
    answersTypes: [JSON.parse(JSON.stringify(answerstype))],
    isRequired: true,
  },
  editData: null,
  errors: {},
  openDeleteModal: false,
  editDeleteId: "",
  deletedAnswerType: [],
};

const initialSetupReport = {
  report: {},
  loading: Constants.PENDING,
};

function ReportQuestions() {
  const [questionTypesList, setQuestionTypesList] = useState([]);
  const [setupReport, setSetupReport] = useState(initialSetupReport);
  const [questionsData, setQuestionsData] = useState(intialQuestionsState);
  const [submitBtn, setSubmitBtn] = useState(ButtonTitles.CREATE_QUESTION);
  const [isPublishing, setIsPublishing] = useState({
    // 0 for unkonow, 1 for publish, 2 for unpublish, 3 for hide button in create or edit question mode
    published: 0,
    btn: ButtonTitles.PUBLISH,
  });
  const dispatch = useDispatch();
  const { setupReportId } = useParams();
  const navigate = useNavigate();

  const fetchReportTypeById = async () => {
    const [setupReports, questionsType] = await Promise.all([
      dispatch(getReportTypeById(setupReportId)),
      dispatch(getAllQuestionTypes()),
    ]);
    if (questionsType?.payload.status === 200) {
      const tempquestiontypesList = questionsType?.payload.data?.data.map((item) => ({
        label: item.name,
        value: item.uniqueKey,
        key: item[Constants.MONGOOSE_ID],
      }));
      const isReportPublished = setupReports?.payload.data?.data?.isPublish;
      setIsPublishing({
        published: isReportPublished ? 1 : 2,
        btn: isReportPublished ? ButtonTitles.UNPUBLISH : ButtonTitles.PUBLISH,
      });
      setQuestionTypesList(tempquestiontypesList);
    }
    if (setupReports?.payload.status === 200) {
      setSetupReport({ report: setupReports?.payload.data?.data, loading: Constants.FULFILLED });
    } else {
      navigate("/client/setting/setup-report");
    }
  };

  useEffect(() => {
    fetchReportTypeById();
  }, []);

  const handleQuestionsForm = () => {
    if (!questionsData.openForm) setIsPublishing({ ...isPublishing, published: 3 });
    else
      setIsPublishing({
        ...isPublishing,
        published: setupReport.report?.isPublish ? 1 : 2,
      });

    setQuestionsData((prev) => ({ ...intialQuestionsState, openForm: !prev.openForm }));
    setSubmitBtn(ButtonTitles.CREATE_QUESTION);
  };

  const handleResetForm = () => {
    const tempQuestions = {
      ...intialQuestionsState,
    };
    const tempAnswerType = JSON.parse(JSON.stringify(answerstype));
    const tempRange = JSON.parse(JSON.stringify(initialRangeState));
    const tempAnswerTitle = JSON.parse(JSON.stringify(initialAnswerTitleState));
    const tempSupportedContent = JSON.parse(JSON.stringify(initalSupportedContentState));

    tempAnswerType.title = [tempAnswerTitle];
    tempAnswerType.range = tempRange;
    tempAnswerType.options = [];

    tempQuestions.body.answersTypes = [tempAnswerType];
    tempQuestions.body.supportedContent = [tempSupportedContent];

    setQuestionsData(JSON.parse(JSON.stringify(tempQuestions)));
    setIsPublishing({
      ...isPublishing,
      published: setupReport.report?.isPublish ? 1 : 2,
    });
  };

  const handleCloseDeleteQuestionsModal = () =>
    setQuestionsData({
      ...intialQuestionsState,
      openDeleteModal: false,
      editDeleteId: "",
    });

  const handleDeleteQuestionModal = (id) =>
    setQuestionsData({
      ...intialQuestionsState,
      openDeleteModal: true,
      editDeleteId: id,
    });

  const handleSupportedContentAdd = () =>
    setQuestionsData((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        supportedContent: [
          ...prev.body.supportedContent,
          {
            ...initalSupportedContentState,
            key: (prev.body.supportedContent[prev.body.supportedContent.length - 1]?.key || 0) + 1,
          },
        ],
      },
    }));

  const handleAnswerTypeAdd = () => {
    const lastKey = questionsData.body.answersTypes[questionsData.body.answersTypes.length - 1].key;
    let newKey = "";
    if (questionsData.editDeleteId !== "") newKey = `create-${lastKey}`;
    else newKey = lastKey + 1;
    setQuestionsData((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        answersTypes: [
          ...prev.body.answersTypes,
          {
            ...JSON.parse(JSON.stringify(answerstype)),
            key: newKey,
          },
        ],
      },
    }));
  };

  const handleOptionsAdd = (index) => {
    const tempQuestions = [...questionsData.body.answersTypes];
    tempQuestions[index].options.push({
      ...intialoptionsState,
      key: tempQuestions[index].options[tempQuestions[index].options.length - 1].key + 1,
    });
    setQuestionsData((prev) => ({ ...prev, body: { ...prev.body, answersTypes: tempQuestions } }));
  };

  const handleQuestionsChange = (e) => {
    const { name, value } = e.target;
    setQuestionsData((prev) => ({ ...prev, body: { ...prev.body, [name]: value } }));
  };

  const handleOptionalQuestionsChange = (e) => {
    const { name, checked } = e.target;
    setQuestionsData((prev) => ({
      ...prev,
      body: { ...prev.body, [name]: checked, duration: !checked ? 0 : prev.body.duration },
    }));
  };

  const handleSupportingContentChange = (e, index) => {
    const { name, value } = e.target;
    setQuestionsData((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        supportedContent: prev.body.supportedContent.map((item, i) =>
          i === index ? { ...item, [name]: name === "path" ? value?.[0]?.url || "" : value } : item
        ),
      },
    }));
  };

  const handlePrintSupportingContentChange = (e, index) => {
    const { name, checked } = e.target;
    setQuestionsData((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        supportedContent: prev.body.supportedContent.map((item, i) =>
          i === index ? { ...item, [name]: checked } : item
        ),
      },
    }));
  };

  const handleAnswerTypeChange = (e, index) => {
    const { name, value } = e.target;
    let tempOptions = [];
    if (name === "parameterType" && ["dropdown", "checkbox"].includes(value)) {
      tempOptions = [JSON.parse(JSON.stringify(intialoptionsState))];
    }
    setQuestionsData((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        answersTypes: prev.body.answersTypes.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: value,
                options: name === "parameterType" ? tempOptions : item.options,
              }
            : item
        ),
      },
    }));
  };

  const handlePrintAnswerTypeChange = (e, index) => {
    const { name, checked } = e.target;
    setQuestionsData((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        answersTypes: prev.body.answersTypes.map((item, i) =>
          i === index ? { ...item, [name]: checked } : item
        ),
      },
    }));
  };

  const handleNumberOfAnswerChange = (i) => {
    const value = questionsData.body.answersTypes[i].numberOfAnswers;
    const prevValue = questionsData.body.answersTypes[i].title.filter(
      (item) => item.isActive
    ).length;
    let prevTitles = questionsData.body.answersTypes[i].title;
    if (value < prevValue) prevTitles = prevTitles.slice(0, value);
    if (value > prevValue)
      prevTitles = [
        ...prevTitles,
        ...Array.from({ length: value - prevValue }, () => ({ ...initialAnswerTitleState })),
      ];
    setQuestionsData((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        answersTypes: prev.body.answersTypes.map((item, index) =>
          index === i
            ? {
                ...item,
                numberOfAnswers: value,
                title: prevTitles,
              }
            : item
        ),
      },
    }));
  };

  const handleAnserTitleRemove = (i, j) => {
    const questionsArr = [...questionsData.body.answersTypes];
    if (questionsData.editDeleteId !== "" && questionsArr[i].title[j].key.length > 10) {
      questionsArr[i].title[j].isActive = false;
      questionsArr[i].numberOfAnswers = questionsArr[i].title.filter(
        (item) => item.isActive
      ).length;
    } else {
      questionsArr[i].title.splice(j, 1);
      questionsArr[i].numberOfAnswers = questionsArr[i].title.length;
    }
    setQuestionsData((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        answersTypes: questionsArr,
      },
    }));
  };

  const handleAnswerTitleCahnge = (e, i, j) => {
    const { value } = e.target;
    const questionsArr = [...questionsData.body.answersTypes];
    questionsArr[i].title[j].value = value;
    setQuestionsData((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        answersTypes: questionsArr,
      },
    }));
  };

  const handleAnswerTypeOptionChange = (e, answerTypeIndex, optionIndex) => {
    const { name, value } = e.target;
    const questionsArr = [...questionsData.body.answersTypes];
    questionsArr[answerTypeIndex].options[optionIndex][name] = value;
    setQuestionsData((prev) => ({ ...prev, body: { ...prev.body, answersTypes: questionsArr } }));
  };

  const handleRangeChange = (e, i) => {
    const { name, value } = e.target;
    const questionsArr = [...questionsData.body.answersTypes];
    questionsArr[i].range[name] = parseFloat(value);
    setQuestionsData((prev) => ({ ...prev, body: { ...prev.body, answersTypes: questionsArr } }));
  };

  const handleSupportedContentRemove = (index) => {
    const questionsArr = [...questionsData.body.supportedContent];
    questionsArr.splice(index, 1);
    setQuestionsData((prev) => ({
      ...prev,
      body: { ...prev.body, supportedContent: questionsArr },
    }));
  };

  const handleAnswerTypeRemove = (answerTypesIndex) => {
    const questionsArr = [...questionsData.body.answersTypes];
    const deletedId = questionsArr[answerTypesIndex].key;
    questionsArr.splice(answerTypesIndex, 1);
    if (questionsData.editDeleteId !== "")
      setQuestionsData((prev) => ({
        ...prev,
        body: { ...prev.body, answersTypes: questionsArr },
        deletedAnswerType: [...prev.deletedAnswerType, deletedId],
      }));
    else
      setQuestionsData((prev) => ({ ...prev, body: { ...prev.body, answersTypes: questionsArr } }));
  };

  const handleOptionsRemove = (answerTypesIndex, optionsIndex) => {
    const questionsArr = [...questionsData.body.answersTypes];
    questionsArr[answerTypesIndex].options.splice(optionsIndex, 1);
    setQuestionsData((prev) => ({ ...prev, body: { ...prev.body, answersTypes: questionsArr } }));
  };

  const handleDeepCopy = (data) => ({
    title: data?.title || "",
    sortOrder: data?.sortOrder || 0,
    duration: data?.duration || 0,
    isRequired: data?.isRequired || false,
    supportedContent: data?.supportedContent || [],
    answersTypes:
      data?.answerTypes.map((item) => ({
        title:
          JSON.parse(
            JSON.stringify(
              item.title.map((element) => ({
                key: element[Constants.MONGOOSE_ID],
                value: element.value,
                isActive: element.isActive,
              }))
            )
          ) || "",
        parameterType: item.parameterType?.uniqueKey || "",
        numberOfAnswers: item.numberOfAnswers || 0,
        isPrintable: item.isPrintable || false,
        options:
          item.option.map((element) => ({
            key: element[Constants.MONGOOSE_ID],
            title: element.title,
          })) || [],
        key: item[Constants.MONGOOSE_ID],
        range: {
          min: item.range.min || 0,
          max: item.range.max || 0,
        },
      })) || [],
  });

  const handleEditQuestions = (data) => {
    handleResetForm();

    setQuestionsData({
      ...intialQuestionsState,
      editDeleteId: data[Constants.MONGOOSE_ID],
      body: handleDeepCopy(data),
      openForm: true,
      title: "Update Question",
      editData: handleDeepCopy(data),
    });
    setSubmitBtn(ButtonTitles.UPDATE_QUESTION);
    setIsPublishing({ ...isPublishing, published: 3 });
  };

  const handleViewQuestions = (data) => {
    setQuestionsData({
      ...intialQuestionsState,
      disableField: true,
      title: "View Question",
      displayBtn: "none",
      editDeleteId: data[Constants.MONGOOSE_ID],
      body: handleDeepCopy(data),
      openForm: true,
    });
    setSubmitBtn(ButtonTitles.UPDATE_QUESTION);
    setIsPublishing({ ...isPublishing, published: 3 });
  };

  const { columns, rows } = ReportQuestionsData(
    setupReport,
    handleViewQuestions,
    handleEditQuestions,
    handleDeleteQuestionModal,
    isPublishing.published
  );

  const handleDeleteQuestion = async () => {
    const { editDeleteId } = questionsData;
    const res = await dispatch(deleteReportQuestions(editDeleteId));
    if (res?.payload.status === 200) {
      fetchReportTypeById();
      dispatch(
        openSnackbar({
          message: Constants.Question_DELETE_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else if (res?.payload.status === 400) {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
    setQuestionsData((prev) => ({ ...prev, openDeleteModal: false, editDeleteId: "" }));
  };

  // publish only when total weightage is equal to 100
  const handlePublish = async () => {
    setIsPublishing({
      ...isPublishing,
      btn: isPublishing.published === 1 ? ButtonTitles.UNPUBLISH : ButtonTitles.PUBLISH_LOADING,
    });

    const res = await dispatch(
      updateReportType({
        reportTypeId: setupReportId,
        body: { isPublish: !(isPublishing.published === 1) },
      })
    );
    if (res.payload.status === 200) {
      fetchReportTypeById();
      dispatch(
        openSnackbar({
          message:
            isPublishing.published === 1
              ? Constants.SETUP_REPORT_UNPUBLISH_SUCCESS
              : Constants.SETUP_REPORT_PUBLISH_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else if (res.payload.status === 400) {
      dispatch(
        openSnackbar({
          message:
            isPublishing.published === 1
              ? Constants.SETUP_REPORT_UNPUBLISH_ERROR
              : res?.payload?.data?.message,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
      setIsPublishing({
        ...isPublishing,
        btn: isPublishing.published === 1 ? ButtonTitles.UNPUBLISH : ButtonTitles.PUBLISH,
      });
    }
  };

  // Realod Table Data
  const handleReload = async () => {
    handleResetForm();
    setSetupReport({ ...setupReport, loading: Constants.PENDING });
    fetchReportTypeById();
  };

  const validation = () => {
    const { title, sortOrder, duration, isRequired } = questionsData.body;
    const errors = {};

    // Questions Validation
    const titleValidation = Validator.validate("basic2", title);
    if (titleValidation) errors.title = titleValidation;
    if (sortOrder < 0) errors.sortOrder = "Sort Order should not be less than zero";
    else if (!Number.isInteger(sortOrder) && sortOrder % 1 !== 0)
      errors.sortOrder = "Sort Order should be an integer";
    if (isRequired && duration <= 0) errors.duration = "Duration should be more than zero";

    // Answers Types Validation
    const answersTypesValidation = questionsData.body.answersTypes.map((item) => {
      const answerTypeTitleValidation = item.title.map((val) =>
        Validator.validate("basic2", val.value)
      );
      const answerTypeValidation = Validator.validate("basic2", item.parameterType);
      let numberOfAnswersValidation = "";

      if (item.numberOfAnswers < 0)
        numberOfAnswersValidation = item.numberOfAnswers > 0 ? "" : "Invalid Value";
      else if (parseInt(item.numberOfAnswers, 10) !== item.title.filter((k) => k.isActive).length)
        numberOfAnswersValidation =
          "Number of Answer and answer titles are not equal, Click on Generate Answer";
      const optionsValidation = item.options.map((option) => {
        const optionTitleValidation = Validator.validate("basic2", option.title);
        return {
          title: optionTitleValidation,
        };
      });
      let min;
      let max;
      if (item.parameterType === "range") {
        if (Number.isNaN(item.range.min)) min = "Invalid Value";
        if (Number.isNaN(item.range.max)) max = "Invalid Value";
        if (item.range.min === item.range.max) {
          min = "Minimum Value cannot be equal to Maximum Value";
          max = "Maximum Value cannot be equal to Minimum Value";
        } else if (item.range.min > item.range.max) {
          min = "Minimum Value cannot be greater than Maximum Value";
          max = "Maximum Value cannot be less than Minimum Value";
        }
      }
      return {
        ...(answerTypeTitleValidation.filter((val) => val !== "").length !== 0 && {
          title: answerTypeTitleValidation,
        }),
        ...(answerTypeValidation !== "" && { parameterType: answerTypeValidation }),
        ...(numberOfAnswersValidation !== "" && { numberOfAnswers: numberOfAnswersValidation }),
        ...(optionsValidation.filter((element) => element.title !== "").length !== 0 && {
          options: optionsValidation,
        }),
        ...(min && min !== "" && { min }),
        ...(max && max !== "" && { max }),
      };
    });
    if (answersTypesValidation.filter((element) => Object.keys(element).length !== 0).length > 0)
      errors.answersTypes = answersTypesValidation;

    setQuestionsData((prev) => ({ ...prev, errors }));
    return Object.keys(errors).length === 0;
  };

  const handleCreateQuestions = async () => {
    setSubmitBtn(ButtonTitles.CREATE_QUESTION_LOADING);
    const isValid = validation();
    if (isValid) {
      const tempAnswers = questionsData.body.answersTypes.map((item) => {
        const answer = {};
        const parameterId = questionTypesList.find(
          (element) => element.value === item.parameterType
        );
        if (item.parameterType === "range") {
          answer.range = item.range;
        } else {
          answer.range = {
            min: 0,
            max: 0,
          };
        }
        if (item.parameterType === "dropdown" || item.parameterType === "checkbox") {
          answer.option = item.options.map((element) => ({
            ...element,
            title: element.title.trim(),
          }));
        }
        answer.title = item.title.map((element) => ({ value: element.value.trim() }));
        answer.parameterType = parameterId.key;
        answer.numberOfAnswers = item.numberOfAnswers;
        answer.isPrintable = item.isPrintable;
        return answer;
      });
      const body = {
        title: questionsData.body.title.trim(),
        report: setupReportId,
        sortOrder: parseInt(questionsData.body.sortOrder, 10),
        duration: parseFloat(questionsData.body.duration),
        isRequired: questionsData.body.isRequired,
        supportedContent: [
          ...questionsData.body.supportedContent
            .filter((item) => item.subText !== "" || item.path !== "")
            .map((item) => ({
              subText: item.subText.trim(),
              path: item.path,
              isPrintable: item.isPrintable,
            })),
        ],
        answers: tempAnswers,
      };
      const res = await dispatch(createQuestions(body));
      if (res?.payload?.status === 200) {
        handleReload();
        dispatch(
          openSnackbar({
            message: Constants.Question_CREATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
      } else {
        dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
    }
    setSubmitBtn(ButtonTitles.CREATE_QUESTION);
  };

  const handleUpdateQuestion = async () => {
    setSubmitBtn(ButtonTitles.UPDATE_QUESTION_LOADING);
    const isValid = validation();
    if (isValid) {
      const updatedAnswerTypes = [];
      const createAnswerTypes = [];
      questionsData.body.answersTypes.forEach((item) => {
        const previousData = questionsData.editData.answersTypes.find(
          (element) => element.key === item.key
        );
        const { key } = item;
        if (key.startsWith("create")) {
          const temp = {
            ...item,
            parameterType: questionTypesList.find((element) => element.value === item.parameterType)
              .key,
            title: item.title.map((element) => ({ ...element, value: element.value.trim() })),
            option: item.options.map((element) => ({ ...element, title: element.title.trim() })),
            isPrintable: item.isPrintable,
          };
          delete temp.options;
          delete temp.key;
          delete temp.error;
          createAnswerTypes.push(temp);
        } else {
          const { title, options, range } = item;
          const { title: prevTitle, options: prevOpts, range: prevRange } = previousData;

          const temp = { ...item, id: key };
          delete temp.parameterType;
          delete temp.key;

          const activeTitle = title.filter((k) => k.isActive);
          const prevActiveTitle = prevTitle.filter((k) => k.isActive);

          delete temp.numberOfAnswers;
          delete temp.options;

          if (
            activeTitle.map((k) => k.value).join("_") ===
            prevActiveTitle.map((k) => k.value).join("_")
          )
            delete temp.title;
          else {
            temp.title = title.map((k) => {
              if (k.key.length > 10)
                return {
                  [Constants.MONGOOSE_ID]: k.key,
                  value: k.value.trim(),
                  isActive: k.isActive,
                };
              return { value: k.value.trim(), isActive: k.isActive };
            });
            temp.numberOfAnswers = activeTitle.length;
          }

          if (JSON.stringify(options) !== JSON.stringify(prevOpts)) {
            temp.option = options.map((element) => ({
              title: element.title.trim(),
            }));
          }
          if (range.min === prevRange.min && range.max === prevRange.max) delete temp.range;
          if (Object.keys(temp).length > 1) updatedAnswerTypes.push(temp);
        }
      });
      const body = {
        ...(questionsData.body.title !== questionsData.editData?.title && {
          title: questionsData.body.title.trim(),
        }),
        report: setupReportId,
        ...(questionsData.body.sortOrder !== questionsData.editData?.sortOrder && {
          sortOrder: parseInt(questionsData.body.sortOrder, 10),
        }),
        ...(questionsData.body.duration !== questionsData.editData?.duration && {
          duration: parseFloat(questionsData.body.duration),
        }),
        ...(questionsData.body.isRequired !== questionsData.editData?.isRequired && {
          isRequired: questionsData.body.isRequired,
        }),
        supportedContent: [
          ...questionsData.body.supportedContent
            .filter((item) => item.subText !== "" || item.path !== "")
            .map((item) => ({
              subText: item.subText.trim(),
              path: item.path,
              isPrintable: item.isPrintable,
            })),
        ],
        answers: {
          update: updatedAnswerTypes,
          create: createAnswerTypes,
          delete: questionsData.deletedAnswerType.filter((val) => !val.startsWith("create")),
        },
      };
      const res = await dispatch(updateQuestions({ questionId: questionsData.editDeleteId, body }));
      if (res?.payload?.status === 200) {
        handleReload();
        dispatch(
          openSnackbar({
            message: Constants.Question_UPDATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
      } else {
        dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
    }
    setSubmitBtn(ButtonTitles.UPDATE_QUESTION);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        display="flex"
        flexDirection={["column", "row"]}
        justifyContent={["center", "space-between"]}
        alignItems={["center", "flex-start"]}
      >
        <PageTitle
          title={`${PageTitles.REPORT_QUESTIONS} - ${
            setupReport.report?.title?.slice(0, 30) || ""
          }`}
        />
        <MDBox mt={{ lg: 0, sm: 2 }} display="flex" flexWrap="wrap">
          {![0, 3].includes(isPublishing.published) &&
            setupReport.report?.reportQuestions?.length > 0 && (
              <CustomButton
                title={isPublishing.btn}
                icon={Icons.EXPORT}
                background={Colors.PRIMARY}
                color={Colors.WHITE}
                border
                openModal={handlePublish}
              />
            )}

          {[2, 3].includes(isPublishing.published) && (
            <BasicButton
              title={questionsData.openForm ? ButtonTitles.CANCEL : ButtonTitles.ADD_NEW_QUESTIONS}
              icon={questionsData.openForm ? Icons.CLOSE : Icons.NEW}
              background={Colors.WHITE}
              color={Colors.BLACK}
              border
              action={handleQuestionsForm}
            />
          )}
          <Divider
            orientation="vertical"
            sx={{
              backgroundColor: "var(--gray-300, #D0D5DD)",
              height: "auto",
              marginLeft: pxToRem(16),
              marginRight: 0,
            }}
          />
          <BasicButton
            icon={Icons.RELOAD}
            background={Colors.WHITE}
            border
            color={Colors.BLACK}
            action={handleReload}
          />
        </MDBox>
      </MDBox>
      <Divider sx={{ marginTop: pxToRem(22) }} />

      <Feature name={FeatureTags.SETUP_REPORT}>
        <StyledMDBox>
          <MDBox
            sx={{
              width: "100%",
              display: questionsData.openForm ? "flex" : "none",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <MDTypography
              sx={{
                color: "#667085",
                fontFamily: "Inter",
                fontSize: 24,
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: pxToRem(16),
              }}
            >
              {questionsData.title}
            </MDTypography>
            <MDBox
              sx={{
                width: "100%",
                marginTop: pxToRem(16),
              }}
            >
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FTextField
                    label="Title*"
                    placeholder="Add questions title"
                    name="title"
                    id="title"
                    type="text"
                    width="100%"
                    disabled={questionsData.disableField}
                    error={Boolean(questionsData.errors?.title)}
                    helperText={questionsData.errors?.title}
                    value={questionsData.body.title}
                    handleChange={handleQuestionsChange}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FTextField
                    label="Sort Order*"
                    placeholder="Add Sort Order"
                    name="sortOrder"
                    id="sortOrder"
                    type="number"
                    width="100%"
                    disabled={questionsData.disableField}
                    error={Boolean(questionsData.errors?.sortOrder)}
                    helperText={questionsData.errors?.sortOrder}
                    value={questionsData.body.sortOrder}
                    handleChange={(e) =>
                      handleQuestionsChange({
                        target: { name: "sortOrder", value: parseInt(e.target.value, 10) },
                      })
                    }
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FTextField
                    label="Duration*"
                    placeholder="Add Duration"
                    name="duration"
                    id="duration"
                    type="number"
                    width="100%"
                    disabled={!questionsData.body.isRequired || questionsData.disableField}
                    error={Boolean(questionsData.errors?.duration)}
                    helperText={questionsData.errors?.duration}
                    value={questionsData.body.duration}
                    handleChange={handleQuestionsChange}
                  />
                </Grid>
                <Grid item xl={3} lg={4} md={6} sm={6} xs={8}>
                  <MDBox mt="33px">
                    <BasicButton
                      title={ButtonTitles.ADD_SUPPORTED_CONTENT}
                      icon={Icons.NEW}
                      background={Colors.WHITE}
                      color={Colors.BLACK}
                      border
                      style={{
                        height: pxToRem(48),
                        width: "100%",
                        marginLeft: 0,
                        display: questionsData.displayBtn,
                      }}
                      action={handleSupportedContentAdd}
                    />
                  </MDBox>
                </Grid>
                <Grid item xl={3} lg={2} md={3} sm={3} xs={4}>
                  <FormControlLabel
                    label="is Required"
                    sx={{ marginTop: pxToRem(36) }}
                    control={
                      <Checkbox
                        name="isRequired"
                        checked={questionsData.body.isRequired}
                        onChange={handleOptionalQuestionsChange}
                        disabled={questionsData.disableField}
                        sx={{
                          opacity: questionsData.disableField ? 0.5 : 1,
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>

              {questionsData?.body?.supportedContent?.map((item, k) => (
                <Grid
                  container
                  spacing={2}
                  key={item.key}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignContent: "center",
                    marginTop: pxToRem(5),
                  }}
                >
                  <Grid item xl={6} lg={5} md={12} sm={12} xs={12}>
                    <FTextField
                      label="Supported Text"
                      placeholder="Add Supported Text"
                      name="subText"
                      id="subText"
                      width="100%"
                      type="textarea"
                      disabled={questionsData.disableField}
                      defaultRows={4}
                      error={Boolean(item.error?.subText)}
                      helperText={item.error?.subText}
                      value={item.subText}
                      handleChange={(e) => handleSupportingContentChange(e, k)}
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                    <ImageUpload
                      label="Support Image (400X500)"
                      name="path"
                      onImageUpload={(imageValues) =>
                        handleSupportingContentChange(
                          { target: { name: "path", value: imageValues } },
                          k
                        )
                      }
                      onImageCancel={() =>
                        handleSupportingContentChange({ target: { name: "path", value: "" } }, k)
                      }
                      data={[
                        item.path && {
                          name: "",
                          url: item.path,
                          compressedUrl: item.path.replace(pattern.LAST_SLASH, "/compressed/$1"),
                          size: 0,
                        },
                      ]}
                      type="Setup_Report_Image"
                      formats={["image/jpeg", "image/jpg", "image/png", "image/svg+xml"]}
                      acceptType="image/*"
                      maxImageCount={1}
                      error={Boolean(item.error?.path)}
                      helperText={item.error?.path}
                      resetComponent={item.path === ""}
                      imageTypeError={Constants.IMAGE_FILE_TYPE_NOT_ALLOWED}
                      disabled={questionsData.disableField}
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} md={6} sm={2} xs={12}>
                    <MDBox
                      display="flex"
                      flexDirection="row"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <FormControlLabel
                        label="Print Supported Content"
                        sx={{ marginTop: pxToRem(36) }}
                        control={
                          <Checkbox
                            name="isPrintable"
                            checked={item.isPrintable}
                            onChange={(e) => handlePrintSupportingContentChange(e, k)}
                            disabled={questionsData.disableField}
                            sx={{
                              opacity: questionsData.disableField ? 0.5 : 1,
                            }}
                          />
                        }
                      />
                      {questionsData?.body?.supportedContent.length > 1 && (
                        <IconButton
                          aria-label="delete options"
                          color="error"
                          sx={{ paddingTop: pxToRem(45), display: questionsData.displayBtn }}
                          onClick={() => handleSupportedContentRemove(k)}
                        >
                          {Icons.DELETE}
                        </IconButton>
                      )}
                    </MDBox>
                  </Grid>
                </Grid>
              ))}
              <MDTypography variant="h4" style={{ marginTop: pxToRem(10) }}>
                Answers
              </MDTypography>
              {questionsData?.body?.answersTypes?.map((item, i) => (
                <MDBox key={item.key} style={{ marginTop: pxToRem(10) }}>
                  <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={6} sm={12}>
                      <FDropdown
                        label={`Answer Type${i + 1}*`}
                        menu={questionTypesList.map(({ label, value }) =>
                          FormDropdownModal(value, label)
                        )}
                        value={item.parameterType}
                        name="parameterType"
                        handleChange={(name, value, id) =>
                          handleAnswerTypeChange({ target: { name, value, id } }, i)
                        }
                        disabled={
                          questionsData.editDeleteId !== "" &&
                          item.key &&
                          !item.key.startsWith("create")
                        }
                        error={Boolean(questionsData?.errors?.answersTypes?.[i]?.parameterType)}
                        helperText={questionsData?.errors?.answersTypes?.[i]?.parameterType}
                      />
                    </Grid>
                    <Grid item xl={3} lg={2} md={6} sm={12}>
                      <FTextField
                        label={`No. of Answers${i + 1}*`}
                        placeholder="Add No. of Answers"
                        name="numberOfAnswers"
                        id="numberOfAnswers"
                        type="number"
                        width="100%"
                        disabled={questionsData.disableField}
                        error={Boolean(questionsData?.errors?.answersTypes?.[i]?.numberOfAnswers)}
                        helperText={questionsData?.errors?.answersTypes?.[i]?.numberOfAnswers}
                        value={item.numberOfAnswers}
                        handleChange={(e) =>
                          handleAnswerTypeChange(
                            {
                              target: {
                                name: "numberOfAnswers",
                                value: parseInt(e.target.value, 10),
                              },
                            },
                            i
                          )
                        }
                      />
                    </Grid>
                    <Grid item xl={3} lg={4} md={6} sm={10}>
                      <MDBox mt="33px">
                        <BasicButton
                          title={ButtonTitles.GENERATE_ANSWER_TITLE}
                          icon={Icons.NEW}
                          background={Colors.WHITE}
                          color={Colors.BLACK}
                          border
                          style={{
                            height: pxToRem(48),
                            marginLeft: 0,
                            width: "100%",
                            display: questionsData.displayBtn,
                          }}
                          action={() => handleNumberOfAnswerChange(i)}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={2}>
                      <MDBox
                        display="flex"
                        flexDirection="row"
                        justifyContent="start"
                        alignItems="center"
                      >
                        <FormControlLabel
                          label="Print Answer Type"
                          sx={{ marginTop: pxToRem(36) }}
                          control={
                            <Checkbox
                              name="isPrintable"
                              checked={item.isPrintable}
                              onChange={(e) => handlePrintAnswerTypeChange(e, i)}
                              disabled={questionsData.disableField}
                              sx={{
                                opacity: questionsData.disableField ? 0.5 : 1,
                              }}
                            />
                          }
                        />
                        {questionsData?.body?.answersTypes.length > 1 && (
                          <IconButton
                            aria-label="delete options"
                            color="error"
                            sx={{ paddingTop: pxToRem(45), display: questionsData.displayBtn }}
                            onClick={() => handleAnswerTypeRemove(i)}
                          >
                            {Icons.DELETE}
                          </IconButton>
                        )}
                      </MDBox>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    {["dropdown", "checkbox"].includes(item.parameterType) &&
                      (() => (
                        <>
                          {item.options.map((element, j) => (
                            <Grid
                              item
                              lg={3}
                              sm={12}
                              key={element?.key}
                              style={{
                                display: "flex",
                                alignContent: "center",
                              }}
                            >
                              <FTextField
                                label={`Option ${j + 1}*`}
                                placeholder="Add Option"
                                name="title"
                                id="title"
                                type="text"
                                width="100%"
                                disabled={questionsData.disableField}
                                error={Boolean(
                                  questionsData?.errors?.answersTypes?.[i]?.options?.[j]?.title
                                )}
                                helperText={
                                  questionsData?.errors?.answersTypes?.[i]?.options?.[j]?.title
                                }
                                value={element.title}
                                handleChange={(e) => handleAnswerTypeOptionChange(e, i, j)}
                              />

                              {item.options.length > 1 && (
                                <IconButton
                                  aria-label="delete options"
                                  color="error"
                                  sx={{
                                    paddingTop: pxToRem(40),
                                    display: questionsData.displayBtn,
                                  }}
                                  onClick={() => handleOptionsRemove(i, j)}
                                >
                                  {Icons.DELETE}
                                </IconButton>
                              )}
                            </Grid>
                          ))}
                          <Grid item lg={3} sm={12}>
                            <MDBox mt="33px">
                              <BasicButton
                                title={ButtonTitles.ADD_OPTIONS}
                                icon={Icons.NEW}
                                background={Colors.WHITE}
                                color={Colors.BLACK}
                                border
                                style={{
                                  height: pxToRem(48),
                                  marginLeft: 0,
                                  display: questionsData.displayBtn,
                                }}
                                action={() => handleOptionsAdd(i)}
                              />
                            </MDBox>
                          </Grid>
                        </>
                      ))()}
                  </Grid>
                  <Grid container spacing={2} style={{ marginTop: pxToRem(5) }}>
                    {["range"].includes(item.parameterType) &&
                      (() => (
                        <>
                          <Grid item lg={3} sm={12}>
                            <FTextField
                              label={`Minimum ${i + 1}*`}
                              placeholder="Add Minimum Value"
                              name="min"
                              id="min"
                              type="number"
                              width="100%"
                              disabled={questionsData.disableField}
                              error={Boolean(questionsData?.errors?.answersTypes?.[i]?.min)}
                              helperText={questionsData?.errors?.answersTypes?.[i]?.min}
                              value={item.range.min}
                              handleChange={(e) => handleRangeChange(e, i)}
                            />
                          </Grid>
                          <Grid item lg={3} sm={12}>
                            <FTextField
                              label={`Maximum ${i + 1}*`}
                              placeholder="Add Maximum Value"
                              name="max"
                              id="max"
                              type="number"
                              width="100%"
                              disabled={questionsData.disableField}
                              error={Boolean(questionsData?.errors?.answersTypes?.[i]?.max)}
                              helperText={questionsData?.errors?.answersTypes?.[i]?.max}
                              value={item.range.max}
                              handleChange={(e) => handleRangeChange(e, i)}
                            />
                          </Grid>
                        </>
                      ))()}
                  </Grid>
                  {item.title.map((element, j) => {
                    if (element.isActive)
                      return (
                        <Grid
                          key={element?.key}
                          container
                          spacing={2}
                          style={{ marginTop: pxToRem(5) }}
                        >
                          <Grid item lg={6} sm={12}>
                            <FTextField
                              label={`Answer Title${i + 1}.${j + 1}*`}
                              placeholder="Add Text"
                              name="title"
                              id="title"
                              type="text"
                              width="100%"
                              disabled={questionsData.disableField}
                              error={Boolean(questionsData?.errors?.answersTypes?.[i]?.title?.[j])}
                              helperText={questionsData?.errors?.answersTypes?.[i]?.title?.[j]}
                              value={element.value}
                              handleChange={(e) => handleAnswerTitleCahnge(e, i, j)}
                            />
                          </Grid>
                          <Grid item lg={6} sm={12}>
                            {item.title.length > 1 && (
                              <IconButton
                                aria-label="delete options"
                                color="error"
                                sx={{ paddingTop: pxToRem(45), display: questionsData.displayBtn }}
                                onClick={() => handleAnserTitleRemove(i, j)}
                              >
                                {Icons.DELETE}
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      );
                    return "";
                  })}
                </MDBox>
              ))}
              <Grid container spacing={2}>
                <Grid item lg={3} sm={12}>
                  <MDBox mt="33px">
                    <BasicButton
                      title={ButtonTitles.ADD_ANSWERS}
                      icon={Icons.NEW}
                      background={Colors.WHITE}
                      color={Colors.BLACK}
                      border
                      style={{
                        height: pxToRem(48),
                        marginLeft: 0,
                        width: "100%",
                        display: questionsData.displayBtn,
                      }}
                      action={handleAnswerTypeAdd}
                    />
                  </MDBox>
                </Grid>
                <Grid item lg={3} sm={12}>
                  <MDBox mt="33px">
                    <BasicButton
                      title={submitBtn}
                      icon={Icons.NEW}
                      background={Colors.PRIMARY}
                      color={Colors.WHITE}
                      disabled={
                        submitBtn === ButtonTitles.CREATE_QUESTION_LOADING ||
                        submitBtn === ButtonTitles.UPDATE_QUESTION_LOADING
                      }
                      border
                      style={{
                        height: pxToRem(48),
                        marginLeft: 0,
                        display: questionsData.displayBtn,
                      }}
                      action={
                        questionsData.editDeleteId !== ""
                          ? handleUpdateQuestion
                          : handleCreateQuestions
                      }
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
          <MDBox
            style={{
              width: "100%",
            }}
          >
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
              showTotalEntries={false}
              noEndBorder
              loading={setupReport.loading}
            />
          </MDBox>
        </StyledMDBox>

        {/* Delete Modal for Report type */}
        <DeleteModal
          open={questionsData.openDeleteModal}
          title={ModalContent.REPORT_PARAMETER_FIGURE_DELETE_TITLE}
          message={ModalContent.REPORT_PARAMETER_FIGURE_DELETE_MESSAGE}
          handleClose={handleCloseDeleteQuestionsModal}
          handleDelete={handleDeleteQuestion}
        />
      </Feature>
    </DashboardLayout>
  );
}

export default ReportQuestions;
