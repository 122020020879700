// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

function DataTableBodyCell({ align, children, isSticky, index, color, style, isChildRow }) {
  return (
    <MDBox
      component="td"
      textAlign={align}
      py={0.5}
      px={3}
      height="44px"
      width={!isSticky && "141px"}
      sx={({ typography: { size }, borders: { borderWidth } }) => ({
        fontSize: size.sm,
        borderBottom: !isSticky && isChildRow ? "none" : `${borderWidth[1]} solid #E0E6F5`, // Remove bottom border
        borderTop: !isSticky && isChildRow ? "none" : `${borderWidth[1]} solid #E0E6F5`, // Remove top border
        borderRight: !isSticky && `${borderWidth[1]} solid #E0E6F5`,
        background: isSticky ? "#ffffff" : color !== "" && color,
        position: isSticky ? "sticky" : "static", // Change position to sticky or static
        left: isSticky && index === 1 ? 0 : 198,
        zIndex: isSticky ? 10 : "auto",
        boxShadow: isSticky && `inset -${borderWidth[1]} 1px 1px #E0E6F5`,
      })}
      {...(Object.keys(style).length > 0 && { style })}
    >
      <MDBox
        display="inline-block"
        width={isSticky && index === 1 ? "120px" : "100%"}
        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        {children}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  align: "left",
  isSticky: false,
  index: 0,
  color: "",
  style: {},
  isChildRow: false,
  children: null,
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf(["left", "right", "center"]),
  isSticky: PropTypes.bool,
  index: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  isChildRow: PropTypes.bool,
};

export default DataTableBodyCell;
