import React from "react";

// 3rd party library
import PropTypes from "prop-types";

// Custom components
import MDTypography from "components/MDTypography";
import FontComponent from "components/Responsive/fonts";

// Styles
import { ModalTitle } from "../style/styles";

function ModalTitleComponent({ title, color }) {
  const fontSize = FontComponent({ sizes: {} });

  return (
    <MDTypography sx={(theme) => ({ color: { color }, fontSize, ...ModalTitle(theme) })}>
      {title}
    </MDTypography>
  );
}

ModalTitleComponent.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
};

ModalTitleComponent.defaultProps = {
  title: "",
  color: "",
};

export default ModalTitleComponent;
