// MUI Components
import Tooltip from "@mui/material/Tooltip";

// Custom Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FontComponent from "components/Responsive/fonts"; // Import FontComponent

// assets
import Superadmin from "assets/images/superadmin.svg";

// 3rd party
import PropTypes from "prop-types";

export default function By({ name, when, isSuperAdmin }) {
  const fontSize = FontComponent({ sizes: {} }); // Get the dynamic font size

  return (
    <MDBox lineHeight={1} sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
      <MDBox lineHeight={1} sx={{ display: "flex", flexDirection: "row", alignItems: "left" }}>
        <MDTypography
          sx={{ textTransform: "capitalize", fontSize }} // Apply fontSize here
          display="block"
          variant="caption"
        >
          {name ?? ""}
        </MDTypography>
        <Tooltip title="Superadmin">
          <MDTypography variant="caption" sx={{ textTransform: "capitalize", fontSize }}>
            {isSuperAdmin && (
              <img
                src={Superadmin}
                alt="Preview"
                width={15}
                height={15}
                style={{ borderRadius: "8px", marginLeft: "5px" }}
              />
            )}
          </MDTypography>
        </Tooltip>
      </MDBox>
      <MDBox lineHeight={1}>
        <MDTypography
          sx={{ textTransform: "capitalize", fontSize, color: "grey" }} // Apply fontSize here too
          display="block"
          variant="caption"
        >
          {when ?? ""}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

By.propTypes = {
  name: PropTypes.string,
  when: PropTypes.string,
  isSuperAdmin: PropTypes.bool,
};

By.defaultProps = {
  name: "",
  when: "",
  isSuperAdmin: false,
};
