import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import Author from "components/Table/Author";
import By from "components/Table/By";
import CustomImage from "components/Table/GroupImage";

// Material ui Components
import MDBox from "components/MDBox";
import { IconButton } from "@mui/material";

// Utils
import Constants, { Icons, defaultData } from "utils/Constants";

// 3rd party libraries
import moment from "moment";

export default function WmOrderLayerData({
  wmReturnOrderList,
  wmReturnOrderLoading,
  currentFilter,
}) {
  const [returnOrderRow, setReturnOrderRow] = useState([]);
  const navigate = useNavigate();

  const handleView = (id) => {
    navigate(`/client/orders/return-orders-details/${id}`);
  };

  useEffect(() => {
    if (
      wmReturnOrderLoading === Constants.FULFILLED &&
      ["return", "in-stock", "return-rejected"].includes(currentFilter)
    ) {
      const tempRows = wmReturnOrderList.map((element, index) => {
        const temp = {
          srNo: <Author name={index + 1} />,
          project: <Author name={element?.project} />,
          orderId: <Author name={element?.order} />,
          raisedBy: (
            <By
              name={`${element?.returnBy?.name}`}
              when={moment(element?.returnBy?.createdAt).format(defaultData.WEB_DATE_FORMAT)}
            />
          ),
          returnEquipments: (
            <MDBox display="flex" flexDirection="row">
              {element?.equipment?.length > 5
                ? element?.equipment.slice(0, 5).map((item, imgIndex) => (
                    <React.Fragment key={item?.equipmentImage[0]?.[Constants.MONGOOSE_ID]}>
                      <CustomImage
                        item={item?.equipmentImage[0]?.url}
                        index={imgIndex}
                        requestedQuantity={
                          currentFilter === "requested"
                            ? item?.pmRequestedQuantity
                            : item?.wmApprovedQuantity
                        }
                        remainingLength={
                          (element.equipment?.length && element.equipment.length - 5) || 0
                        }
                      />
                    </React.Fragment>
                  ))
                : element?.equipment?.map((item, imgIndex) => (
                    <React.Fragment key={item?.equipmentImage[0]?.[Constants.MONGOOSE_ID]}>
                      <CustomImage
                        item={item?.equipmentImage?.[0]?.url}
                        requestedQuantity={item?.pmDispatchQuantity}
                        index={imgIndex}
                      />
                    </React.Fragment>
                  ))}
            </MDBox>
          ),
          reqItems: <Author name={element?.equipment?.length} />,
          action: (
            <MDBox>
              <IconButton
                aria-label="fingerprint"
                color="info"
                onClick={() => {
                  handleView(element?.returnOrderId);
                }}
              >
                {Icons.VIEW}
              </IconButton>
            </MDBox>
          ),
        };
        return temp;
      });
      setReturnOrderRow(tempRows);
    }
  }, [wmReturnOrderLoading]);

  return {
    returnOrderColumns: [
      { Header: "No.", accessor: "srNo", width: "5%" },
      { Header: "Project", accessor: "project", align: "left" },
      { Header: "Order #", accessor: "orderId", align: "left" },
      { Header: "Order By", accessor: "raisedBy", align: "left" },
      { Header: "Equipment", accessor: "returnEquipments", align: "left" },
      { Header: "Items", accessor: "reqItems", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    returnOrderRow,
  };
}
