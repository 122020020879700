import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { Icons } from "utils/Constants";
import { Icon, IconButton } from "@mui/material";
import Author from "components/Table/Author";

export default function Functions(
  functionList,
  handleOpenNewModal,
  setModalType,
  editLists,
  setEditLists,
  handleDelete,
  setSelectedItems,
  handleFunctionSort,
  sorted
) {
  const [rows, setRows] = useState([]);
  const mongooseId = "_id";

  const handleEdit = (item) => {
    setModalType("Update");
    setEditLists({ ...editLists, function: item });
    setSelectedItems(item?.certificates);
    handleOpenNewModal("Functions");
  };

  useEffect(() => {
    if (functionList) {
      const list = functionList.map((item) => {
        const temp = {
          name: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              <Author name={item?.functionName} />
            </MDTypography>
          ),
          sortOrder: <Author name={item?.sortOrder} />,
          action: (
            <MDBox>
              <IconButton
                color="secondary"
                fontSize="medium"
                onClick={() => handleEdit(item)}
                sx={{ cursor: "pointer" }}
                disabled={!item?.isDeletable || false}
              >
                {Icons.EDIT}
              </IconButton>{" "}
              &nbsp;
              <IconButton
                color="secondary"
                fontSize="medium"
                onClick={() => handleDelete("Function", item[mongooseId])}
                disabled={!item?.isDeletable || false}
              >
                {Icons.DELETE}
              </IconButton>
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [functionList]);

  return {
    FunctionsColumns: [
      {
        Header: () => (
          <div
            onClick={handleFunctionSort}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => e.key === "Enter" && handleFunctionSort()}
            style={{ cursor: "pointer" }}
          >
            Project Function
            <MDBox
              position="absolute"
              top={-3}
              left="25%"
              color={sorted === "asc" ? "text" : "secondary"}
              opacity={sorted === "asc" ? 1 : 0.5}
            >
              <Icon fontSize="medium">arrow_drop_up</Icon>
            </MDBox>
            <MDBox
              position="absolute"
              top={3}
              left="25%"
              color={sorted === "desc" ? "text" : "secondary"}
              opacity={sorted === "desc" ? 1 : 0.5}
            >
              <Icon fontSize="medium">arrow_drop_down</Icon>
            </MDBox>
          </div>
        ),
        accessor: "name",
        width: "80%",
        align: "left",
      },
      { Header: "Sort Order", accessor: "sortOrder", width: "10%", align: "center" },
      { Header: "Action", accessor: "action", width: "10%", align: "right" },
    ],
    FunctionsRows: rows,
  };
}
