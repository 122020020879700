import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ConfigReducer from "redux/Slice/Config";
import SafetyCardReducer from "redux/Slice/SafetyCard";
import DynamicFieldReducer from "redux/Slice/DynamicFields";
import NotificationReducer from "redux/Slice/Notification";
import superAdminReducer from "redux/Slice/SuperAdmin";
import AuthenticationReducer from "redux/Slice/Authentication";
import DalShiftReducer from "redux/Slice/DalShift";
import LicenseReducer from "redux/Slice/License";
import ReportSlice from "redux/Slice/Report";
import FeedbackSlice from "redux/Slice/Feedback";
import RoleAgreement from "redux/Slice/RoleAgreement";
import ProductSlice from "redux/Slice/Equipment";
import WarehouseSlice from "redux/Slice/Warehouse";
import SettingSlice from "redux/Slice/Settings";
import EquipmentReuestSlice from "redux/Slice/EquipmentRequest";
import ReturnEquipmentSlice from "redux/Slice/OrderEquipment";
import SetupEquipmentSlice from "redux/Slice/SetupEquipment";
import FiltersSlice from "redux/Slice/Filter";

export const rootReducer = combineReducers({
  config: ConfigReducer,
  safetCard: SafetyCardReducer,
  dynamicFields: DynamicFieldReducer,
  Notification: NotificationReducer,
  superadmin: superAdminReducer,
  authentication: AuthenticationReducer,
  License: LicenseReducer,
  dalShift: DalShiftReducer,
  report: ReportSlice,
  feedback: FeedbackSlice,
  product: ProductSlice,
  Warehouse: WarehouseSlice,
  setting: SettingSlice,
  equipmentRequest: EquipmentReuestSlice,
  roleAgreement: RoleAgreement,
  returnEquipment: ReturnEquipmentSlice,
  setupEquipment: SetupEquipmentSlice,
  filtersSlice: FiltersSlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["filtersSlice"], // Specify which reducers to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);

export default store;
