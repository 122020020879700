import React, { useEffect, useState } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { Badge, IconButton } from "@mui/material";
import Constants, { Icons } from "utils/Constants";
import User from "assets/images/UserDefault.png";
// Components
import Author from "components/Table/Author";
import pxToRem from "assets/theme/functions/pxToRem";

export default function OrderDetailsData(
  userList,
  status,
  openUserConfirmationBox,
  openEditQuantity,
  list,
  projectId,
  equipmentId,
  openEngineerCommentModal,
  permission
) {
  const [equipmentRows, setEquipmentRows] = useState([]);
  useEffect(() => {
    if (userList) {
      const tempRows = userList?.data?.map((element, index) => {
        let approvedQuantity = null;
        const appQty = list?.map((proj) =>
          proj?.equipmentTypes?.map((equip) =>
            equip?.users?.map((user) => {
              if (
                proj[Constants.MONGOOSE_ID] === projectId &&
                equip[Constants.MONGOOSE_ID] === equipmentId &&
                user?.equipmentRequestId === element?.equipmentRequestId
              ) {
                approvedQuantity = user?.approvedQuantity;
                return (
                  <Author
                    key={`${user?.equipmentRequestId}-${user?.userId}`}
                    name={status === "queue" ? user?.pmApprovedQuantity : user?.approvedQuantity}
                  />
                );
              }
              return null;
            })
          )
        );
        let userTotalAmount = null;
        list?.map((proj) =>
          proj?.equipmentTypes?.map((equip) =>
            equip?.users?.map((user) => {
              if (
                proj[Constants.MONGOOSE_ID] === projectId &&
                equip[Constants.MONGOOSE_ID] === equipmentId &&
                user?.equipmentRequestId === element?.equipmentRequestId
              ) {
                if (status === "pending") {
                  if (user?.totalAmount !== undefined && user?.rentalDays !== null) {
                    userTotalAmount = user.totalAmount * user.rentalDays;
                  } else {
                    userTotalAmount = user?.totalAmount ?? 0;
                  }
                } else if (status === "queue") {
                  if (
                    user?.pmApprovedQuantity !== undefined &&
                    user?.pricePerEquipment !== undefined &&
                    user?.rentalDays
                  ) {
                    userTotalAmount =
                      user.pmApprovedQuantity * user.pricePerEquipment * user.rentalDays;
                  } else {
                    userTotalAmount = user.pmApprovedQuantity * user.pricePerEquipment;
                  }
                }
              }
              return null;
            })
          )
        );
        const temp = {
          srNo: <Author name={index + 1} />,
          requestedBy: (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <img
                src={
                  element?.profileImage === null || element?.profileImage === ""
                    ? User
                    : element?.profileImage
                }
                alt={element?.profileImage}
                key={element?.profileImage}
                style={{
                  width: pxToRem(50),
                  height: pxToRem(50),
                  marginRight: pxToRem(10),
                  borderRadius: "8px",
                }}
              />
              <Author name={`${element?.firstName} ${element?.lastName}`} />
            </MDBox>
          ),
          reqQty: <Author name={element?.engineerRequestedQuantity} />,
          appQty,
          rentdays: <Author name={element?.rentalDays ? element?.rentalDays : "N/A"} />,
          price: <Author name={element?.pricePerEquipment} />,
          updatedAmount: <Author name={userTotalAmount} />,
          action: (
            <MDBox display="flex">
              {status === "pending" && permission?.update ? (
                <MDBox>
                  <IconButton
                    aria-label="fingerprint"
                    color="info"
                    onClick={() => openEditQuantity(element, approvedQuantity)}
                  >
                    {Icons.EDIT2}
                  </IconButton>
                </MDBox>
              ) : null}
              {(status === "pending" || status === "queue") && permission?.update ? (
                <IconButton
                  aria-label="fingerprint"
                  color="info"
                  disabled={element?.engineerComment?.length === 0}
                  onClick={() => openEngineerCommentModal("user", element?.engineerComment)}
                >
                  <Badge
                    badgeContent={element?.engineerComment?.length}
                    color="error"
                    max={9}
                    sx={{ "& .MuiBadge-badge": { fontSize: 10, height: 15, minWidth: 15 } }}
                  >
                    {element?.engineerComment?.length === 0 ? Icons.COMMENT_DISABLE : Icons.COMMENT}
                  </Badge>
                </IconButton>
              ) : null}
              {status !== "requested" && permission?.update ? (
                <IconButton
                  aria-label="fingerprint"
                  color="error"
                  onClick={() => openUserConfirmationBox(element?.equipmentRequestId, "rejected")}
                >
                  {Icons.REJECTED}
                </IconButton>
              ) : null}
            </MDBox>
          ),
        };
        return temp;
      });
      setEquipmentRows([...tempRows]);
    }
  }, [userList, list]);

  const equipmentColumns = [
    { Header: "No.", accessor: "srNo", width: "5%" },
    { Header: "Requested By", accessor: "requestedBy", align: "left" },
    { Header: "Req Qty", accessor: "reqQty", align: "center" },
    { Header: "Approved Qty", accessor: "appQty", align: "center" },
    { Header: "Rental days", accessor: "rentdays", align: "center" },
    { Header: "Price Per Equipment Type", accessor: "price", align: "center" },
  ];
  if (status !== "rejected" && status !== "requested" && status !== "" && permission?.update) {
    equipmentColumns.push({ Header: "Action", accessor: "action", width: "10%", align: "center" });
  }

  const tableData = {
    equipmentColumns,
    equipmentRows,
  };

  return tableData;
}
