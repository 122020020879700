import React from "react";
import { useTheme } from "@emotion/react";

// MUI components
import { Button, Icon, useMediaQuery } from "@mui/material";

// Custom components
import MDTypography from "components/MDTypography";
import FontComponent from "components/Responsive/fonts";

// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// 3rd party library
import PropTypes from "prop-types";

export default function CustomButton({
  title,
  icon,
  width,
  background,
  color,
  openModal,
  disabled = false,
  border = false,
  style = {},
}) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const fontSize = FontComponent({ sizes: {} });

  const handleClick = () => {
    if (typeof openModal === "function") {
      openModal(true);
    }
  };

  return (
    <Button
      disableRipple
      disabled={disabled}
      sx={{
        marginLeft: pxToRem(16),
        backgroundColor: background,
        borderRadius: pxToRem(8),
        "&:hover": {
          backgroundColor: background,
        },
        height: pxToRem(40),
        width: { width },
        paddingX: pxToRem(16),
        border: border ? `1px solid ${color}` : "none",
        marginBottom: md ? 0 : sm && !md && pxToRem(16),
      }}
      style={style}
      onClick={() => handleClick()}
    >
      <Icon fontSize={fontSize} sx={{ color }}>
        {icon}
      </Icon>
      <MDTypography
        fontSize={fontSize}
        sx={{ color, marginLeft: pxToRem(8.6), lineHeight: pxToRem(20) }}
      >
        {title}
      </MDTypography>
    </Button>
  );
}

CustomButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  width: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  openModal: PropTypes.func,
  disabled: PropTypes.bool,
  border: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
};

CustomButton.defaultProps = {
  title: "",
  icon: "",
  width: "",
  background: "",
  color: "",
  openModal: () => {},
  disabled: false,
  border: false,
  style: {},
};
