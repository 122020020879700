import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { Icons } from "utils/Constants";
import { Icon, IconButton } from "@mui/material";

export default function Scopes(
  scopeList,
  handleOpenNewModal,
  setModalType,
  editLists,
  setEditLists,
  handleDelete,
  handleScopeSort,
  sorted
) {
  const [rows, setRows] = useState([]);
  const mongooseId = "_id";

  const handleEdit = (item) => {
    setModalType("Update");
    setEditLists({ ...editLists, scope: item });
    handleOpenNewModal("Scopes");
  };

  useEffect(() => {
    if (scopeList) {
      const list = scopeList.map((item) => {
        const temp = {
          name: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {item.name}
            </MDTypography>
          ),
          isDoable: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {item.isDoable}
            </MDTypography>
          ),
          sortOrder: (
            <MDBox sx={{ width: "max-content" }}>
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.sortOrder}
              </MDTypography>
            </MDBox>
          ),
          action: (
            <MDBox>
              <IconButton
                color="secondary"
                fontSize="medium"
                onClick={() => handleEdit(item)}
                sx={{ cursor: "pointer" }}
                disabled={!item?.isDeletable || false}
              >
                {Icons.EDIT}
              </IconButton>{" "}
              &nbsp;
              <IconButton
                color="secondary"
                fontSize="medium"
                onClick={() => handleDelete("Scope", item[mongooseId])}
                disabled={!item?.isDeletable || false}
              >
                {Icons.DELETE}
              </IconButton>
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [scopeList]);

  return {
    ScopesColumns: [
      {
        Header: () => (
          <div
            onClick={handleScopeSort}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => e.key === "Enter" && handleScopeSort()}
            style={{ cursor: "pointer" }}
          >
            Name
            <MDBox
              position="absolute"
              top={-3}
              left="10%"
              color={sorted === "asc" ? "text" : "secondary"}
              opacity={sorted === "asc" ? 1 : 0.5}
            >
              <Icon fontSize="medium">arrow_drop_up</Icon>
            </MDBox>
            <MDBox
              position="absolute"
              top={3}
              left="10%"
              color={sorted === "desc" ? "text" : "secondary"}
              opacity={sorted === "desc" ? 1 : 0.5}
            >
              <Icon fontSize="medium">arrow_drop_down</Icon>
            </MDBox>
          </div>
        ),
        accessor: "name",
        width: "80%",
        align: "left",
      },
      { Header: "Sort Order", accessor: "sortOrder", width: "10%", align: "left" },
      { Header: "Action", accessor: "action", width: "10%", align: "right" },
    ],
    ScopesRows: rows,
  };
}
