import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { Divider } from "@mui/material";

// Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import CustomButton from "examples/NewDesign/CustomButton";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import pxToRem from "assets/theme/functions/pxToRem";
import FullScreenImageComponent from "components/ViewFullImage/ViewImage";
import EquipmentDetailDrawer from "examples/Drawers/Equiupment/EquipmentDetail";
import DeleteModal from "examples/modal/deleteModal/deleteModal";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import BasicModal from "examples/modal/BasicModal/BasicModal2";
import ResetFilterButton from "components/Buttons/ResetButton";

// Data
import DataTable from "examples/Tables/DataTable";
import ProductData from "layouts/resources/data/productData";
import StockUpdateData from "layouts/resources/data/stockUpdateData";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "redux/Slice/Notification";
import ProductListThunk, {
  equipmentDeleteThunk,
  equipmentStockUpdateThunk,
} from "redux/Thunks/Equipment";
import { warehouseListThunk } from "redux/Thunks/Warehouse";
import {
  reloadData,
  resetListState,
  updateStockCheckbox,
  updateQuantity,
  deleteStock,
} from "redux/Slice/Equipment";

// Constants
import Constants, {
  Icons,
  Colors,
  ModalContent,
  PageTitles,
  defaultData,
  ButtonTitles,
} from "utils/Constants";
import SearchBar from "components/Search/SearchInTable";
import { paramCreater } from "utils/methods/methods";

function ProductDetails() {
  const [tablePagination, setTablePagination] = useState({
    page: defaultData.PAGE,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL_2,
  });
  let debounceTimeout;
  const [filters, setFilters] = useState([
    {
      inputLabel: "Missing",
      list: [
        { [Constants.MONGOOSE_ID]: "select", title: "Select" },
        { [Constants.MONGOOSE_ID]: "all", title: "All" },
        { [Constants.MONGOOSE_ID]: "qr", title: "QR" },
        { [Constants.MONGOOSE_ID]: "certificate", title: "Certificate" },
        { [Constants.MONGOOSE_ID]: "certificate_expired", title: "Certificate Expired" },
        {
          [Constants.MONGOOSE_ID]: "certificate_expire_in_30",
          title: "Certificate Expiring in 30 days",
        },
        {
          [Constants.MONGOOSE_ID]: "certificate_expire_in_60",
          title: "Certificate Expiring in 60 days",
        },
      ],
      selectedValue: "select",
    },
    {
      inputLabel: "Warehouse",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }],
      selectedValue: "all",
    },
    {
      inputLabel: "Category",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }],
      selectedValue: "all",
      isLoading: false,
    },
    {
      inputLabel: "Type",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }],
      selectedValue: "all",
      isLoading: false,
    },
    {
      inputLabel: "Product Number",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }],
      selectedValue: "all",
      isLoading: false,
    },
    {
      inputLabel: "Name",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }],
      selectedValue: "all",
      isLoading: false,
    },
    {
      inputLabel: "Serial number",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }],
      selectedValue: "all",
      isLoading: false,
    },
  ]);
  const [next, setNext] = useState(0);
  const [stockShow, setStockShow] = useState(false);
  const [stockEdit, setStockEdit] = useState({
    data: [],
    open: false,
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [equipmentId, setEquipmentId] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  // use for handling equipment drawer
  const [equipmentAnchor, setEquipmentAnchor] = useState({ right: false });
  const [equipmentDrawerId, setEquipmentDrawerId] = useState("");
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.product);
  const stockData = useSelector((state) => state.product);
  const ConfigData = useSelector((state) => state.config);
  const permission = ConfigData?.screens?.[9]?.screensInfo?.agreement;
  const fetchProductList = async (filterVale = filters) => {
    setTablePagination({ ...tablePagination, page: defaultData.PAGE });
    setNext(0);
    const paramData = {
      page: defaultData.PAGE,
      perPage: tablePagination.perPage,
      ...(filterVale[0].selectedValue !== "select" && { missing: filterVale[0].selectedValue }),
    };
    if (
      filterVale[2].selectedValue !== "all" &&
      filterVale[2].selectedValue !== null &&
      filterVale[2].selectedValue !== "All"
    ) {
      paramData.equipmentCategory = filterVale[2].selectedValue.trim();
    }
    if (
      filterVale[3].selectedValue !== "all" &&
      filterVale[3].selectedValue !== null &&
      filterVale[3].selectedValue !== "All"
    ) {
      paramData.type = filterVale[3].selectedValue.trim();
    }
    if (
      filterVale[4].selectedValue !== "all" &&
      filterVale[4].selectedValue !== null &&
      filterVale[4].selectedValue !== "All"
    ) {
      paramData.equipmentNumber = filterVale[4].selectedValue.trim();
    }
    if (
      filterVale[5].selectedValue !== "all" &&
      filterVale[5].selectedValue !== null &&
      filterVale[5].selectedValue !== "All"
    ) {
      paramData.name = filterVale[5].selectedValue.trim();
    }
    if (
      filterVale[6].selectedValue !== "all" &&
      filterVale[6].selectedValue !== null &&
      filterVale[6].selectedValue !== "All"
    ) {
      paramData.serialNumber = filterVale[6].selectedValue.trim();
    }

    Object.keys(paramData).forEach((key) => {
      if (paramData[key] === "") {
        delete paramData[key];
      }
    });

    const data = new URLSearchParams(paramData);
    await dispatch(ProductListThunk(data));
  };
  useEffect(() => {
    fetchProductList(filters);
    (async () => {
      const paramData = {
        isActive: true,
      };
      const data = new URLSearchParams(paramData);
      const [warehouseList] = await Promise.all([dispatch(warehouseListThunk(data))]);
      const temp = [...filters];
      const dropdownFormat = {
        [Constants.MONGOOSE_ID]: "",
        title: "",
      };

      temp[1].list = [
        { [Constants.MONGOOSE_ID]: "all", title: "All" },
        ...warehouseList.payload.data.data.map((item) => {
          const temp3 = { ...dropdownFormat };
          temp3[Constants.MONGOOSE_ID] = item?.[Constants.MONGOOSE_ID];
          temp3.title = item.name;
          return temp3;
        }),
      ];
      setFilters(temp);
    })();

    return dispatch(resetListState());
  }, []);

  const handleImageFullView = (imageUrl) => {
    setFullScreenImage(imageUrl);
  };
  const handleCloseFullView = () => {
    setFullScreenImage(null);
  };

  // open Equipment drawer from rigth
  const handleOpenEquipmentDetailDrawer = async (id) => {
    setEquipmentAnchor({ right: true });
    setEquipmentDrawerId(id);
  };
  const handleCloseEquipmentDetailDrawer = async (id) => {
    setEquipmentAnchor({ right: false });
    setEquipmentDrawerId(id);
  };
  const handleDeleteOpen = (id) => {
    setEquipmentId(id);
    setDeleteModalOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setEquipmentId("");
  };
  const handleDeleteEquipment = async () => {
    const res = await dispatch(equipmentDeleteThunk(equipmentId));
    if (res.payload.status === 200) {
      await fetchProductList();
      handleCloseDeleteModal();

      await dispatch(
        openSnackbar({
          message: Constants.EQUIPMENT_DELETE_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else if (res.payload.status === 400) {
      await dispatch(
        openSnackbar({
          message: Constants.EQUIPMENT_DELETE_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
    setEquipmentId("");
  };

  const handleChange = async (element, checked) => {
    await dispatch(updateStockCheckbox({ equipmentData: element, checked }));
  };

  useEffect(() => {
    (async () => {
      if (!stockShow) {
        await dispatch(resetListState());
        await dispatch(fetchProductList());
      }
    })();
  }, [stockShow]);

  const handleStockChange = async (e, Id, name) => {
    setStockEdit({ ...stockEdit, open: true });
    await dispatch(updateQuantity({ target: e.target.value, equipmentId: Id, name }));
  };

  const handleUpdateStock = async () => {
    const isValid = !stockData.stockData.some((item) => item.stockQuantity === 0);
    if (isValid) {
      const body = stockData.stockData.map((item) => ({
        equipment: item[Constants.MONGOOSE_ID],
        quantity: item?.totalStock,
      }));
      setButtonLoading(true);
      setDisableSubmit(true);
      const res = await dispatch(equipmentStockUpdateThunk(body));
      setDisableSubmit(false);
      if (res.payload.status === 200) {
        setStockEdit({ ...stockEdit, open: false });
        setStockShow(false);
        setButtonLoading(false);
        await dispatch(resetListState());
        await dispatch(fetchProductList());
        await dispatch(
          openSnackbar({
            message: Constants.STOCKS_UPDATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
      } else {
        await dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
    } else {
      await dispatch(
        openSnackbar({
          message: Constants.STOCK_VALIDATION,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const handleDeleteStock = async (deleteId) => {
    await dispatch(deleteStock({ deleteId }));
  };

  // Equipment Table data
  const { columns, rows } = ProductData(
    productList.list,
    handleImageFullView,
    handleDeleteOpen,
    handleOpenEquipmentDetailDrawer,
    permission,
    stockShow,
    handleChange,
    stockData
  );

  const { stockColumns, stockRows } = StockUpdateData(
    stockData.stockData,
    handleStockChange,
    handleDeleteStock
  );

  const handleTablePagination = async () => {
    const paramData = {
      page: next + 1,
      perPage: tablePagination.perPage,
      ...(filters[0].selectedValue !== "select" && { missing: filters[0].selectedValue }),
    };
    if (
      filters[2].selectedValue !== "all" &&
      filters[2].selectedValue !== null &&
      filters[2].selectedValue !== "All"
    ) {
      paramData.equipmentCategory = filters[2].selectedValue.trim();
    }
    if (
      filters[3].selectedValue !== "all" &&
      filters[3].selectedValue !== null &&
      filters[3].selectedValue !== "All"
    ) {
      paramData.type = filters[3].selectedValue.trim();
    }
    if (
      filters[4].selectedValue !== "all" &&
      filters[4].selectedValue !== null &&
      filters[4].selectedValue !== "All"
    ) {
      paramData.equipmentNumber = filters[4].selectedValue.trim();
    }
    if (
      filters[5].selectedValue !== "all" &&
      filters[5].selectedValue !== null &&
      filters[5].name !== "All"
    ) {
      paramData.name = filters[5].selectedValue.trim();
    }
    if (
      filters[6].selectedValue !== "all" &&
      filters[6].selectedValue !== null &&
      filters[6].selectedValue !== "All"
    ) {
      paramData.serialNumber = filters[6].selectedValue.trim();
    }

    Object.keys(paramData).forEach((key) => {
      if (paramData[key] === "") {
        delete paramData[key];
      }
    });

    const data = new URLSearchParams(paramData);
    const res = await dispatch(ProductListThunk(data));
    if (res.payload.status === 200) {
      setNext(res.payload.data.data.length > 0 ? next + 1 : next);
    }
  };

  const handleReload = async () => {
    await dispatch(reloadData());
    fetchProductList();
  };

  const handleFilterType = async (e) => {
    if (e.target.value === "") return;
    const temp = [...filters];
    const i = temp.findIndex((item) => item.inputLabel === e.target.name);
    temp[i].selectedValue = e.target.value;
    setFilters(temp);
    await fetchProductList(temp);
  };

  const handleSearch = async (searchValue = filters, filterIndex) => {
    setFilters((prev) => {
      const temp = [...prev];
      temp[filterIndex].isLoading = true;
      return temp;
    });

    const paramData = {
      page: 0,
      perPage: tablePagination.perPage,
      ...(searchValue[0].selectedValue !== "select" && { missing: searchValue[0].selectedValue }),
      ...(searchValue[2].selectedValue !== "all" &&
        searchValue[2].selectedValue !== null &&
        searchValue[2].selectedValue !== "All" && {
          equipmentCategory: searchValue[2].selectedValue.trim(),
        }),
      ...(searchValue[3].selectedValue !== "all" &&
        searchValue[3].selectedValue !== null &&
        searchValue[3].selectedValue !== "All" && {
          type: searchValue[3].selectedValue.trim(),
        }),
      ...(searchValue[4].selectedValue !== "all" &&
        searchValue[4].selectedValue !== null &&
        searchValue[4].selectedValue !== "All" && {
          equipmentNumber: searchValue[4].selectedValue.trim(),
        }),
      ...(searchValue[5].selectedValue !== "all" &&
        searchValue[5].selectedValue !== null &&
        searchValue[5].selectedValue !== "All" && {
          name: searchValue[5].selectedValue.trim(),
        }),
      ...(searchValue[6].selectedValue !== "all" &&
        searchValue[6].selectedValue !== null &&
        searchValue[6].selectedValue !== "All" && {
          serialNumber: searchValue[6].selectedValue.trim(),
        }),
    };

    try {
      const res = await dispatch(ProductListThunk(paramCreater(paramData)));
      const temp = [...searchValue];

      let suggestions;
      switch (searchValue[filterIndex].inputLabel) {
        case "Name":
          suggestions = res.payload.data.data.map((item) => ({
            [Constants.MONGOOSE_ID]: item[Constants.MONGOOSE_ID],
            title: item.name,
          }));
          break;
        case "Serial number":
          suggestions = res.payload.data.data.map((item) => ({
            [Constants.MONGOOSE_ID]: item[Constants.MONGOOSE_ID],
            title: item?.serialNumber !== null ? item?.serialNumber : "",
          }));
          break;
        case "Product Number":
          suggestions = res.payload.data.data.map((item) => ({
            [Constants.MONGOOSE_ID]: item[Constants.MONGOOSE_ID],
            title: item.equipmentNumber,
          }));
          break;
        case "Type":
          suggestions = res.payload.data.data.map((item) => ({
            [Constants.MONGOOSE_ID]: item[Constants.MONGOOSE_ID],
            title: item.equipmentType.type,
          }));
          break;
        case "Category":
          suggestions = res.payload.data.data.map((item) => ({
            [Constants.MONGOOSE_ID]: item[Constants.MONGOOSE_ID],
            title: item.equipmentType.equipmentCategory[0].name,
          }));
          break;
        default:
          suggestions = [];
      }

      const uniqueSuggestions = suggestions.reduce((acc, current) => {
        const duplicate = acc.find((item) => item.title === current.title);
        if (!duplicate) {
          acc.push(current);
        }
        return acc;
      }, []);

      // Add the 'All' option and update the filter list
      temp[filterIndex].list = [
        { [Constants.MONGOOSE_ID]: "all", title: "All" },
        ...uniqueSuggestions,
      ];
      temp[filterIndex].isLoading = false;

      setFilters(temp);
    } catch (error) {
      setFilters((prev) => {
        const temp = [...prev];
        temp[filterIndex].isLoading = false;
        return temp;
      });
    }
  };

  const debounce =
    (func, delay) =>
    (...args) => {
      const context = this;
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => func.apply(context, args), delay);
    };

  const debouncedHandleSearch = debounce((e, filterIndex) => {
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const temp = [...filters];
    temp[filterIndex].selectedValue = e.target.value;
    handleSearch(temp, filterIndex);
  }, 300);

  const handleReset = async () => {
    await dispatch(reloadData());
    const tempFilters = filters.map((filter, index) => {
      if (index === 0) {
        return { ...filter, selectedValue: "select" };
      }
      return { ...filter, selectedValue: "all" };
    });
    setFilters(tempFilters);
    await fetchProductList(tempFilters);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <PageTitle title={PageTitles?.EQUIPMENT} />
        <MDBox display="flex" flexDirection="row">
          {permission?.create && (
            <Link to="/client/register-equipment">
              <CustomButton
                title="Register"
                icon="add_circle_outline"
                background="#191A51"
                color="#ffffff"
              />
            </Link>
          )}
          <Divider
            orientation="vertical"
            sx={{
              backgroundColor: "var(--gray-300, #D0D5DD)",
              height: "auto",
              marginLeft: pxToRem(16),
              marginRight: 0,
            }}
          />
          <BasicButton
            icon={Icons.RELOAD}
            background={Colors.WHITE}
            border
            color={Colors.BLACK}
            action={handleReload}
          />
        </MDBox>
      </MDBox>
      <Divider sx={{ marginTop: 2 }} />
      <MDBox display="flex" justifyContent="space-between">
        <MDBox>
          <MDBox
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "start",
              alignItems: "flex-end",
            }}
            mx={0}
          >
            {/* Search bar */}
            {filters.slice(2).map((item, index) => (
              <SearchBar
                freeSolos
                width={pxToRem(200)}
                key={item.inputLabel.replace(" ", "")}
                options={item?.list.map((val) => val?.title) || []}
                filters={filters}
                label={item?.inputLabel}
                value={
                  item?.selectedValue
                    ? item.selectedValue.charAt(0).toUpperCase() +
                      item.selectedValue.substring(1).toLowerCase()
                    : ""
                }
                placeholder={item?.inputLabel}
                isLoading={item?.isLoading}
                debouncedHandleSearch={(e) => debouncedHandleSearch(e, index + 2)}
                handleFilterChange={(e, value) =>
                  debouncedHandleSearch({ target: { name: item.inputLabel, value } }, index + 2)
                }
              />
            ))}
            <MDBox
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "start",
                alignItems: "flex-end",
              }}
            >
              {filters
                .slice(0, 1)
                .filter((val) => val.inputLabel !== "Search")
                ?.map((val) => (
                  <FilterDropdown
                    key={val.inputLabel}
                    label={val.inputLabel}
                    name={val.inputLabel}
                    defaultValue={val?.selectedValue}
                    value={val?.selectedValue}
                    handleChange={handleFilterType}
                    menu={val.list}
                  />
                ))}
              <ResetFilterButton handleReset={handleReset} style={{ marginLeft: "1rem" }} />
              {permission?.update && (
                <BasicButton
                  title={!stockShow ? ButtonTitles.ADD_STOCKS : ButtonTitles.CANCEL}
                  icon={!stockShow ? Icons.ACCEPT2 : Icons.CROSS4}
                  background={Colors.WHITE}
                  border
                  borderColor={Colors.PRIMARY}
                  color={Colors.PRIMARY}
                  action={() => setStockShow(!stockShow)}
                  style={{ marginTop: pxToRem(45) }}
                />
              )}
              {stockShow && stockData?.stockData?.length > 0 && (
                <BasicButton
                  title={ButtonTitles.EDIT_STOCKS}
                  icon={Icons.EDIT}
                  background={Colors.WHITE}
                  color={Colors.PRIMARY}
                  borderColor={Colors.PRIMARY}
                  border
                  action={() => setStockEdit({ ...stockEdit, open: true })}
                />
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox mt={3} mb={5}>
        {/* Equipments Table */}
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE_4 }}
          showTotalEntries={false}
          pagination={{ variant: "gradient", color: "info" }}
          loading={productList.loading}
          licenseRequired
          currentPage={tablePagination.page}
          handleTablePagination={handleTablePagination}
          handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
        />
      </MDBox>

      {equipmentAnchor.right && (
        <EquipmentDetailDrawer
          equipmentAnchor={equipmentAnchor}
          equipmentId={equipmentDrawerId}
          closeDrawer={handleCloseEquipmentDetailDrawer}
          handleViewImage={handleImageFullView}
          equipmentKey="equipmentDrawer"
        />
      )}
      <FullScreenImageComponent
        fullScreenImage={fullScreenImage}
        handleCloseFullView={handleCloseFullView}
        src={fullScreenImage}
      />
      <DeleteModal
        open={deleteModalOpen}
        title={ModalContent.DELETE_EQUIPMENT_TITLE}
        message={ModalContent.DELETE_EQUIPMENT_MESSAGE}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDeleteEquipment}
      />
      {stockEdit.open && (
        <BasicModal
          title={ModalContent.UPDATE_STOCKS}
          open={stockEdit.open}
          handleClose={() => setStockEdit({ ...stockEdit, open: false })}
          handleAction={handleUpdateStock}
          disabled={stockData.stockData?.length === 0 || disableSubmit}
          actionButton={buttonLoading === false ? ButtonTitles.SAVE : ButtonTitles.LOADING}
          py={0}
          minWidth="65%"
        >
          <MDBox
            sx={{
              minWidth: "100%",
              maxHeight: "400px",
              overflowY: "scroll",
              "::-webkit-scrollbar": {
                width: "5px",
              },
              "::-webkit-scrollbar-thumb": {
                background: "gray",
              },
              scrollbarWidth: "thin",
              scrollbarColor: "gray transparent",
            }}
          >
            <DataTable
              table={{ columns: stockColumns, rows: stockRows }}
              isSorted={false}
              entriesPerPage={{ defaultValue: defaultData.PER_PAGE_4 }}
              showTotalEntries={false}
              pagination={{ variant: "gradient", color: "info" }}
              loading={Constants.FULFILLED}
            />
          </MDBox>
        </BasicModal>
      )}
    </DashboardLayout>
  );
}

export default ProductDetails;
