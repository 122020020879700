const pattern = {
  EMAIL_PATTERN: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/,
  PASSWORD_PATTERN: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d@$.!%*#?&]/,
  DEFAULT_ROLE_PATTERN:
    /^(?:super[-_\s]?admin|admin|project[-_\s]?manager|warehouse[-_\s]?manager)$/i,
  NUMERIC_PATTERN: /(?=.*?\d)/,
  LAST_SLASH: /\/([^/]+)$/,
};

export default pattern;
