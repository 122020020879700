import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import pxToRem from "assets/theme/functions/pxToRem";

const defaultBreakpoints = {
  xs: pxToRem(12),
  sm: pxToRem(14),
  md: pxToRem(14),
  lg: pxToRem(14),
  xl: pxToRem(14),
  xxl: pxToRem(16),
  fhd: pxToRem(16),
  uhd: pxToRem(18),
};

function FontComponent({ sizes = {} }) {
  // Media queries to detect the current screen size
  const isXs = useMediaQuery("(max-width:575px)");
  const isSm = useMediaQuery("(min-width:576px) and (max-width:767px)");
  const isMd = useMediaQuery("(min-width:768px) and (max-width:991px)");
  const isLg = useMediaQuery("(min-width:992px) and (max-width:1199px)");
  const isXl = useMediaQuery("(min-width:1200px) and (max-width:1399px)");
  const isXxl = useMediaQuery("(min-width:1400px) and (max-width:1919px)"); // Adjusted
  const isFhd = useMediaQuery("(min-width:1920px) and (max-width:2559px)"); // Full HD screens
  const isUhd = useMediaQuery("(min-width:2560px)"); // Ultra HD screens

  // Merge default breakpoints with custom sizes if provided
  const fontSizes = { ...defaultBreakpoints, ...sizes };

  // Get the appropriate font size based on the screen size
  const getFontSize = () => {
    if (isUhd) return fontSizes.uhd;
    if (isFhd) return fontSizes.fhd;
    if (isXxl) return fontSizes.xxl;
    if (isXl) return fontSizes.xl;
    if (isLg) return fontSizes.lg;
    if (isMd) return fontSizes.md;
    if (isSm) return fontSizes.sm;
    if (isXs) return fontSizes.xs;
    return fontSizes.md; // Default size if no matches
  };

  return getFontSize();
}

// Define the propTypes
FontComponent.propTypes = {
  sizes: PropTypes.shape({
    xs: PropTypes.string,
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
    xxl: PropTypes.string,
    fhd: PropTypes.string,
    uhd: PropTypes.string,
  }),
};

// Define defaultProps for FontComponent
FontComponent.defaultProps = {
  sizes: {}, // Default to empty object to use default breakpoints
};

export default FontComponent;
