import { useState, useEffect } from "react";
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FormControlErrorStyles from "assets/style/Component";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import FontComponent from "components/Responsive/fonts";
import pxToRem from "assets/theme/functions/pxToRem";
import Constants, { Colors } from "utils/Constants";
import PropTypes from "prop-types";
import Ellipsis from "components/Table/Ellipsis";

export default function MultiSelectDropdown({
  label,
  id,
  name,
  values,
  hint,
  handleChange,
  menu,
  error,
  helperText,
  marginBottom,
  disabled = false,
  labelStyle,
  valueStyle,
  showBadge,
}) {
  const [open, setOpen] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const dropdownIcon = () => <KeyboardArrowDownIcon fontSize="medium" sx={{ color: "#667085" }} />;
  const fontSize = FontComponent({ sizes: {} });

  useEffect(() => {
    setIsAllSelected(values.length === menu.length);
  }, [values, menu.length]);

  return (
    <FormControl
      sx={{
        mr: 2,
        ml: 0,
        mt: pxToRem(8),
        width: "100%",
        marginBottom,
        maxHeight: 400,
        ...FormControlErrorStyles,
      }}
      error={Boolean(error)}
      formhelpertextprops={{
        sx: { marginLeft: 0 },
      }}
    >
      <MDTypography
        variant="caption"
        mb={1}
        sx={{ fontSize, fontWeight: labelStyle?.fontWeight || 500, color: "#344054" }}
      >
        {label}
      </MDTypography>
      <MDBox
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          backgroundColor: disabled ? Colors.DISABLED : "transparent",
        }}
      >
        <Select
          displayEmpty
          disabled={disabled}
          onChange={(e) => {
            const { value } = e.target;
            if (value[value.length - 1] === "all") {
              if (isAllSelected) {
                handleChange(name, [], id);
              } else {
                const allValues = menu.map((item) => item[Constants.MONGOOSE_ID]);
                handleChange(name, allValues, id);
              }
              setIsAllSelected(!isAllSelected);
              return;
            }
            handleChange(name, e.target.value, id);
          }}
          multiple
          IconComponent={dropdownIcon}
          labelId={id}
          id={id}
          name={name}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          placeholder={hint}
          sx={{
            color: "#667085",
            backgroundColor: disabled ? Colors.DISABLED : valueStyle?.backgroundColor || "black",
            paddingY: "0.5rem",
            paddingX: "0.5rem",
            fontSize: pxToRem(16),
            fontWeight: 400,
            width: "100%",
            height: valueStyle?.height || "auto",
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                width: pxToRem(valueStyle?.menuWidth || 405),
                maxHeight: 150,
                opacity: 1,
                transform: "none",
                border: "1px solid #D0D5DD",
                marginTop: valueStyle?.verticalMarginTop
                  ? valueStyle.verticalMarginTop
                  : pxToRem(8),
                marginLeft: pxToRem(-8),
              },
            },
            getContentAnchorEl: null,
          }}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <MDTypography
                  sx={{
                    color: "#667085",
                    fontSize,
                    fontWeight: 400,
                  }}
                >
                  {hint}
                </MDTypography>
              );
            }
            if (showBadge) {
              const firstSelected = menu.find((val) => selected[0] === val[Constants.MONGOOSE_ID]);
              const remainingCount = selected.length - 1;

              return (
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  {firstSelected && (
                    <Chip
                      label={
                        <MDBox
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Ellipsis
                            title={firstSelected.title}
                            maxWidth={valueStyle?.inputWidth || 200}
                          >
                            {firstSelected.title}
                          </Ellipsis>
                        </MDBox>
                      }
                    />
                  )}
                  {remainingCount > 0 && (
                    <Chip
                      label={`+${remainingCount}`}
                      sx={{
                        backgroundColor: "#EBEBEB",
                        color: "#344767",
                      }}
                    />
                  )}
                </Box>
              );
            }
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {menu
                  .filter((val) => selected.some((option) => option === val[Constants.MONGOOSE_ID]))
                  .map((value) => (
                    <Chip
                      key={value[Constants.MONGOOSE_ID]}
                      label={
                        <MDBox
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Ellipsis title={value.title} maxWidth={valueStyle?.inputWidth || 200}>
                            {value.title}
                          </Ellipsis>
                        </MDBox>
                      }
                    />
                  ))}
              </Box>
            );
          }}
          value={values}
        >
          {menu.length !== 0 && (
            <MenuItem
              sx={{
                display: "flex",
                alignItems: "center",
                p: 0,
              }}
              value="all"
            >
              <CustomCheckbox checked={isAllSelected} />
              <MDTypography
                variant="subtitle1"
                sx={{
                  ml: 1,
                  textTransform: "capitalize",
                  fontSize: pxToRem(16),
                  fontWeight: 400,
                  color: "#667085",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {isAllSelected ? "Deselect All" : "Select All"}
              </MDTypography>
            </MenuItem>
          )}
          {menu.length > 0 ? (
            menu.map((val) => (
              <MenuItem
                key={val[Constants.MONGOOSE_ID]}
                value={val[Constants.MONGOOSE_ID]}
                sx={{ p: 0 }}
              >
                <CustomCheckbox
                  name={val[Constants.MONGOOSE_ID]}
                  checked={values.includes(val[Constants.MONGOOSE_ID])}
                />
                <MDTypography
                  variant="subtitle1"
                  sx={{
                    ml: 1,
                    textTransform: "capitalize",
                    fontSize: pxToRem(16),
                    fontWeight: 400,
                    color: "#667085",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Ellipsis
                    maxWidth={pxToRem(Number(valueStyle?.menuWidth) - 50 || 360)}
                    title={val?.title}
                    style={{
                      textTransform: "capitalize",
                      fontSize,
                      color: "#667085",
                    }}
                  >
                    {val?.title}
                  </Ellipsis>
                </MDTypography>
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No data available</MenuItem>
          )}
        </Select>
        <IconButton
          onClick={() => !disabled && setOpen(!open)}
          disabled={disabled}
          sx={{
            position: "absolute",
            top: "50%",
            right: 0,
            transform: "translateY(-50%)",
            zIndex: 1,
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </MDBox>
      {helperText !== "" && <FormHelperText sx={{ marginLeft: 0 }}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

MultiSelectDropdown.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  menu: PropTypes.arrayOf(PropTypes.object),
  values: PropTypes.arrayOf(PropTypes.string),
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  marginBottom: PropTypes.string,
  labelStyle: PropTypes.objectOf(PropTypes.any),
  valueStyle: PropTypes.objectOf(PropTypes.any),
  showBadge: PropTypes.bool,
};

MultiSelectDropdown.defaultProps = {
  id: "",
  name: "",
  label: "",
  hint: "",
  error: false,
  helperText: "",
  menu: [],
  values: [],
  disabled: false,
  marginBottom: "0",
  labelStyle: {},
  valueStyle: {},
  showBadge: false,
};
