import pxToRem from "assets/theme/functions/pxToRem";

const ButtonBreakPoint = {
  baseTitleBreakPoint: {
    xs: pxToRem(12),
    sm: pxToRem(14),
    md: pxToRem(14),
    lg: pxToRem(14),
    xl: pxToRem(14),
    xxl: pxToRem(16),
    fhd: pxToRem(16),
    uhd: pxToRem(18),
  },
  smallTitleBreakPoint: {
    xs: pxToRem(10),
    sm: pxToRem(10),
    md: pxToRem(12),
    lg: pxToRem(12),
    xl: pxToRem(12),
    xxl: pxToRem(12),
    fhd: pxToRem(14),
    uhd: pxToRem(14),
  },
};

export default ButtonBreakPoint;
