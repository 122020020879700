import React, { useEffect, useState } from "react";

// Custom Components
import Author from "components/Table/Author";
import MDBox from "components/MDBox";
import { IconButton } from "@mui/material";

// Redux
import { exportToolBoxTalkPdf } from "redux/Thunks/Filter";

// Constants
import Constants, { Icons, defaultData } from "utils/Constants";

// 3rd party library
import moment from "moment";
import { useDispatch } from "react-redux";
import { handlePdfExport } from "utils/methods/methods";

const ToolboxTalkData = (toolboxData, handleOpenToolboxDrawer, handleOpenDeleteModal) => {
  const [exportToolboxId, setExportToolboxId] = useState(null);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  const handleExportToolbox = async (id) => {
    setExportToolboxId(id);
    const res = await dispatch(exportToolBoxTalkPdf(id));
    await handlePdfExport("ToolboxTalk", res);
    setExportToolboxId(null);
  };

  useEffect(() => {
    if (toolboxData) {
      const dataList = toolboxData?.map((item, index) => ({
        no: <Author name={index + 1} />,
        project: (
          <Author
            name={item?.project?.title || Constants.NA}
            maxContent={defaultData.MEDIUM_CONTENT_LENGTH}
          />
        ),
        location: (
          <Author
            name={item?.location?.title || Constants.NA}
            maxContent={defaultData.MEDIUM_CONTENT_LENGTH_2}
          />
        ),
        team: <Author name={item?.team?.teamsWfmName || Constants.NA} />,
        attendee: <Author name={`${item?.memberSigned || Constants.NA}`} />,
        submittedat: (
          <Author name={moment(item?.createdAt).format(defaultData.WEB_24_HOURS_FORMAT)} />
        ),
        host: (
          <Author
            name={
              `${item?.createdBy?.firstName ?? ""} ${item?.createdBy?.lastName ?? ""}`.trim() ||
              Constants.NA
            }
          />
        ),
        note: (
          <Author name={item?.note || Constants.NA} maxContent={defaultData.LARGE_CONTENT_LENGTH} />
        ),
        action: (
          <MDBox>
            <IconButton
              aria-label="report-parameter"
              color="info"
              onClick={() => handleOpenToolboxDrawer(item[Constants.MONGOOSE_ID])}
            >
              {Icons.VIEW}
            </IconButton>
            <IconButton
              aria-label="report-parameter"
              color="info"
              onClick={() => handleOpenDeleteModal(item[Constants.MONGOOSE_ID])}
            >
              {Icons.DELETE}
            </IconButton>
            &nbsp;
            <IconButton
              aria-label="Export Safety Card"
              color="info"
              onClick={() => handleExportToolbox(item[Constants.MONGOOSE_ID])}
            >
              {exportToolboxId === item[Constants.MONGOOSE_ID] ? Icons.LOADING : Icons.EXPORT}
            </IconButton>
          </MDBox>
        ),
      }));

      setRows(dataList);
    }
  }, [toolboxData, exportToolboxId]);

  return {
    columns: [
      { Header: "No.", accessor: "no", width: "2%" },
      { Header: "Project", accessor: "project", align: "left", width: "25%" },
      { Header: "Location", accessor: "location", align: "left", width: "15%" },
      { Header: "Team", accessor: "team", align: "left", width: "15%" },
      { Header: "Attendee", accessor: "attendee", align: "center", width: "8%" },
      { Header: "Submitted At", accessor: "submittedat", align: "left", width: "15%" },
      { Header: "Host", accessor: "host", align: "left", width: "15%" },
      { Header: "Note", accessor: "note", align: "left", width: "15%" },
      { Header: "Action", accessor: "action", align: "left", width: "15%" },
    ],
    rows,
  };
};

export default ToolboxTalkData;
