import React, { useState } from "react";

// material-ui
import { Autocomplete } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Ellipsis from "components/Table/Ellipsis";

// Common components
import FontComponent from "components/Responsive/fonts";

// utils
import Constants, { Icons, Colors } from "utils/Constants";

// 3rd party
import PropTypes, { object } from "prop-types";

function CustomAutoComplete({
  label,
  id,
  name,
  hint,
  handleChange,
  menu,
  error,
  helperText,
  getOptionLabel,
  value,
  labelStyle,
  valueStyle,
  disabled,
}) {
  const fontSize = FontComponent({ sizes: {} });
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e, newInputValue) => {
    setInputValue(newInputValue.trimStart());
  };

  const handleOptionChange = (e, selectedOption) => {
    handleChange({
      target: {
        name,
        value: selectedOption ? selectedOption[Constants.MONGOOSE_ID] : "",
      },
    });
  };

  return (
    <>
      <MDTypography
        variant="caption"
        sx={{ fontSize, fontWeight: labelStyle?.fontWeight || 500, color: "#344054" }}
      >
        {label}
      </MDTypography>
      <Autocomplete
        options={menu}
        getOptionLabel={getOptionLabel}
        name={name}
        id={id}
        variant="standard"
        value={value}
        disabled={disabled}
        inputValue={inputValue}
        onInputChange={handleInputChange} // Search input change handler
        onChange={handleOptionChange} // Option selection handler
        renderOption={(props, option) => (
          <li {...props} key={option?.[Constants.MONGOOSE_ID]}>
            <Ellipsis
              maxWidth="100%"
              title={option?.title || option?.name}
              style={{
                textTransform: valueStyle?.textTransform || "",
                fontSize,
                color: "#667085",
              }}
            >
              {option?.title || option?.name}
            </Ellipsis>
          </li>
        )}
        sx={{
          "& .MuiAutocomplete-inputRoot": {
            display: "flex",
            alignItems: "center",
            height: valueStyle?.height || "auto",
            padding: valueStyle?.padding ? valueStyle?.padding : "4px",
            fontSize,
            backgroundColor: disabled
              ? Colors.DISABLED
              : valueStyle?.backgroundColor || "transparent",
          },
        }}
        popupIcon={Icons.ARROW_DOWN}
        renderInput={(params) => (
          <MDInput
            {...params}
            name={name}
            placeholder={hint}
            error={Boolean(error)}
            helperText={helperText}
            disabled={disabled}
            sx={{
              "& .MuiInputBase-input": {
                color: valueStyle?.color || "",
                textTransform: valueStyle?.textTransform || "",
                fontSize,
                backgroundColor: disabled ? Colors.DISABLED : "transparent",
              },
            }}
            FormHelperTextProps={{
              sx: {
                marginLeft: 0,
                color: "#FF2E2E",
              },
            }}
          />
        )}
      />
    </>
  );
}

CustomAutoComplete.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  menu: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  getOptionLabel: PropTypes.func.isRequired,
  value: PropTypes.string || PropTypes.objectOf(object),
  labelStyle: PropTypes.objectOf(PropTypes.any),
  valueStyle: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
};

CustomAutoComplete.defaultProps = {
  handleChange: () => {},
  menu: [],
  error: false,
  helperText: "",
  value: "",
  labelStyle: {},
  valueStyle: {},
  disabled: false,
};

export default CustomAutoComplete;
