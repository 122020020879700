// Data
import React, { useState, useEffect } from "react";

// Material Copmponents
import { Divider } from "@mui/material";
import MDBox from "components/MDBox";

// Custom Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import DataTable from "examples/Tables/DataTable";
import ConfirmationMoal from "examples/modal/Confirmation/Confirmation";
import CertificateApprovalData from "layouts/personnelManagement/CertificateApproval/data/CertificateApprovalData";
import SearchBar from "components/Search/SearchInTable";
import FullScreenImageComponent from "components/ViewFullImage/ViewImage";
import FormTextField from "components/Form/FTextField";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import ResetFilterButton from "components/Buttons/ResetButton";

// Utils and Constants
import { paramCreater } from "utils/methods/methods";
import Constants, { Icons, ButtonTitles, FeatureTags, defaultData, Colors } from "utils/Constants";

// Library
import { Feature } from "flagged";

// Redux
import { useDispatch } from "react-redux";
import getAllCertificates, { approveCertificate } from "redux/Thunks/certificateApproval";
import { openSnackbar } from "redux/Slice/Notification";
import UserListThunk from "redux/Thunks/UserManagement";

const intialSelectedCertificate = {
  open: false,
  status: "",
  reason: "",
  certificateId: "",
  loading: false,
};

function CertificateApproval() {
  const [loadingStatus, setLoadingStatus] = useState(Constants.PENDING);
  const [certificateList, setCertificateList] = useState([]);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [certificateData, setCertificateData] = useState({});
  const [selectedCertificate, setSelectedCertificate] = useState(intialSelectedCertificate);
  const [tablePagination, setTablePagination] = useState({
    page: defaultData.PAGE,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const [next, setNext] = useState(0);
  const [filters, setFilter] = useState([
    {
      inputLabel: "Search",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }],
      selectedValue: "all",
      isLoading: false,
    },
  ]);
  const dispatch = useDispatch();
  let debounceTimeout;

  const handleCertificateOpen = (status, certificateId) => {
    setSelectedCertificate({ ...selectedCertificate, open: true, status, certificateId });
  };

  const handleCertificateClose = () => {
    setSelectedCertificate(intialSelectedCertificate);
  };

  const fetchData = async (searchValue = filters[0].selectedValue) => {
    setTablePagination({ ...tablePagination, page: 0 });

    const paramData = {
      page: 0,
      perPage: tablePagination.perPage,
      ...(searchValue !== "all" && { search: searchValue }),
    };
    const res = await dispatch(getAllCertificates(paramCreater(paramData)));
    if (res.payload.status === 200) {
      setCertificateList(res.payload.data.data);
      setLoadingStatus(Constants.FULFILLED);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = async (searchValue) => {
    if (searchValue === "") return;
    setFilter((prev) => {
      const temp = [...prev];
      temp[0].isLoading = true;
      return temp;
    });

    setTablePagination({ ...tablePagination, page: 0 });

    const paramData = {
      page: 0,
      perPage: tablePagination.perPage,
      search: searchValue[0].selectedValue.trim(),
      nationality: "all",
    };

    const res = await dispatch(UserListThunk(paramCreater(paramData)));
    const temp = [...filters];
    const userList = res.payload.data.data
      .filter(
        (item) =>
          item.role?.title !== defaultData.ADMIN_ROLE &&
          item.role?.title !== defaultData.SUPER_ADMIN_ROLE
      )
      .map((item) => ({
        [Constants.MONGOOSE_ID]: item[Constants.MONGOOSE_ID],
        title: `${item.firstName} ${item.lastName}`,
      }));

    temp[0].list = [{ [Constants.MONGOOSE_ID]: "all", title: "All" }, ...userList];
    temp[0].isLoading = false;
    setFilter(temp);
  };

  const debouncedHandleSearch = (e) => {
    clearTimeout(debounceTimeout);
    const temp = [...filters];
    temp[0].selectedValue = e.target.value.trim();
    debounceTimeout = setTimeout(async () => handleSearch(temp), 300);
  };

  const handleCertificateView = (data) => {
    setFullScreenImage(data?.url);
    setCertificateData(data);
  };

  const { columns, rows } = CertificateApprovalData(
    certificateList,
    handleCertificateOpen,
    handleCertificateView
  );

  const handleResetFilter = () => {
    setFilter(filters.map((prevFilter) => ({ ...prevFilter, selectedValue: "all" })));
    fetchData("All");
  };

  const handleCloseFullView = () => {
    setFullScreenImage(null);
  };

  const handleCertificateAction = async () => {
    if (selectedCertificate.status === "rejected" && selectedCertificate.reason === "") {
      setSelectedCertificate({
        ...selectedCertificate,
        errorMessage: "Please enter reason",
        loading: false,
      });
      return;
    }
    setSelectedCertificate({ ...selectedCertificate, loading: true });
    const body = {
      status: selectedCertificate.status,
      ...(selectedCertificate.status === "rejected" && { reason: selectedCertificate.reason }),
    };
    const res = await dispatch(approveCertificate({ id: selectedCertificate.certificateId, body }));
    if (res.payload.status === 200) {
      setSelectedCertificate(intialSelectedCertificate);
      fetchData(filters[0].selectedValue);
      dispatch(
        openSnackbar({
          message: res.payload?.data.message,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const handleReason = (event) => {
    setSelectedCertificate({ ...selectedCertificate, reason: event.target.value });
  };

  const handleTablePagination = async () => {
    const data = {
      page: next + 1,
      perPage: tablePagination.perPage,
    };
    if (filters[0].selectedValue !== "all") {
      data.search = filters[0].selectedValue;
    }
    const res = await dispatch(getAllCertificates(paramCreater(data)));
    if (res.payload.status === 200) {
      setCertificateList([...certificateList, ...res.payload.data.data]);
      setNext(res.payload.data.data.length > 0 ? next + 1 : next);
    }
  };

  const handleReload = async () => {
    setLoadingStatus(Constants.PENDING);
    setFilter(filters.map((prevFilter) => ({ ...prevFilter, selectedValue: "all" })));
    fetchData("all");
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    const temp = [...filters];
    const filterIndex = temp.findIndex((item) => item.inputLabel === name);
    temp[filterIndex].selectedValue = temp[filterIndex].list.find((item) => item.title === value)?.[
      Constants.MONGOOSE_ID
    ];
    setFilter(temp);
    fetchData(temp[filterIndex].selectedValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        display="flex"
        flexDirection={{ md: "row", sm: "column" }}
        justifyContent={{ md: "space-between" }}
        alignItems={{ lg: "space-between", sm: "center" }}
      >
        <PageTitle title="Certificate Approval" />
        <BasicButton
          icon={Icons.RELOAD}
          background={Colors.WHITE}
          border
          color={Colors.BLACK}
          action={handleReload}
        />
      </MDBox>
      <Divider sx={{ marginTop: 2 }} />
      <Feature name={FeatureTags.CERTIFICATE}>
        {/* Search bar and reset button */}
        <MDBox display="flex" justifyContent="start" gap={2} flexWrap="wrap" mx={0}>
          <SearchBar
            freeSolos
            options={filters[0]?.list.map((val) => val.title) || []}
            filters={filters}
            placeholder="Search"
            value={
              filters[0].list.find(
                (item) => item[Constants.MONGOOSE_ID] === filters[0].selectedValue
              )?.title
            }
            handleFilterChange={(e, value) => {
              handleFilterChange({
                target: {
                  name: filters[0].inputLabel,
                  value,
                },
              });
            }}
            debouncedHandleSearch={debouncedHandleSearch}
            isLoading={filters[0].isLoading}
          />
          <ResetFilterButton handleReset={handleResetFilter} />
        </MDBox>

        <MDBox mt={3}>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
            showTotalEntries={false}
            pagination={{ variant: "gradient", color: "info" }}
            loading={loadingStatus}
            currentPage={tablePagination.page}
            handleTablePagination={handleTablePagination}
            handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
          />
        </MDBox>

        <ConfirmationMoal
          open={selectedCertificate.open}
          handleClose={handleCertificateClose}
          title={
            selectedCertificate.status === "approved"
              ? Constants.CERTIFICATE_APPROVAL_TITLE
              : Constants.REJECT_CERTIFICATE_TITLE
          }
          message={
            selectedCertificate.status === "approved"
              ? Constants.CERTIFICATE_APPROVAL_MESSAGE
              : Constants.REJECT_CERTIFICATE_MESSAGE
          }
          positiveButton={
            (selectedCertificate.status === "approved" &&
              (selectedCertificate.loading
                ? ButtonTitles.SUBMIT_LOADING
                : ButtonTitles.APPROVE_LICENSE)) ||
            (selectedCertificate.status === "rejected" &&
              (selectedCertificate.loading
                ? ButtonTitles.SUBMIT_LOADING
                : ButtonTitles.REJECT_LICENSE))
          }
          positiveLoadingButton={
            (selectedCertificate.status === "approved" &&
              (selectedCertificate.loading
                ? ButtonTitles.SUBMIT_LOADING
                : ButtonTitles.APPROVE_LICENSE)) ||
            (selectedCertificate.status === "rejected" &&
              (selectedCertificate.loading
                ? ButtonTitles.SUBMIT_LOADING
                : ButtonTitles.REJECT_LICENSE))
          }
          handleAction={handleCertificateAction}
          disabled={selectedCertificate.loading}
        >
          {selectedCertificate.status === "rejected" && (
            <FormTextField
              type="textarea"
              marginTop={2}
              title="Reason*"
              value={selectedCertificate.reason}
              name="reason"
              label="Reason*"
              placeholder="Add reason here"
              error={Boolean(selectedCertificate?.errorMessage)}
              helperText={selectedCertificate?.errorMessage}
              handleChange={handleReason}
            />
          )}
        </ConfirmationMoal>

        <FullScreenImageComponent
          fullScreenImage={fullScreenImage}
          handleCloseFullView={handleCloseFullView}
          src={fullScreenImage}
          certificateData={certificateData}
        />
      </Feature>
    </DashboardLayout>
  );
}

export default CertificateApproval;
