// Material Dashboard 2 React components
import { Box, Grid, Tab, Tabs, styled } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Data
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import pxToRem from "assets/theme/functions/pxToRem";
import PageTitle from "examples/NewDesign/PageTitle";
import ConfigDropdown from "components/Dropdown/ConfigDropdown";
import AccessDenied from "components/AccessDenied/accessDenied";
import SearchBar from "components/Search/SearchInTable";

import Constants, {
  Icons,
  PageTitles,
  FeatureTags,
  ButtonTitles,
  Colors,
  defaultData,
} from "utils/Constants";
import ProductDocument from "layouts/resources/productDocument";
import ProductWarehouse from "layouts/resources/productWarehouse";
import CustomButton from "examples/NewDesign/CustomButton";
import FTextField from "components/Form/FTextField";
import DateTime from "components/DateTime/DateTime";

// Library
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import PropTypes from "prop-types";

// Redux
import { openSnackbar } from "redux/Slice/Notification";
import { useDispatch, useSelector } from "react-redux";
import ProductListThunk, {
  CreateNewProduct,
  equipmentByIdThunk,
  equipmentUpdateThunk,
} from "redux/Thunks/Equipment";
import { equipmentConfig } from "redux/Thunks/Config";
import FontComponent from "components/Responsive/fonts";
import { ButtonBreakPoint } from "components/Responsive/BreakPoints";

// Icons
import HardwareOutlinedIcon from "@mui/icons-material/HardwareOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";

// feature flag
import { Feature } from "flagged";
import ExportHOC from "examples/HigherOrderComponents/ExportHOC";
import { paramCreater } from "utils/methods/methods";
import { resetListState } from "redux/Slice/Equipment";

const StyledMDBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(3),
  minHeight: "800px",
  border: "1px solid #E0E6F5",
  background: "var(--base-white, #FFF)",

  padding: theme.spacing(`${pxToRem(30)} ${pxToRem(200)}`), // Default padding for all breakpoints
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(`${pxToRem(30)} ${pxToRem(100)}`), // Adjust padding for small screens and below
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(`${pxToRem(30)} ${pxToRem(50)}`), // Adjust padding for small screens and below
  },
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(`${pxToRem(30)} ${pxToRem(0)}`), // Adjust padding for extra-small screens
  },
}));
function ConfigEquipment(props) {
  const [values, setValues] = useState({});
  const [searchData, setSearchData] = useState([]);
  const [updatedValues, setUpdatedValues] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [documentFormFilled, setDocumentFormFilled] = useState(false);
  const [warehouseFormFilled, setWarehouseFormFilled] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [equipDocumentData, setEquipDocumentData] = useState({
    errors: {},
  });
  const [documentForm, setDocumentForm] = useState([]);
  const [warehouseData, setWarehouseData] = useState({
    errors: {},
  });
  const ConfigData = useSelector((state) => state.config);
  const [form, setForm] = useState([]);
  const [quantityType, setQuantityType] = useState(false);
  const permission = ConfigData?.equipmentConfig?.agreement?.create;
  const { checkEquipmentId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [updatedBody, setUpdatedBody] = useState({});
  const [currency, setCurrency] = useState(null);
  const dispatch = useDispatch();

  let debounceTimeout;

  const [filters, setFilters] = useState([
    {
      inputLabel: "Search",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }],
      selectedValue: "",
      isLoading: false,
    },
  ]);
  const fontSize = FontComponent({ sizes: ButtonBreakPoint.smallTitleBreakPoint });
  const { handleEquipmentSampleFileExport } = props;

  const handleTabChange = (event, newValue) => {
    if (newValue === 1 && !documentFormFilled) {
      dispatch(
        openSnackbar({
          message: Constants.FILL_ALL_REQUIRED_FIELDS,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
      return;
    }
    if (newValue === 2 && !warehouseFormFilled) {
      dispatch(
        openSnackbar({
          message: Constants.FILL_ALL_REQUIRED_FIELDS,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
      return;
    }
    // Set the new tab value if the conditions are met
    setTabValue(newValue);
  };

  useEffect(() => {
    (async () => {
      await dispatch(equipmentConfig());
    })();
  }, [checkEquipmentId]);

  // Function to load data while updating equipment
  const handleEditLoader = async (tempForm, productForm) => {
    const tempCopy = tempForm ? JSON.parse(JSON.stringify(tempForm)) : {};
    const tempProductCopy = productForm ? JSON.parse(JSON.stringify(productForm)) : {};
    if (checkEquipmentId && ConfigData.equipmentLoading === Constants.FULFILLED) {
      // Set the form completion status for all tabs to true
      setDocumentFormFilled(true);
      setWarehouseFormFilled(true);

      const res = await dispatch(equipmentByIdThunk(checkEquipmentId));
      setCurrency(res.payload.data?.data?.equipmentType?.currencyUnit?.symbol || "");
      if (res.payload.status === 200) {
        const temp = { ...values };
        ConfigData?.equipmentConfig?.properties?.equipment.forEach((val, index) => {
          switch (val.type) {
            case "text":
              {
                const updatedItem = { ...val };

                if (
                  updatedItem.parentFieldId !== "" &&
                  updatedItem.id === "serialNumber" &&
                  res.payload.data.data?.equipmentType?.quantityType?.quantityType === "unique"
                ) {
                  setQuantityType(true);
                  updatedItem.isDefaultVisible = true;
                  updatedItem.IsRequired = true;
                  temp[val.id] = res.payload.data.data?.[val.id];
                  updatedBody[val.id] = res.payload.data.data?.[val.id];
                } else if (quantityType === false) {
                  temp[val.id] = res.payload.data.data?.[val.id];
                }

                tempCopy[index] = updatedItem;
              }
              break;
            case "number":
            case "search":
            case "decimal-number":
              temp[val.id] = res.payload.data.data?.[val.id];
              updatedBody[val.id] = temp[val.id];
              break;
            case "options":
              {
                const priceItem = { ...val };
                if (val.parentFieldId === "" && val.id === "equipmentType") {
                  const equipmentType = res.payload.data.data?.[val.id];
                  if (equipmentType) {
                    temp[val.id] = res.payload.data.data?.equipmentType?.type;
                    const tempID = res.payload.data.data?.[val.id][Constants.MONGOOSE_ID];
                    updatedBody[val.id] = tempID;
                  } else {
                    temp[val.id] = res.payload.data.data?.[val.id]?.type;
                  }
                } else if (val.parentFieldId !== "" && val.id === "weightForm") {
                  temp[val.id] = res.payload.data.data?.equipmentType?.equipmentUnit?.title;
                } else if (val.parentFieldId !== "" && val.id === "quantityType") {
                  temp[
                    val.id
                  ] = `${res.payload.data.data?.equipmentType?.quantityType?.name} (${res.payload.data.data?.equipmentType?.quantityType?.priceType},${res.payload.data.data?.equipmentType?.quantityType?.quantityType})`;
                } else if (val.parentFieldId !== "" && val.id === "price") {
                  temp[val.id] = res.payload.data.data?.equipmentType?.price;
                } else if (val.parentFieldId !== "" && val.id === "equipmentCategory") {
                  temp[val.id] = res.payload.data.data?.equipmentType?.equipmentCategory?.name;
                } else if (val.parentFieldId !== "" && val.id === "hsCode") {
                  temp[
                    val.id
                  ] = `${res.payload.data.data?.equipmentType?.hsCode?.name}(${res.payload.data.data?.equipmentType?.hsCode?.code})`;
                }
                if (priceItem.type === "options" && priceItem.id === "price") {
                  if (
                    priceItem.dependentIds.includes(
                      res.payload.data.data?.equipmentType[Constants.MONGOOSE_ID]
                    )
                  ) {
                    priceItem.isDefaultVisible = true;
                    priceItem.IsRequired = true;
                  } else if (
                    !priceItem.dependentIds.includes(
                      res.payload.data.data?.equipmentType[Constants.MONGOOSE_ID]
                    )
                  ) {
                    priceItem.isDefaultVisible = false;
                    priceItem.IsRequired = false;
                  }
                }
                tempCopy[index] = priceItem;
              }
              break;

            case "options-values":
              {
                const updatedItem = { ...val };

                if (
                  updatedItem.parentFieldId !== "" &&
                  updatedItem.type === "options-values" &&
                  res.payload.data.data?.equipmentType?.quantityType?.priceType === "rental"
                ) {
                  updatedItem.isDefaultVisible = true;
                  updatedItem.IsRequired = true;
                  temp[val.id] = res.payload.data.data?.[val.id];
                }

                tempCopy[index] = updatedItem;
              }
              break;
            default:
              break;
          }
        });
        setValues({ ...temp });
        const tempDocument = { ...equipDocumentData };

        ConfigData?.equipmentConfig?.properties?.imageSpecificationAndPrice.forEach(
          (val, index) => {
            switch (val.type) {
              case "text":
              case "number":
              case "date":
              case "images":
                tempDocument[val.id] = res.payload.data.data?.[val.id];
                break;
              case "certificateType":
                if (val.id === "certificateType") {
                  tempDocument[val.id] = res.payload.data.data?.certificateType;
                  updatedBody[val.id] = tempDocument[val.id];
                }
                break;
              case "validity-date":
                {
                  const updatedItem = { ...val };
                  const certificateType = res.payload.data.data?.certificateType;

                  if (
                    tempDocument[val.id] !== null &&
                    updatedItem.parentFieldId !== "" &&
                    updatedItem.type === "validity-date" &&
                    certificateType !== null &&
                    updatedItem.dependentIds.includes(certificateType[Constants.MONGOOSE_ID])
                  ) {
                    updatedItem.isDefaultVisible = true;
                    tempDocument[val.id] = res.payload.data.data?.[val.id];
                  }
                  tempProductCopy[index] = updatedItem;
                }
                break;

              default:
                break;
            }
          }
        );
        setEquipDocumentData({ ...tempDocument });
        const tempWarehouse = { ...warehouseData };
        ConfigData?.equipmentConfig?.properties?.warehouseInfo.forEach((val) => {
          switch (val.type) {
            case "text":
            case "number":
            case "date":
              tempWarehouse[val.id] = res.payload.data.data?.[val.id];
              break;
            case "options":
              if (val.id === "warehouse") {
                tempWarehouse[val.id] = res.payload.data.data?.warehouse?.name;
                const tempID = res.payload.data.data?.[val.id][Constants.MONGOOSE_ID];
                updatedBody[val.id] = tempID;
              }
              if (val.id === "equipmentCurrentLocation") {
                tempWarehouse[val.id] = res.payload.data.data?.equipmentCurrentLocation;
              }
              break;
            default:
              break;
          }
        });
        setWarehouseData({ ...tempWarehouse });
        setUpdatedBody(updatedBody);
      } else if (res.payload.status === 404) {
        navigate("/client/inventory");
      } else {
        dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
    }
    return { tempCopy, tempProductCopy };
  };

  useEffect(() => {
    const tempForm = ConfigData?.equipmentConfig?.properties?.equipment;
    const productForm = ConfigData?.equipmentConfig?.properties?.imageSpecificationAndPrice;

    (async () => {
      const { tempCopy: newForm, tempProductCopy: newProductForm } = await handleEditLoader(
        tempForm,
        productForm
      );

      setForm(newForm);
      setDocumentForm(newProductForm);
    })();
  }, [ConfigData.equipmentLoading]);

  // Function to load data while searching  equipment for Register
  const handleSearchData = async (tempForm, productForm) => {
    const tempCopy = tempForm ? JSON.parse(JSON.stringify(tempForm)) : {};
    const tempProductCopy = productForm ? JSON.parse(JSON.stringify(productForm)) : {};
    if (
      searchData?.name &&
      !checkEquipmentId &&
      ConfigData.equipmentLoading === Constants.FULFILLED
    ) {
      // Set the form completion status for all tabs to true
      setDocumentFormFilled(true);
      setWarehouseFormFilled(true);

      await dispatch(ProductListThunk());
      const temp = { ...values };
      setCurrency(searchData?.equipmentType?.currencyUnit[0]?.symbol || "");
      ConfigData?.equipmentConfig?.properties?.equipment.forEach((val, index) => {
        switch (val.type) {
          case "text":
            {
              const updatedItem = { ...val };

              if (
                updatedItem.parentFieldId !== "" &&
                updatedItem.id === "serialNumber" &&
                searchData?.equipmentType?.quantityType[0]?.quantityType === "unique"
              ) {
                setQuantityType(true);
                updatedItem.isDefaultVisible = true;
                updatedItem.IsRequired = true;
              } else if (quantityType === false) {
                temp[val.id] = searchData?.[val.id];
              }

              tempCopy[index] = updatedItem;
            }
            break;
          case "number":
          case "decimal-number":
          case "search":
            temp[val.id] = searchData?.[val.id];
            updatedBody[val.id] = temp[val.id];
            break;
          case "options": {
            const priceItem = { ...val };
            if (val.parentFieldId === "" && val.id === "equipmentType") {
              const equipmentType = searchData?.[val.id];
              if (equipmentType) {
                temp[val.id] = searchData?.equipmentType?.type;
                const tempID = searchData?.[val.id][Constants.MONGOOSE_ID];
                updatedBody[val.id] = tempID;
              } else {
                temp[val.id] = searchData?.[val.id]?.type;
              }
            } else if (val.parentFieldId !== "" && val.id === "weightForm") {
              temp[val.id] = searchData?.equipmentType?.equipmentUnit[0]?.title;
            } else if (val.parentFieldId !== "" && val.id === "quantityType") {
              temp[
                val.id
              ] = `${searchData?.equipmentType?.quantityType[0]?.name} (${searchData?.equipmentType?.quantityType[0]?.priceType},${searchData?.equipmentType?.quantityType[0]?.quantityType})`;
            } else if (val.parentFieldId !== "" && val.id === "price") {
              temp[val.id] = searchData?.equipmentType?.price;
            } else if (val.parentFieldId !== "" && val.id === "equipmentCategory") {
              temp[val.id] = searchData?.equipmentType?.equipmentCategory[0]?.name;
            } else if (val.parentFieldId !== "" && val.id === "hsCode") {
              temp[
                val.id
              ] = `${searchData?.equipmentType?.hsCode[0]?.name}(${searchData?.equipmentType?.hsCode[0]?.code})`;
            }
            if (priceItem.type === "options" && priceItem.id === "price") {
              if (
                priceItem.dependentIds.includes(searchData?.equipmentType[Constants.MONGOOSE_ID])
              ) {
                priceItem.isDefaultVisible = true;
                priceItem.IsRequired = true;
              } else if (
                !priceItem.dependentIds.includes(searchData?.equipmentType[Constants.MONGOOSE_ID])
              ) {
                priceItem.isDefaultVisible = false;
                priceItem.IsRequired = false;
              }
            }
            tempCopy[index] = priceItem;
            break;
          }
          case "options-values":
            {
              const updatedItem = { ...val };

              if (
                updatedItem.parentFieldId !== "" &&
                updatedItem.type === "options-values" &&
                searchData?.equipmentType?.quantityType[0]?.priceType === "rental"
              ) {
                updatedItem.isDefaultVisible = true;
                updatedItem.IsRequired = true;
                temp[val.id] = searchData?.[val.id];
                updatedBody[val.id] = temp[val.id];
              }

              tempCopy[index] = updatedItem;
            }
            break;
          default:
            break;
        }
      });
      setValues({ ...temp });
      const tempDocument = { ...equipDocumentData };

      ConfigData?.equipmentConfig?.properties?.imageSpecificationAndPrice.forEach((val) => {
        switch (val.type) {
          case "text":
          case "number":
          case "date":
          case "images":
            tempDocument[val.id] = searchData?.[val.id];
            updatedBody[val.id] = tempDocument[val.id];
            break;
          default:
            break;
        }
      });
      setEquipDocumentData({ ...tempDocument });
      const tempWarehouse = { ...warehouseData };
      ConfigData?.equipmentConfig?.properties?.warehouseInfo.forEach((val) => {
        switch (val.type) {
          case "text":
          case "number":
          case "date":
            tempWarehouse[val.id] = searchData?.[val.id];
            updatedBody[val.id] = tempWarehouse[val.id];
            break;
          case "options":
            if (val.id === "warehouse") {
              tempWarehouse[val.id] = searchData?.warehouse?.name;
              const tempID = searchData?.[val.id][Constants.MONGOOSE_ID];
              updatedBody[val.id] = tempID;
            }
            if (val.id === "equipmentCurrentLocation") {
              tempWarehouse[val.id] = searchData?.equipmentCurrentLocation;
              updatedBody[val.id] = tempWarehouse[val.id];
            }
            break;
          default:
            break;
        }
      });
      setWarehouseData({ ...tempWarehouse });
      setUpdatedBody(updatedBody);
    }
    return { tempCopy, tempProductCopy };
  };

  useEffect(() => {
    const tempForm = ConfigData?.equipmentConfig?.properties?.equipment;
    const productForm = ConfigData?.equipmentConfig?.properties?.imageSpecificationAndPrice;

    (async () => {
      const { tempCopy: newForm, tempProductCopy: newProductForm } = await handleSearchData(
        tempForm,
        productForm
      );

      setForm(newForm);
      setDocumentForm(newProductForm);
    })();
  }, [ConfigData.equipmentLoading, searchData]);

  const validate = () => {
    switch (tabValue) {
      case 1: {
        const newErrors = {};
        if (ConfigData?.equipmentConfig?.properties?.imageSpecificationAndPrice) {
          ConfigData?.equipmentConfig?.properties?.imageSpecificationAndPrice.forEach((item) => {
            if (item.IsRequired) {
              const itemValue = equipDocumentData?.[item.id];
              const dynamicField = equipDocumentData?.dynamicFields?.find(
                (val) => val?.title.replace(/\s/g, "") === item.id
              );

              if (
                (!item.questionId && !itemValue && item.parentFieldId === "") ||
                (item.type === "images" && itemValue.length === 0)
              ) {
                newErrors[item.id] = item.hint;
              } else if (item.questionId && !dynamicField) {
                newErrors[item.id] = item.hint;
              } else if (
                !item.questionId &&
                typeof itemValue === "string" &&
                itemValue.trimStart() === ""
              ) {
                newErrors[item.id] = Constants.INVALID_VALUE;
              } else if (
                !item.questionId &&
                item.type === "options" &&
                item.parentFieldId === "" &&
                (!itemValue || itemValue.length === 0)
              ) {
                newErrors[item.id] = item.hint;
              } else if (
                !item.questionId &&
                item.type === "options" &&
                item.parentFieldId !== "" &&
                Object.keys(equipDocumentData).findIndex(
                  (key) => key.toLocaleLowerCase() === item.parentFieldId.toLocaleLowerCase()
                ) === -1
              ) {
                newErrors[item.id] = item.hint;
              } else if (item.questionId && dynamicField?.value[0]?.trimStart() === "") {
                newErrors[item.id] = Constants.INVALID_VALUE;
              } else if (!item.questionId && item.type === "number" && itemValue < 0) {
                newErrors[item.id] = Constants.INVALID_VALUE;
              }
            }
          });
        }
        setEquipDocumentData((prevData) => ({
          ...prevData,
          errors: newErrors,
        }));
        return Object.values(newErrors).filter((val) => val !== "").length === 0;
      }
      case 2: {
        const WarehouseError = {};
        if (ConfigData?.equipmentConfig?.properties?.warehouseInfo) {
          ConfigData?.equipmentConfig?.properties?.warehouseInfo.forEach((item) => {
            if (item.IsRequired) {
              const itemValue = warehouseData?.[item.id];
              const dynamicField = warehouseData?.dynamicFields?.find(
                (val) => val?.title.replace(/\s/g, "") === item.id
              );

              if (!item.questionId && !itemValue && item.parentFieldId === "") {
                WarehouseError[item.id] = item.hint;
              } else if (item.questionId && !dynamicField) {
                WarehouseError[item.id] = item.hint;
              } else if (
                !item.questionId &&
                typeof itemValue === "string" &&
                itemValue.trimStart() === ""
              ) {
                WarehouseError[item.id] = Constants.INVALID_VALUE;
              } else if (
                !item.questionId &&
                item.type === "options" &&
                item.parentFieldId === "" &&
                (!itemValue || itemValue.length === 0)
              ) {
                WarehouseError[item.id] = item.hint;
              } else if (
                !item.questionId &&
                item.type === "options" &&
                item.parentFieldId !== "" &&
                Object.keys(warehouseData).findIndex(
                  (key) => key.toLocaleLowerCase() === item.parentFieldId.toLocaleLowerCase()
                ) === -1
              ) {
                WarehouseError[item.id] = item.hint;
              } else if (item.questionId && dynamicField?.value[0]?.trimStart() === "") {
                WarehouseError[item.id] = Constants.INVALID_VALUE;
              } else if (!item.questionId && item.type === "number") {
                if ((itemValue?.toString().includes(".") || itemValue <= 0) && !checkEquipmentId) {
                  WarehouseError[item.id] = Constants.INVALID_VALUE;
                } else if (!itemValue && !checkEquipmentId) {
                  WarehouseError[item.id] = item.hint;
                }
              }
            }
          });
        }
        setWarehouseData((prevData) => ({
          ...prevData,
          errors: WarehouseError,
        }));
        return Object.values(WarehouseError).filter((val) => val !== "").length === 0;
      }
      default:
        return false;
    }
  };

  const validateEquipment = () => {
    const newErrors = {};

    if (ConfigData?.equipmentConfig?.properties?.equipment) {
      ConfigData?.equipmentConfig?.properties?.equipment.forEach((item) => {
        const checkValue =
          item.parentFieldId !== "" ? item.dependentIds.includes(values?.equipmentType) : {};
        const itemValue = values?.[item.id] || updatedBody?.[item.id];
        if (item.IsRequired) {
          const dynamicField = values?.dynamicFields?.find(
            (val) => val?.title.replace(/\s/g, "") === item.id
          );
          if (!item.questionId && !itemValue && item.parentFieldId === "") {
            newErrors[item.id] = item.hint;
          } else if (item.questionId && !dynamicField) {
            newErrors[item.id] = item.hint;
          } else if (
            !item.questionId &&
            typeof itemValue === "string" &&
            itemValue.trimStart() === ""
          ) {
            newErrors[item.id] = Constants.INVALID_VALUE;
          } else if (
            !item.questionId &&
            item.type === "options" &&
            item.parentFieldId === "" &&
            (!itemValue || itemValue.length === 0)
          ) {
            newErrors[item.id] = item.hint;
          } else if (
            !item.questionId &&
            item.type === "options" &&
            item.parentFieldId !== "" &&
            Object.keys(values).findIndex(
              (key) => key.toLocaleLowerCase() === item.parentFieldId.toLocaleLowerCase()
            ) === -1
          ) {
            newErrors[item.id] = item.hint;
          } else if (item.questionId && dynamicField?.value[0]?.trimStart() === "") {
            newErrors[item.id] = Constants.INVALID_VALUE;
          } else if (item.type === "decimal-number" && parseFloat(itemValue, 10) === 0) {
            newErrors[item.id] = Constants.INVALID_VALUE;
          }
        }
        if (
          !item.IsRequired &&
          !itemValue &&
          checkValue === true &&
          item.type === "options-values"
        ) {
          newErrors[item.id] = Constants.TOTAL_VALUE_REQUIRED;
        } else if (!item.questionId && item.type === "options-values" && itemValue <= 0) {
          newErrors[item.id] = Constants.INVALID_TOTAL_VALUE;
        }
      });
    }

    setErrors(newErrors);
    return Object.values(newErrors).filter((val) => val !== "").length === 0;
  };

  const handleConfigChange = (name, value) => {
    const temp = { ...values };
    const updateCopy = { ...updatedBody };
    const equipmentValues = { ...updatedValues };
    if (name.includes("weight")) {
      temp[name] = value;
      equipmentValues[name] = value.replace(",", ".");
    } else {
      temp[name] = value;
      equipmentValues[name] = value;
    }
    const updatedFormCopy = form.map((item) => {
      const tempCopy = { ...temp };
      const checkID = /^\d/.test(tempCopy?.equipmentType)
        ? tempCopy?.equipmentType
        : updateCopy?.equipmentType;
      if (item.parentFieldId !== "" && !item.dependentIds.includes(checkID)) {
        delete equipmentValues?.[item?.id];
      }
      const updatedItem = { ...item };
      if (updatedItem.type === "options-values") {
        if (updatedItem.dependentIds.includes(checkID)) {
          updatedItem.isDefaultVisible = true;
          updatedItem.IsRequired = true;
        } else if (!updatedItem.dependentIds.includes(checkID)) {
          updatedItem.isDefaultVisible = false;
          updatedItem.IsRequired = false;
        }
      } else if (updatedItem.type === "text" && updatedItem.parentFieldId === "equipmentType") {
        if (updatedItem.dependentIds.includes(checkID)) {
          setQuantityType(true);
          updatedItem.isDefaultVisible = true;
          updatedItem.IsRequired = true;
        } else if (!updatedItem.dependentIds.includes(checkID)) {
          setQuantityType(false);
          updatedItem.isDefaultVisible = false;
          updatedItem.IsRequired = false;
          delete updatedBody?.serialNumber;
        }
      } else if (updatedItem.type === "options" && updatedItem.id === "price") {
        if (updatedItem.dependentIds.includes(checkID)) {
          setQuantityType(true);
          updatedItem.isDefaultVisible = true;
          updatedItem.IsRequired = true;
        } else if (!updatedItem.dependentIds.includes(checkID)) {
          setQuantityType(false);
          updatedItem.isDefaultVisible = false;
          updatedItem.IsRequired = false;
        }
      }
      if (name === "equipmentType" && item.id === "equipmentType") {
        item.options.forEach((val) => {
          if (val.id === value) {
            setCurrency(val.currency);
          }
        });
      }
      return updatedItem;
    });

    setForm(updatedFormCopy);
    setValues({ ...temp });
    setUpdatedValues(equipmentValues);
    setUpdatedBody((prevData) => ({
      ...prevData,
      ...equipmentValues,
    }));
  };

  const handleFilterType = async (e) => {
    const temp = [...filters];
    const { value } = e.target;
    if (value) {
      temp[0].selectedValue = value;
      setFilters(temp);
    }
  };

  useEffect(() => {
    const { list } = filters[0];
    const detailsData = list?.find((item) => item.title === filters[0].selectedValue);
    setSearchData(detailsData?.details);
  }, [filters[0]?.selectedValue]);

  const handleSearch = async (e) => {
    if (e.target.value === "") {
      // Clear the list and search data when the search field is empty
      setFilters((prev) => {
        const temp = [...prev];
        temp[0].list = [];
        temp[0].isLoading = false;
        return temp;
      });
      setSearchData(null);
      return;
    }
    setFilters((prev) => {
      const temp = [...prev];
      temp[0].isLoading = true;
      return temp;
    });

    const data = {
      search: e.target.value,
    };
    Object.keys(data).forEach((key) => {
      if (data[key] === "") {
        delete data[key];
      }
    });
    const res = await dispatch(ProductListThunk(paramCreater(data)));
    const temp = [...filters];
    const userList = res.payload.data.data?.map((item) => ({
      title: item?.name,
      details: item,
    }));
    const filteredEquipmentList = userList.filter((item) => item.title.toLowerCase() !== "all");

    temp[0].list = filteredEquipmentList;
    temp[0].isLoading = false;
    setFilters(temp);
    setUpdatedBody({ ...updatedBody, name: e.target.value });
  };

  const debouncedHandleSearch = (e) => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(async () => handleSearch(e), 300);
  };

  const handleWeightNumberChange = (id, value) => {
    const regex = /^\d+([,.]\d*)?$/;

    if (regex.test(value) || value === "") {
      handleConfigChange(id, value, id);
    }
  };

  const moveToNextTab = () => {
    if (tabValue < 2) {
      setTabValue(tabValue + 1);
    }
  };

  const moveToPreviousTab = () => {
    if (tabValue > 0) {
      setTabValue(tabValue - 1);
    }
  };

  const getButtonText = () => {
    if (loading) {
      return ButtonTitles.LOADING;
    }
    if (tabValue === 2) {
      return checkEquipmentId ? ButtonTitles.UPDATE : ButtonTitles.PUBLISH;
    }
    return checkEquipmentId ? ButtonTitles.UPDATE_AND_CONTINUE : ButtonTitles.SAVE_AND_CONTINUE;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    switch (tabValue) {
      case 0: {
        const val = validateEquipment();
        if (val) {
          setDocumentFormFilled(true);
          setWarehouseFormFilled(true);
          moveToNextTab();
        }
        break;
      }
      case 1:
        moveToNextTab();
        break;
      case 2: {
        setLoading(true);
        const val = validate();
        const val2 = validateEquipment();
        if (val && val2) {
          const { equipmentType, ...filteredBody } = updatedBody;
          const body = {
            ...updatedValues,
            ...filteredBody,
            equipmentType: equipmentType || updatedValues.equipmentType,
            name: updatedBody.name,
            serialNumber: updatedBody.serialNumber ? updatedBody.serialNumber : null,
          };
          const tempBody = { ...body };
          Object.entries(body).forEach(([key]) => {
            if (typeof tempBody[key] === "string") {
              tempBody[key] = tempBody[key].trim();
            } else if (typeof tempBody[key] === "object" && tempBody[key]?.length === 0) {
              tempBody[key] = null;
            }
          });
          try {
            if (checkEquipmentId) {
              const data = {
                body: tempBody,
                id: checkEquipmentId,
              };
              setDisableSubmit(true);
              const res = await dispatch(equipmentUpdateThunk(data));
              setDisableSubmit(false);
              if (res.payload.status === 200) {
                await dispatch(
                  openSnackbar({
                    message: Constants.EQUIPMENT_UPDATE_SUCCESS,
                    notificationType: Constants.NOTIFICATION_SUCCESS,
                  })
                );
                navigate("/client/inventory");
              } else if (res.payload.status === 422) {
                dispatch(
                  openSnackbar({
                    message: res.payload.data.data.error[0].equipmentType,
                    notificationType: Constants.NOTIFICATION_ERROR,
                  })
                );
              } else {
                dispatch(
                  openSnackbar({
                    message: Constants.SOMETHING_WENT_WRONG,
                    notificationType: Constants.NOTIFICATION_ERROR,
                  })
                );
              }
            } else {
              setDisableSubmit(true);
              const res = await dispatch(CreateNewProduct(body));
              setDisableSubmit(false);
              if (res.payload.status === 200) {
                await dispatch(
                  openSnackbar({
                    message: Constants.EQUIPMENT_CREATE_SUCCESS,
                    notificationType: Constants.NOTIFICATION_SUCCESS,
                  })
                );
                navigate("/client/inventory");
              } else if (res.payload.status === 422) {
                const newErrors = {};
                newErrors.serialNumber = res.payload.data.data.error[0].serialNumber;
                setErrors(newErrors);
                dispatch(
                  openSnackbar({
                    message: res.payload.data.data.error[0].serialNumber,
                    notificationType: Constants.NOTIFICATION_ERROR,
                  })
                );
              } else {
                dispatch(
                  openSnackbar({
                    message: Constants.SOMETHING_WENT_WRONG,
                    notificationType: Constants.NOTIFICATION_ERROR,
                  })
                );
              }
            }
          } catch (error) {
            dispatch(
              openSnackbar({
                message: Constants.SOMETHING_WENT_WRONG,
                notificationType: Constants.NOTIFICATION_ERROR,
              })
            );
          }
        } else {
          dispatch(
            openSnackbar({
              message: Constants.FILL_ALL_REQUIRED_FIELDS,
              notificationType: Constants.NOTIFICATION_ERROR,
            })
          );
        }
        setLoading(false);
        break;
      }
      default:
        break;
    }
  };

  const checkloader = () => {
    if (checkEquipmentId) {
      return Object.keys(values).length > 0;
    }
    return form?.length > 0;
  };

  useEffect(() => {
    if (!checkEquipmentId) {
      const tempForm = ConfigData?.equipmentConfig?.properties?.equipment;
      setForm(tempForm);

      setTimeout(() => {});
      setDocumentFormFilled(false);
      setWarehouseFormFilled(false);
      setValues({});
      setUpdatedBody({});
      setEquipDocumentData({
        errors: {},
      });
      setWarehouseData({
        errors: {},
      });
      setTabValue(0);
    }
    return dispatch(resetListState());
  }, [checkEquipmentId]);

  return (
    <DashboardLayout xPadding={0}>
      <MDBox px={3}>
        <DashboardNavbar />
        <MDBox display="flex" justifyContent="space-between">
          <PageTitle
            title={checkEquipmentId ? PageTitles.UPDATE_EQUIPMENT : PageTitles.REGISTER_EQUIPMENT}
          />
          {permission && (
            <CustomButton
              key="equipment-sample-file-export"
              title={ButtonTitles.DOWNLOAD_IMPORT_SAMPLE}
              icon={Icons.DOWNLOAD2}
              background={Colors.PRIMARY}
              color={Colors.WHITE}
              openModal={() =>
                handleEquipmentSampleFileExport(
                  process.env.REACT_APP_EQUIPMENT_IMPORT_SAMPLE_FILE_NAME,
                  process.env.REACT_APP_EQUIPMENT_IMPORT_SAMPLE_FILE_NAME.split(".")[0]
                )
              }
            />
          )}
        </MDBox>
      </MDBox>
      <Feature name={FeatureTags.SETUP_EQUIPMENT}>
        {checkloader() ? (
          <MDBox>
            {permission ? (
              <>
                <MDBox px={3}>
                  <MDBox
                    sx={{
                      height: "60px",
                      marginTop: 2,
                      width: "100%",
                      display: "flex",
                      flexWrap: "nowrap",
                      justifyContent: "center",
                      border: "1px solid #EAECF0",
                    }}
                  >
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      orientation="horizontal"
                      sx={{
                        margin: "0 auto",
                        flexGrow: 1,
                        flexShrink: 0,
                      }}
                    >
                      <Tab
                        defaultindex={0}
                        label="Equipment Details"
                        icon={
                          <HardwareOutlinedIcon
                            fontSize="medium"
                            sx={{
                              cursor: "pointer",
                              color: `${tabValue === 0 ? "#FF6600" : "#667085"}`,
                            }}
                          />
                        }
                        iconPosition="start"
                        sx={{
                          width: "100%",
                          padding: "12px 0px",
                          fontSize: pxToRem(18),
                          fontWeight: `${tabValue === 0 ? "600" : null}`,
                          borderRadius: 0,
                          color: `${tabValue === 0 ? "red" : "yellow"}`,
                          backgroundColor: "#ffffff",
                          borderBottom: `2px solid ${tabValue === 0 && "#FF6600"}`,
                          borderRight: "1px solid #EAECF0",
                        }}
                      />
                      <Tab
                        defaultindex={1}
                        label="Images, Specification & Price"
                        icon={
                          <ImageOutlinedIcon
                            fontSize="medium"
                            sx={{
                              cursor: "pointer",
                              color: `${tabValue === 1 ? "#FF6600" : "#667085"}`,
                            }}
                          />
                        }
                        iconPosition="start"
                        sx={{
                          width: "100%",
                          padding: "12px 0px",
                          fontSize: pxToRem(18),
                          fontWeight: `${tabValue === 1 ? "600" : null}`,
                          borderRadius: 0,
                          color: `${tabValue === 1 ? "#344054" : "#667085"}`,
                          backgroundColor: "#ffffff",
                          borderBottom: `2px solid ${tabValue === 1 && "#FF6600"}`,
                          borderRight: "1px solid #EAECF0",
                        }}
                      />
                      <Tab
                        defaultindex={2}
                        label="Warehouse Info & QR"
                        icon={
                          <MeetingRoomOutlinedIcon
                            fontSize="medium"
                            sx={{
                              cursor: "pointer",
                              color: `${tabValue === 2 ? "#FF6600" : "#667085"}`,
                            }}
                          />
                        }
                        iconPosition="start"
                        sx={{
                          width: "100%",
                          padding: "12px 0px",
                          fontSize: pxToRem(18),
                          fontWeight: `${tabValue === 2 ? "600" : null}`,
                          borderRadius: 0,
                          color: `${tabValue === 2 ? "#344054" : "#667085"}`,
                          backgroundColor: "#ffffff",
                          borderBottom: `2px solid ${tabValue === 2 && "#FF6600"}`,
                        }}
                      />
                    </Tabs>
                  </MDBox>
                  <StyledMDBox>
                    {tabValue === 0 && (
                      <MDBox
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: pxToRem(16),
                        }}
                      >
                        <MDBox
                          sx={{
                            width: "100%",
                            gap: pxToRem(16),
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            sx={{ display: "flex", justifyContent: "flex-start" }}
                          >
                            <Grid item xs={12}>
                              <MDTypography
                                sx={{
                                  fontSize: pxToRem(24),
                                  fontWeight: 600,
                                  color: "#667085",
                                  padding: "12px 0px",
                                }}
                              >
                                Equipment Information
                              </MDTypography>
                            </Grid>
                            {form?.length > 0
                              ? form
                                  ?.filter((item) => item.isDefaultVisible)
                                  .map((item) => {
                                    const fieldValue = values[item.id] || "";
                                    switch (item.type) {
                                      case "text":
                                        return (
                                          <Grid item lg={6} sm={12} key={item.id}>
                                            <FTextField
                                              label={item.title}
                                              placeholder={item?.hint}
                                              name={item?.id}
                                              id={item?.id}
                                              type="text"
                                              error={Boolean(errors[item.id])}
                                              helperText={errors[item.id]}
                                              value={fieldValue}
                                              handleChange={(e) =>
                                                handleConfigChange(
                                                  item?.id,
                                                  e.target.value,
                                                  item?.id
                                                )
                                              }
                                            />
                                          </Grid>
                                        );
                                      case "search":
                                        return (
                                          <Grid item lg={12} sm={12} key={item.id}>
                                            <SearchBar
                                              label={
                                                item?.IsRequired === true
                                                  ? `${item.title}*`
                                                  : item.title
                                              }
                                              width="100%"
                                              marginRight={0}
                                              options={
                                                filters[0]?.list
                                                  .filter(
                                                    (val) => val[Constants.MONGOOSE_ID] !== "all"
                                                  ) // Exclude "All" option
                                                  .map((val) => val.title) || []
                                              }
                                              value={
                                                filters[0]?.selectedValue || updatedBody?.[item.id]
                                              }
                                              filters={filters}
                                              freeSolos
                                              error={Boolean(errors[item.id])}
                                              helperText={errors[item.id]}
                                              placeholder={item.title}
                                              isLoading={filters[0]?.isLoading}
                                              handleFilterChange={(e, value) => {
                                                handleFilterType({
                                                  target: {
                                                    name: filters[0].inputLabel,
                                                    value,
                                                  },
                                                });
                                              }}
                                              debouncedHandleSearch={debouncedHandleSearch}
                                            />
                                          </Grid>
                                        );
                                      case "options":
                                        return (
                                          <Grid item lg={6} sm={12} key={item.id}>
                                            {(() => {
                                              const equipvalue =
                                                item.parentFieldId !== ""
                                                  ? item.options.find(
                                                      (opt) =>
                                                        opt.isVisibleForOptions.includes(
                                                          values?.[item.parentFieldId]
                                                        ) ||
                                                        opt.isVisibleForOptions.includes(
                                                          values?.[item.parentFieldId]?.id
                                                        )
                                                    )
                                                  : {};
                                              return (
                                                <ConfigDropdown
                                                  label={
                                                    item?.IsRequired ? `${item.title}*` : item.title
                                                  }
                                                  menu={item?.options.map((val) => ({
                                                    [Constants.MONGOOSE_ID]: val?.id,
                                                    title: val?.title,
                                                  }))}
                                                  name={item?.id}
                                                  id={item?.id}
                                                  disabled={
                                                    item?.parentFieldId !== "" ||
                                                    (checkEquipmentId &&
                                                      item.id === "equipmentType")
                                                  }
                                                  handleChange={(e, value, id) => {
                                                    if (item.parentFieldId === "") {
                                                      handleConfigChange(e, value, id);
                                                    }
                                                  }}
                                                  value={
                                                    (item.parentFieldId !== "" &&
                                                      (equipvalue?.id || "")) ||
                                                    values?.[item?.id] ||
                                                    fieldValue
                                                  }
                                                  error={errors?.[item.id]}
                                                  helperText={errors?.[item.id]}
                                                  minWidth={pxToRem(317)}
                                                  maxContent={defaultData.MEDIUM_CONTENT_LENGTH}
                                                  startAdornment={
                                                    item.id === "price" &&
                                                    currency !== null &&
                                                    currency !== "" ? (
                                                      <MDTypography
                                                        variant="body"
                                                        sx={{ color: Colors.GREY }}
                                                      >
                                                        {currency}
                                                      </MDTypography>
                                                    ) : (
                                                      ""
                                                    )
                                                  }
                                                />
                                              );
                                            })()}
                                          </Grid>
                                        );
                                      case "number":
                                        return (
                                          <Grid item lg={6} sm={12} key={item.id}>
                                            <FTextField
                                              label={
                                                item?.IsRequired ? `${item.title}*` : item.title
                                              }
                                              placeholder={item?.hint}
                                              name={item?.id}
                                              id={item?.id}
                                              type="number"
                                              error={Boolean(errors[item.id])}
                                              helperText={errors[item.id]}
                                              value={values[item.id] || ""}
                                              handleChange={(e) =>
                                                handleConfigChange(
                                                  item?.id,
                                                  e.target.value,
                                                  item?.id
                                                )
                                              }
                                            />
                                          </Grid>
                                        );
                                      case "decimal-number":
                                        return (
                                          <Grid item lg={6} sm={12} key={item.id}>
                                            <FTextField
                                              label={
                                                item?.IsRequired ? `${item.title}*` : item.title
                                              }
                                              placeholder={item?.hint}
                                              name={item?.id}
                                              id={item?.id}
                                              error={Boolean(errors[item.id])}
                                              helperText={errors[item.id]}
                                              value={values[item.id] || ""}
                                              handleChange={(e) =>
                                                handleWeightNumberChange(item?.id, e.target.value)
                                              }
                                            />
                                          </Grid>
                                        );
                                      case "date":
                                        return (
                                          <Grid item lg={6} sm={12} key={item.id}>
                                            <MDBox
                                              sx={{ minWidth: "100%", mt: 0, mr: 1, zIndex: 9999 }}
                                              display="flex"
                                            >
                                              <ReactDatePicker
                                                selected={
                                                  (values?.[item.id] &&
                                                    moment(
                                                      values?.[item.id].split(".")[0]
                                                    ).toDate()) ||
                                                  null
                                                }
                                                onChange={(date) =>
                                                  handleConfigChange(
                                                    item.id,
                                                    moment(date)
                                                      .format(defaultData.DATABSE_DATE_FORMAT)
                                                      .toString(),
                                                    item.questionId ? item.questionId : item.id
                                                  )
                                                }
                                                customInput={
                                                  <DateTime
                                                    value={fieldValue}
                                                    item={item}
                                                    label={`${item?.title}${
                                                      item?.IsRequired ? "*" : ""
                                                    }`}
                                                    errors={values.errors?.[item.id]}
                                                    helperText={values.errors?.[item.id]}
                                                    placeholder={item?.hint}
                                                  />
                                                }
                                                placeholderText={item?.hint}
                                                dateFormat={
                                                  defaultData.REACTDATETIMEPICKER_DATE_FORMAT
                                                }
                                              />
                                            </MDBox>
                                          </Grid>
                                        );
                                      case "options-values":
                                        return (
                                          <Grid item lg={6} sm={12} key={item.id}>
                                            <FTextField
                                              label={
                                                item?.IsRequired ? `${item.title}*` : item.title
                                              }
                                              placeholder={item?.hint}
                                              name={item?.id}
                                              id={item?.id}
                                              type="number"
                                              error={Boolean(errors[item.id])}
                                              helperText={errors[item.id]}
                                              value={values[item.id] || ""}
                                              handleChange={(e) =>
                                                handleConfigChange(
                                                  item?.id,
                                                  e.target.value,
                                                  item?.id
                                                )
                                              }
                                              InputProps={
                                                currency !== null && currency !== ""
                                                  ? {
                                                      startAdornment: (
                                                        <MDTypography variant="body">
                                                          {currency}
                                                        </MDTypography>
                                                      ),
                                                    }
                                                  : {}
                                              }
                                            />
                                          </Grid>
                                        );
                                      default:
                                        return null;
                                    }
                                  })
                              : null}
                          </Grid>
                        </MDBox>
                      </MDBox>
                    )}
                    {tabValue === 1 && (
                      <ProductDocument
                        data={equipDocumentData}
                        setData={setEquipDocumentData}
                        documentForm={documentForm}
                        setUpdatedBody={setUpdatedBody}
                        updatedBody={updatedBody}
                      />
                    )}
                    {tabValue === 2 && (
                      <ProductWarehouse
                        warehouseData={warehouseData}
                        setWarehouseData={setWarehouseData}
                        checkEquipmentId={checkEquipmentId}
                        setUpdatedBody={setUpdatedBody}
                        searchData={searchData}
                        quantityType={quantityType}
                      />
                    )}
                  </StyledMDBox>
                </MDBox>
                <MDBox
                  sx={{
                    marginTop: 3,
                    backgroundColor: "#fff",
                    height: "71px",
                    padding: "12px 32px 16px 32px",
                    border: "1px solid #E0E6F5",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <MDBox sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    {tabValue > 0 && ( // Show "Back" button only if not on the first tab
                      <MDButton
                        variant="outlined"
                        color="info"
                        style={{ textTransform: "none", boxShadow: "none" }}
                        onClick={moveToPreviousTab}
                        sx={{ marginRight: "16px" }} // Use the function to move to the previous tab
                      >
                        <span style={{ fontSize }}>Back</span>
                      </MDButton>
                    )}
                    <MDButton
                      variant="contained"
                      disabled={disableSubmit}
                      color="info"
                      style={{ textTransform: "none", boxShadow: "none" }}
                      onClick={handleSubmit}
                    >
                      <span style={{ fontSize }}>{getButtonText()}</span>
                    </MDButton>
                  </MDBox>
                </MDBox>
              </>
            ) : (
              <AccessDenied />
            )}
          </MDBox>
        ) : (
          <MDBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {Icons.LOADING2}
          </MDBox>
        )}
      </Feature>
    </DashboardLayout>
  );
}

ConfigEquipment.defaultProps = {
  handleEquipmentSampleFileExport: () => {},
};

ConfigEquipment.propTypes = {
  handleEquipmentSampleFileExport: PropTypes.func,
};

export default ExportHOC(ConfigEquipment);
