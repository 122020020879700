import pxToRem from "assets/theme/functions/pxToRem";

const CardBreakPoint = {
  baseTitleBreakPoint: {
    xs: pxToRem(12),
    sm: pxToRem(16),
    md: pxToRem(16),
    lg: pxToRem(16),
    xl: pxToRem(16),
    xxl: pxToRem(16),
    fhd: pxToRem(18),
    uhd: pxToRem(20),
  },
};

export default CardBreakPoint;
