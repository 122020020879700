import React from "react";

// 3rd party library
import PropTypes from "prop-types";

// Common Components
import CustomDrawer from "components/Drawer/CustomDrawer";
import MDBox from "components/MDBox";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import DynamicTypeData from "components/Table/DynamicTypeData";
import FontComponent from "components/Responsive/fonts";
import { ModalBreakPoint, TitleBreakPoint } from "components/Responsive/BreakPoints";
import pattern from "utils/Patterns";

// MUI Components
import { Divider, Icon } from "@mui/material";

// Constants
import Constants, { Icons, Colors, defaultData } from "utils/Constants";

// 3rd party library
import moment from "moment";

function ViewReportAnswer({ viewReportAnswerAnchor, setViewReportAnswerAnchor, reportAnswer }) {
  const supportedContentBreakPoint = FontComponent({
    sizes: TitleBreakPoint.extraSmallTitleBreakPoint,
  });
  const answerBreakPoint = FontComponent({ sizes: TitleBreakPoint.baseTitleBreakPoint });
  const handleCloseReportAnswerDrawer = async () => {
    setViewReportAnswerAnchor({ right: false });
  };
  return (
    <CustomDrawer defaultAnchor={viewReportAnswerAnchor}>
      <MDBox
        sx={{
          backgroundColor: Colors.PRIMARY,
          padding: pxToRem(15),
          scrollX: "auto",
        }}
        px={pxToRem(24)}
        display="flex"
        justifyContent="start"
        alignItems="center"
        height={pxToRem(74)}
      >
        <Icon
          sx={{ cursor: "pointer", color: "beige", marginRight: "15px" }}
          fontSize="medium"
          onClick={handleCloseReportAnswerDrawer}
        >
          {Icons.CROSS}
        </Icon>
        <MDTypography
          sx={{
            color: "var(--white-900, #ffffff)",
            fontFamily: "Inter",
            fontSize: FontComponent({ sizes: ModalBreakPoint.baseTitleBreakPoint }),
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: pxToRem(28),
            textAlign: "center",
            textTransform: "capitalize",
          }}
        >
          Report Answers
        </MDTypography>
      </MDBox>
      <Divider />
      <MDBox mt={4} sx={{ paddingBottom: pxToRem(20), paddingLeft: pxToRem(50) }}>
        <MDBox>
          <MDTypography
            sx={{
              fontWeight: 600,
              fontSize: pxToRem(24),
              lineHeight: pxToRem(16),
              height: pxToRem(24),
              color: "#475467",
            }}
          >
            Question
          </MDTypography>
          <MDBox
            sx={{
              paddingY: pxToRem(14),
            }}
          >
            <MDTypography
              sx={{
                fontWeight: 600,
                color: Colors.PRIMARY,
                height: "auto",
                width: "auto",
                wordWrap: "break-word",
                whiteSpace: "pre-line",
                textAlign: "justify",
                padding: "0px 12px",
                lineHeight: pxToRem(25),
              }}
            >
              {`${reportAnswer.questionNo}. ${reportAnswer.question}`}
            </MDTypography>
          </MDBox>
        </MDBox>
        {reportAnswer?.supportedContent?.length > 0 && (
          <MDBox
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            flexWrap="wrap"
            gap={2}
          >
            {reportAnswer?.supportedContent?.length > 0 && (
              <MDBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                flexWrap="wrap"
                gap={5}
              >
                {reportAnswer?.supportedContent?.map((supportData) => (
                  <MDBox
                    key={supportData?.subText}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    {supportData?.path?.length > 0 && (
                      <img
                        src={supportData?.path.replace(pattern.LAST_SLASH, "/compressed/$1")}
                        alt={supportData?.subText}
                        style={{
                          width: pxToRem(124),
                          height: pxToRem(86),
                          marginRight: pxToRem(10),
                          borderRadius: "8px",
                        }}
                      />
                    )}
                    <MDTypography
                      sx={{
                        fontSize: supportedContentBreakPoint,
                        fontWeight: "medium",
                        lineHeight: pxToRem(18),
                        marginTop: pxToRem(supportData?.path?.length > 0 ? 10 : 0),
                        color: "#B3B9C3",
                        width: "auto",
                        wordWrap: "break-word",
                        whiteSpace: "pre-line",
                        textAlign: "justify",
                      }}
                    >
                      {supportData?.subText}
                    </MDTypography>
                  </MDBox>
                ))}
              </MDBox>
            )}
          </MDBox>
        )}

        <MDBox
          sx={{
            marginTop: pxToRem(20),
          }}
        >
          <MDTypography
            sx={{
              fontWeight: 600,
              fontSize: pxToRem(24),
              lineHeight: pxToRem(36),
              height: pxToRem(24),
              color: "#475467",
            }}
          >
            Answers
          </MDTypography>
          <MDBox
            sx={{
              paddingX: pxToRem(24),
              paddingY: pxToRem(16),
            }}
          >
            {(() => {
              // Check if there are any answers
              const hasAnswers = reportAnswer.answer?.some(
                (element) => element.userAnswers?.length > 0
              );

              if (!hasAnswers) {
                return (
                  <MDTypography variant="h5" color="secondary">
                    No answers found
                  </MDTypography>
                );
              }

              return reportAnswer.answer.flatMap((element, typeIdx) =>
                element.userAnswers?.length > 0
                  ? element.userAnswers.flatMap((item2) => {
                      const usersMap = new Map(); // Map to keep track of users

                      return item2.answers.map((answer, ansIdx) => {
                        const correspondingTitle = element.title.find(
                          (title) => title[Constants.MONGOOSE_ID] === answer.answerTitleId
                        );

                        // Check if the user has been encountered before
                        const userKey = `${item2.createdBy.firstName} ${item2.createdBy.lastName}`;
                        const isFirstOccurrence = !usersMap.has(userKey);

                        // If it's the first occurrence, add the user to the map
                        if (isFirstOccurrence) {
                          usersMap.set(userKey, true);
                        }

                        return (
                          <MDBox key={answer.answerTitleId}>
                            {/* Render user's name only if it's the first occurrence */}
                            {isFirstOccurrence && (
                              <MDTypography
                                sx={{
                                  fontWeight: 600,
                                  fontSize: answerBreakPoint,
                                  lineHeight: pxToRem(36),
                                  height: pxToRem(24),
                                  color: "#475467",
                                  textTransform: "capitalize",
                                }}
                              >
                                Answer by{" "}
                                {`${item2.createdBy.firstName} ${
                                  item2.createdBy.lastName
                                } (${moment(item2?.updatedAt).format(
                                  defaultData.WEB_24_HOURS_FORMAT
                                )})`}
                              </MDTypography>
                            )}
                            <MDBox sx={{ display: "flex", alignItems: "start" }}>
                              <MDTypography
                                sx={{
                                  marginTop: pxToRem(20),
                                  fontWeight: 500,
                                  fontSize: answerBreakPoint,
                                  color: "#475467",
                                }}
                              >
                                {`${reportAnswer.questionNo}.${typeIdx + 1}.${ansIdx + 1}.`}
                              </MDTypography>
                              <MDBox>
                                <MDTypography
                                  sx={{
                                    marginTop: pxToRem(20),
                                    fontWeight: 500,
                                    fontSize: answerBreakPoint,
                                    color: "#475467",
                                    marginLeft: pxToRem(7),
                                  }}
                                >
                                  {`${correspondingTitle?.value}`}
                                </MDTypography>
                                <MDBox>
                                  <DynamicTypeData
                                    type={element?.parameterType?.uniqueKey}
                                    data={answer.answer}
                                    compressedData={answer.answer.replace(
                                      pattern.LAST_SLASH,
                                      "/compressed/$1"
                                    )}
                                  />
                                </MDBox>
                              </MDBox>
                              <Divider />
                            </MDBox>
                          </MDBox>
                        );
                      });
                    })
                  : null
              );
            })()}
          </MDBox>
        </MDBox>
      </MDBox>
    </CustomDrawer>
  );
}

ViewReportAnswer.defaultProps = {
  viewReportAnswerAnchor: {
    right: false,
  },
};

ViewReportAnswer.propTypes = {
  viewReportAnswerAnchor: PropTypes.objectOf(PropTypes.any),
  setViewReportAnswerAnchor: PropTypes.func.isRequired,
  reportAnswer: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ViewReportAnswer;
