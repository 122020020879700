// MUI Components
import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

// Custom Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FontComponent from "components/Responsive/fonts";

// 3rd party
import PropTypes from "prop-types";
import { useRef, useState, useEffect } from "react";

export default function Author({ name, icon, nickName, iconMargin, style }) {
  const fontSize = FontComponent({ sizes: {} });
  const nameRef = useRef(null);
  const nickNameRef = useRef(null);
  const [isNameEllipsisActive, setIsNameEllipsisActive] = useState(false);
  const [isNickNameEllipsisActive, setIsNickNameEllipsisActive] = useState(false);

  // Debounce the ellipsis check
  const debounce = (fn, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };

  // Function to check ellipsis activation
  const checkEllipsis = () => {
    if (nameRef.current) {
      setIsNameEllipsisActive(nameRef.current.scrollWidth > nameRef.current.clientWidth);
    }
    if (nickNameRef.current) {
      setIsNickNameEllipsisActive(
        nickNameRef.current.scrollWidth > nickNameRef.current.clientWidth
      );
    }
  };

  // Using ResizeObserver to detect size changes dynamically
  useEffect(() => {
    const observer = new ResizeObserver(debounce(checkEllipsis, 100));

    if (nameRef.current) {
      observer.observe(nameRef.current);
    }

    if (nickNameRef.current) {
      observer.observe(nickNameRef.current);
    }

    // Initial check
    checkEllipsis();

    // Cleanup observer on component unmount
    return () => {
      if (nameRef.current) observer.unobserve(nameRef.current);
      if (nickNameRef.current) observer.unobserve(nickNameRef.current);
    };
  }, [name, nickName]);

  const renderWithTooltip = (content, ref, isEllipsisActive) =>
    isEllipsisActive ? (
      <Tooltip title={content}>
        <MDTypography
          variant="caption"
          ref={ref}
          sx={{
            ...style,
            fontSize,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {content}
        </MDTypography>
      </Tooltip>
    ) : (
      <MDTypography
        variant="caption"
        ref={ref}
        sx={{
          ...style,
          fontSize,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {content}
      </MDTypography>
    );

  return (
    <MDBox
      lineHeight={1}
      sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}
      noWrap
    >
      <MDBox lineHeight={1} sx={{ display: "flex", flexDirection: "row" }} noWrap>
        {renderWithTooltip(name, nameRef, isNameEllipsisActive)}
        <Typography
          sx={{ ...style, fontSize }}
          display="block"
          variant="caption"
          ml={iconMargin}
          noWrap
        >
          {icon ?? ""}
        </Typography>
      </MDBox>
      <MDBox lineHeight={1}>
        {renderWithTooltip(nickName, nickNameRef, isNickNameEllipsisActive)}
      </MDBox>
    </MDBox>
  );
}

Author.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  nickName: PropTypes.string,
  iconMargin: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.any),
};

Author.defaultProps = {
  name: "",
  icon: "",
  nickName: "",
  iconMargin: 0,
  style: { textTransform: "capitalize" },
};
