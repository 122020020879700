// React
import React from "react";

// Material Components
import { Autocomplete, InputAdornment } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import FontComponent from "components/Responsive/fonts";

// Common Commponents
import pxToRem from "assets/theme/functions/pxToRem";

// Utils
import { Icons } from "utils/Constants";

// 3rd party
import PropTypes from "prop-types";

export default function CustomSearch({
  filters,
  handleFilterChange,
  options,
  value,
  debouncedHandleSearch,
  placeholder,
  label = "Search",
  width = pxToRem(300),
  marginRight = 2,
  freeSolos,
  error,
  helperText,
  disabled,
  isLoading = false,
}) {
  // Function to preprocess the input value
  const preprocessInputValue = (values) => values.trim(); // Remove leading and trailing spaces
  const fontSize = FontComponent({ sizes: {} });

  return (
    <MDBox display="flex" alignItems="start" sx={{ flexDirection: "column", marginRight, mt: 2.5 }}>
      <MDTypography variant="caption" mb={1} sx={{ fontSize, fontWeight: 600, color: "#344054" }}>
        {label}
      </MDTypography>
      <Autocomplete
        disablePortal
        freeSolo={freeSolos}
        disabled={disabled}
        id="combo-box-demo"
        options={options}
        value={value}
        noOptionsText="No data found"
        filterOptions={(option, state) => {
          const trimmedInputValue = preprocessInputValue(state.inputValue);
          return option.filter((option2) =>
            option2?.toLowerCase()?.includes(trimmedInputValue?.toLowerCase())
          );
        }}
        sx={{
          width,
          "& .MuiOutlinedInput-root": {
            padding: 0,
            height: 40,
            backgroundColor: "#fff",
            paddingX: "8px !important",
            fontSize,
            fontWeight: 600,
            color: "#344054",
          },
        }}
        renderInput={(params) => (
          <MDInput
            {...params}
            placeholder={placeholder}
            pr={0}
            error={error}
            helperText={helperText}
            FormHelperTextProps={{
              sx: { marginLeft: 0, marginTop: 1, color: "red" },
            }}
            sx={{ textTransform: "capitalize" }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {filters && isLoading ? Icons.LOADING : Icons.SEACRH}
                </InputAdornment>
              ),
            }}
          />
        )}
        onChange={handleFilterChange}
        onKeyUp={debouncedHandleSearch}
      />
    </MDBox>
  );
}

CustomSearch.defaultProps = {
  filters: false,
  handleFilterChange: () => {},
  options: [],
  value: "",
  debouncedHandleSearch: () => {},
  placeholder: "",
  label: "Search",
  width: pxToRem(300),
  marginRight: 2,
  freeSolos: false,
  error: false,
  helperText: "",
  disabled: false,
  isLoading: false,
};

CustomSearch.propTypes = {
  filters: PropTypes.bool,
  handleFilterChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.string,
  debouncedHandleSearch: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.string,
  marginRight: PropTypes.number,
  freeSolos: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};
