/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// MUI Components
import { IconButton } from "@mui/material";

// Custom Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Status from "components/Table/Status";
import Author from "components/Table/Author";
import By from "components/Table/By";

// 3rd party library
import moment from "moment";

// Redux
import { useSelector } from "react-redux";

// Constants
import Constants, { Icons, defaultData } from "utils/Constants";
import FontComponent from "components/Responsive/fonts";

export default function DailyShiftData(handleOpenDeleteModal, shiftList) {
  const [rows, setRows] = useState([]);
  const ConfigData = useSelector((state) => state.config);
  const permission = ConfigData?.screens?.[5]?.screensInfo?.agreement;
  const fontSize = FontComponent({ sizes: {} });

  const formateDuration = (duration) => {
    const [hours, minutes] = duration.split(":");
    const formattedHours = hours ? `${hours} hrs` : "0 hrs";
    const formattedMinutes = minutes ? `${minutes} min` : "0 min";
    return `${formattedHours} ${formattedMinutes}`;
  };

  useEffect(() => {
    if (shiftList && permission?.read) {
      const list = shiftList.map((item, index) => {
        const temp = {
          srNo: <Author name={index + 1} />,
          project: (
            <Author name={item?.projects[0]?.title} nickName={item?.defaultProject?.title} />
          ),
          team: <Author name={item?.teams[0]?.teamsWfmName} />,
          status: <Status title={`${item?.status.replace("_", " ")}`} />,
          startTime: (
            <MDTypography
              variant="caption"
              sx={{ cursor: "pointer", fontSize }}
              component={Link}
              to={`/client/shifts/${item?.[Constants.MONGOOSE_ID]}`}
            >
              {item?.startDate
                ? moment(item?.startDate).format(defaultData.WEB_24_HOURS_FORMAT)
                : "---"}
            </MDTypography>
          ),
          duration: (
            <MDTypography variant="caption" sx={{ fontSize }}>
              {item?.duration ? formateDuration(item?.duration) : "0 hrs 0 min"}
            </MDTypography>
          ),
          createdBy: (
            <By
              name={`${item?.createdBy?.firstName ?? ""} ${item?.createdBy?.lastName ?? ""}`}
              isSuperAdmin={item?.createdBy?.role?.[0]?.title === defaultData.SUPER_ADMIN_ROLE}
              when={moment(item?.createdAt).format(defaultData.WEB_DATE_FORMAT)}
            />
          ),
          action: (
            <MDBox>
              {permission?.read && (
                <IconButton
                  aria-label="fingerprint"
                  color="info"
                  component={Link}
                  to={`/client/shifts/${item?.[Constants.MONGOOSE_ID]}`}
                >
                  {Icons.VIEW}
                </IconButton>
              )}
              &nbsp;
              {permission?.delete && (
                <IconButton
                  aria-label="fingerprint"
                  color="error"
                  onClick={() => handleOpenDeleteModal(item?.[Constants.MONGOOSE_ID])}
                >
                  {Icons.DELETE}
                </IconButton>
              )}
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [shiftList, ConfigData]);

  return {
    columns: [
      { Header: "No.", accessor: "srNo", width: "2%" },
      { Header: "Project", accessor: "project", align: "left", width: "30%" },
      { Header: "Team", accessor: "team", align: "left", width: "15%" },
      { Header: "Start Time", accessor: "startTime", align: "left", width: "15%" },
      { Header: "Duration", accessor: "duration", align: "left", width: "13%" },
      { Header: "Status", accessor: "status", align: "left", width: "10%" },
      { Header: "Created By", accessor: "createdBy", align: "left", width: "15%" },
      { Header: "Action", accessor: "action", width: "10%", align: "left" },
    ],

    rows,
  };
}
