import { useState } from "react";

// MUI Components
import { FormControl, FormHelperText, IconButton, MenuItem, Select, Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Custom Component
import FormControlErrorStyles from "assets/style/Component";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FontComponent from "components/Responsive/fonts";
import { TitleBreakPoint } from "components/Responsive/BreakPoints";

// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// Constants
import Constants, { Colors, defaultData } from "utils/Constants";

// 3rd party
import PropTypes from "prop-types";

export default function FormDropdown({
  width,
  label,
  id,
  name,
  defaultValue,
  value,
  hint,
  handleChange,
  menu,
  error,
  helperText,
  marginBottom,
  disabled = false,
  maxContent = defaultData.SMALLER_CONTENT_LENGTH,
  variant = "outlined",
  height = 45,
  mr,
}) {
  const [open, setOpen] = useState(false);
  const fontSize = FontComponent({ sizes: {} });
  const inputBreakPoint = FontComponent({ sizes: TitleBreakPoint.baseTitleBreakPoint });

  return (
    <FormControl
      variant={variant}
      sx={{
        mr,
        ml: 0,
        mt: pxToRem(8),
        width: "100%", // Ensures it takes full available width
        maxWidth: width || "600px", // Limits max width for large screens
        marginBottom,
        maxHeight: 400,
        ...FormControlErrorStyles,
      }}
      error={error}
      size="small"
    >
      {label !== "" && (
        <MDTypography variant="caption" mb={1} sx={{ fontSize, fontWeight: 500, color: "#344054" }}>
          {label}
        </MDTypography>
      )}
      <MDBox
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          backgroundColor: disabled ? Colors.DISABLED : "transparent",
        }}
      >
        <Select
          displayEmpty
          id={id}
          name={name}
          {...(!value && { defaultValue })}
          {...(!defaultValue && { value })}
          placeholder={hint}
          disabled={disabled}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          sx={{
            height,
            minWidth: "100%",
            "& .MuiInputBase-input": {
              fontSize: inputBreakPoint,
              fontWeight: 400,
              color: "#667085",
            },
            textTransform: "capitalize",
            backgroundColor: "black",
            paddingY: "0.65rem",
            paddingRight: "0.55rem",
            maxHeight: 100,
            cursor: "pointer",
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 29,
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                maxHeight: 200,
                opacity: 1,
                transform: "none",
                border: "1px solid #D0D5DD",
              },
            },
          }}
          onChange={(e) => handleChange(name, e.target.value, id)}
        >
          <MenuItem disabled value="">
            Select
          </MenuItem>
          {menu.length > 0 ? (
            menu.map((item) => (
              <MenuItem
                value={item[Constants.MONGOOSE_ID] || item}
                id={item[Constants.MONGOOSE_ID] || item}
                sx={{
                  textTransform: "capitalize",
                  maxHeight: 400,
                  fontSize: inputBreakPoint,
                  fontWeight: 400,
                  marginTop: "4px",
                  color: "#667085",
                }}
                key={item[Constants.MONGOOSE_ID] || item}
              >
                {item && item?.title?.length > maxContent ? (
                  <Tooltip title={item.title}>
                    <span>{`${item.title.slice(0, maxContent)}...`}</span>
                  </Tooltip>
                ) : (
                  item.title || item
                )}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No data available</MenuItem>
          )}
        </Select>
        <IconButton
          onClick={() => !disabled && setOpen(!open)}
          sx={{
            position: "absolute",
            top: "50%",
            right: 0,
            transform: "translateY(-50%)",
            zIndex: 1,
          }}
        >
          <KeyboardArrowDownIcon sx={{ color: disabled ? "#CDD3D9" : "#667085" }} />
        </IconButton>
      </MDBox>
      <FormHelperText sx={{ marginLeft: 0 }}>{helperText}</FormHelperText>
    </FormControl>
  );
}

FormDropdown.defaultProps = {
  width: "100%", // Defaults to full width but responsive
  label: "",
  id: "",
  name: "",
  defaultValue: "",
  value: "",
  hint: "",
  handleChange: () => {},
  menu: [],
  error: false,
  helperText: "",
  marginBottom: "",
  mr: 2,
  disabled: false,
  maxContent: defaultData.SMALLER_CONTENT_LENGTH,
  variant: "outlined",
  height: 45,
};

FormDropdown.propTypes = {
  width: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  hint: PropTypes.string,
  handleChange: PropTypes.func,
  menu: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  marginBottom: PropTypes.string,
  mr: PropTypes.number,
  disabled: PropTypes.bool,
  maxContent: PropTypes.number,
  variant: PropTypes.string,
  height: PropTypes.number,
};

// FDropdown menu modal
export function FormDropdownModal(mongooseId, title) {
  return {
    [Constants.MONGOOSE_ID]: mongooseId,
    title,
  };
}
