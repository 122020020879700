import React, { useEffect, useState } from "react";

// Material ui
import { Divider } from "@mui/material";
import MDBox from "components/MDBox";

// Custom Components
import TrainingMatrixData from "layouts/personnelManagement/TrainingMatrix/data/TrainingMatrixData";
import DataTable from "examples/Tables/DataTable/GroupedRowTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import ResetFilterButton from "components/Buttons/ResetButton";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import ExportHOC from "examples/HigherOrderComponents/ExportHOC";
import CustomAutoComeplete from "components/Dropdown/CustomAutoComeplete";
import MultiSelectDropdown from "components/Dropdown/MultiSelectDropdown";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { projectListThunk, memberThunk } from "redux/Thunks/FieldsData";
import getAllCertificateType from "redux/Thunks/certificateQuestion";
import getTrainingMatrixData, {
  exportTrainingMatrixCertificate,
} from "redux/Thunks/TrainingMatrix";
import { setStoreFilters, resetFilters } from "redux/Slice/Filter";
// utils and constants
import Constants, {
  defaultData,
  PageTitles,
  Colors,
  Icons,
  FeatureTags,
  ButtonTitles,
} from "utils/Constants";
import { paramCreater } from "utils/methods/methods";

// 3rd party lib
import { Feature } from "flagged";
import PropTypes from "prop-types";
import pxToRem from "assets/theme/functions/pxToRem";

function TrainingMatrix({ handleExportZipFile, exportLoading }) {
  const [loadingTrainingMatrixStatus, setLoadingTrainingMatrixStatus] = useState(Constants.PENDING);
  const [traingMatrixData, setTraingMatrixData] = useState([]);
  const [certificateTypeList, setCertificateTypeList] = useState([]);
  const [filters, setFilters] = useState([
    {
      inputLabel: "Project",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }],
      selectedValue: "all",
    },
    {
      inputLabel: "Certificate Status",
      list: [
        { [Constants.MONGOOSE_ID]: "all", title: "All" },
        { [Constants.MONGOOSE_ID]: "certificate_expired", title: "Certificate Expired" },
        {
          [Constants.MONGOOSE_ID]: "certificate_expire_in_30",
          title: "Certificate Expiring in 30 days",
        },
        {
          [Constants.MONGOOSE_ID]: "certificate_expire_in_60",
          title: "Certificate Expiring in 60 days",
        },
      ],
      selectedValue: "all",
    },
    {
      inputLabel: "Name",
      list: [],
      selectedValue: "",
    },
    {
      inputLabel: "Function",
      list: [],
      selectedValue: [],
    },
  ]);
  const dispatch = useDispatch();
  const matrixStoredFilters = useSelector((state) => state.filtersSlice.trainingMatrix);
  const exportFilters = {
    project: filters[0].selectedValue,
    expire: filters[1].selectedValue,
    ...(filters[0].selectedValue !== "all" && {
      user: filters[2].selectedValue === "" ? "all" : filters[2].selectedValue,
      functions:
        filters[3].selectedValue &&
        (filters[3].selectedValue.length === filters[3].list.length ||
          filters[3].selectedValue.length === 0)
          ? "all"
          : filters[3].selectedValue.join(","),
    }),
  };
  const fetchTrainingMatrixData = async (tempFilters = filters) => {
    const paramData = {
      project: tempFilters[0].selectedValue,
      expire: tempFilters[1].selectedValue,
      ...(tempFilters[0].selectedValue !== "all" && {
        user: tempFilters[2].selectedValue === "" ? "all" : tempFilters[2].selectedValue,
        functions:
          tempFilters[3].selectedValue &&
          (tempFilters[3].selectedValue.length === tempFilters[3].list.length ||
            tempFilters[3].selectedValue.length === 0)
            ? "all"
            : tempFilters[3].selectedValue.join(","),
      }),
    };
    const TrainingMatrixRes = await dispatch(getTrainingMatrixData(paramCreater(paramData)));
    setTraingMatrixData(TrainingMatrixRes?.payload?.data?.data || []);
    setLoadingTrainingMatrixStatus(Constants.FULFILLED);
  };

  const getNameFunctions = async (id) => {
    if (id !== "all") {
      const usersSet = new Set();
      const functionsSet = new Set();

      const memberFunctionRes = await dispatch(
        memberThunk({
          id,
        })
      );

      memberFunctionRes.payload?.data.forEach((item) => {
        const userTitle = `${item?.user?.firstName} ${item?.user?.lastName}`;
        const user = {
          [Constants.MONGOOSE_ID]: item?.user?.[Constants.MONGOOSE_ID],
          title: userTitle,
        };
        usersSet.add(JSON.stringify(user));

        if (item?.function?.functionName) {
          const func = {
            [Constants.MONGOOSE_ID]: item?.function?.[Constants.MONGOOSE_ID],
            title: item.function.functionName,
          };
          functionsSet.add(JSON.stringify(func));
        }
      });

      const uniqueUsers = Array.from(usersSet).map((user) => JSON.parse(user));
      const uniqueFunctions = Array.from(functionsSet).map((func) => JSON.parse(func));

      return {
        userList: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }, ...uniqueUsers],
        functionList: uniqueFunctions,
        userValue: "all",
        functionValue: uniqueFunctions.map((func) => func[Constants.MONGOOSE_ID]),
      };
    }
    return { userList: [], functionList: [], userValue: "", functionValue: [] };
  };

  useEffect(() => {
    (async () => {
      const paramData = {
        sort: "asc",
      };
      const [projectRes, certificateTypeRes] = await Promise.all([
        dispatch(projectListThunk()),
        dispatch(getAllCertificateType(paramCreater(paramData))),
      ]);
      const temp = [...filters];
      temp[0].list = [{ [Constants.MONGOOSE_ID]: "all", title: "All" }, ...projectRes.payload.data];

      if (matrixStoredFilters?.length > 0) {
        const { userList, functionList } = await getNameFunctions(
          matrixStoredFilters[0]?.selectedValue
        );
        setFilters((prevFilters) => {
          const updatedFilters = [...prevFilters];
          updatedFilters[0] = {
            ...updatedFilters[0],
            selectedValue: matrixStoredFilters[0]?.selectedValue,
            list: temp[0].list,
          };
          updatedFilters[1] = {
            ...updatedFilters[1],
            selectedValue: matrixStoredFilters[1]?.selectedValue,
          };
          updatedFilters[2] = {
            ...updatedFilters[2],
            selectedValue: matrixStoredFilters[2]?.selectedValue,
            list: userList,
          };
          updatedFilters[3] = {
            ...updatedFilters[3],
            selectedValue: matrixStoredFilters[3]?.selectedValue,
            list: functionList,
          };

          return updatedFilters;
        });
      } else {
        setFilters(temp);
      }
      setCertificateTypeList(certificateTypeRes?.payload?.data?.data || []);
      if (matrixStoredFilters?.length > 0) {
        fetchTrainingMatrixData(matrixStoredFilters);
      } else {
        await fetchTrainingMatrixData();
      }
    })();
  }, []);

  const { columns, rows } = TrainingMatrixData(
    traingMatrixData,
    certificateTypeList,
    filters[0].selectedValue
  );

  const handleReload = async () => {
    setLoadingTrainingMatrixStatus(Constants.PENDING);
    await fetchTrainingMatrixData();
  };

  const handleFilterType = async (e) => {
    setLoadingTrainingMatrixStatus(Constants.PENDING);
    const { name, value } = e.target;
    const temp = filters.map((filter) => ({ ...filter }));
    const index = temp.findIndex((val) => val.inputLabel === name);
    temp[index] = {
      ...temp[index],
      selectedValue: value,
    };

    if (name === "Project") {
      const { userList, functionList, userValue, functionValue } = await getNameFunctions(value);

      temp[2] = {
        ...temp[2],
        list: userList,
        selectedValue: userValue,
      };

      temp[3] = {
        ...temp[3],
        list: functionList,
        selectedValue: functionValue,
      };
    }
    setFilters(temp);
    dispatch(setStoreFilters({ module: "trainingMatrix", filters: temp }));
    fetchTrainingMatrixData(temp);
  };

  const handleResetFilter = () => {
    setLoadingTrainingMatrixStatus(Constants.PENDING);
    const tempFilters = filters.map((filter, index) => {
      switch (index) {
        case 0:
          return { ...filter, selectedValue: "all" };
        case 1:
          return { ...filter, selectedValue: "all" };
        case 2:
          return { ...filter, selectedValue: "" };
        case 3:
          return { ...filter, selectedValue: [] };
        default:
          return { ...filter };
      }
    });
    setFilters(tempFilters);
    dispatch(resetFilters({ module: "trainingMatrix" }));
    fetchTrainingMatrixData(tempFilters);
  };

  const groupedData = traingMatrixData?.trainingMatrix?.reduce((acc, item) => {
    const key = Object.keys(item)[0];
    const value = Object.values(item)[0];
    const updatedAcc = { ...acc };

    if (!updatedAcc[key]) {
      updatedAcc[key] = [];
    }
    updatedAcc[key].push(value);
    return updatedAcc;
  }, {});

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        display="flex"
        flexDirection={{ md: "row", sm: "column" }}
        justifyContent={{ md: "space-between" }}
        alignItems={{ lg: "space-between", sm: "center" }}
      >
        <PageTitle title={PageTitles.TRAINING_MATRIX} />
        <BasicButton
          icon={Icons.RELOAD}
          background={Colors.WHITE}
          border
          color={Colors.BLACK}
          action={handleReload}
        />
      </MDBox>
      <Divider sx={{ marginTop: 2 }} />
      <Feature name={FeatureTags.TRAININGMATRIX}>
        <MDBox
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "start",
            alignItems: "flex-end",
            rowGap: pxToRem(16),
          }}
          mx={0}
        >
          {filters?.map((val, index) => {
            if (index < 2) {
              return (
                <FilterDropdown
                  label={val.inputLabel}
                  name={val.inputLabel}
                  value={val.selectedValue}
                  handleChange={handleFilterType}
                  menu={val.list}
                  key={val.inputLabel}
                  maxContent={defaultData.MEDIUM_CONTENT_LENGTH}
                />
              );
            }
            return null;
          })}
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
              minWidth: pxToRem(160),
              gap: pxToRem(8),
              maxHeight: pxToRem(400),
              marginRight: pxToRem(16),
            }}
          >
            <CustomAutoComeplete
              label={filters[2].inputLabel}
              id={filters[2].inputLabel}
              name={filters[2].inputLabel}
              hint="Enter Name"
              handleChange={(e) => handleFilterType(e)}
              menu={filters[2].list}
              getOptionLabel={(option) => option.title || ""}
              value={
                filters[2].list.find(
                  (val) => val[Constants.MONGOOSE_ID] === filters[2].selectedValue
                ) || null
              }
              labelStyle={{ fontWeight: 600 }}
              valueStyle={{
                backgroundColor: Colors.WHITE,
                height: pxToRem(40),
                color: "#344767",
                textTransform: "capitalize",
                padding: "0px 4px",
              }}
              disabled={filters[0].selectedValue === "all"}
            />
          </MDBox>

          <MDBox
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
              minWidth: pxToRem(160),
              gap: pxToRem(8),
              marginRight: pxToRem(16),
              maxWidth: pxToRem(310),
            }}
          >
            <MultiSelectDropdown
              label={filters[3].inputLabel}
              name={filters[3].inputLabel}
              id={filters[3].inputLabel}
              menu={filters[3].list}
              values={filters[3].selectedValue}
              handleChange={(name, value, id) => handleFilterType({ target: { name, value, id } })}
              labelStyle={{ fontWeight: 600 }}
              valueStyle={{
                backgroundColor: Colors.WHITE,
                height: pxToRem(40),
                verticalMarginTop: pxToRem(4),
                menuWidth: 310,
                inputWidth: 180,
              }}
              showBadge
              hint="Select Function"
              disabled={filters[0].selectedValue === "all"}
            />
          </MDBox>

          <MDBox sx={{ marginRight: pxToRem(8) }}>
            <ResetFilterButton handleReset={handleResetFilter} />
          </MDBox>
          <BasicButton
            title={exportLoading ? ButtonTitles.EXPORTING : ButtonTitles.EXPORT}
            icon={Icons.EXPORT}
            background={Colors.WHITE}
            color={Colors.BLACK}
            border
            action={() => handleExportZipFile(exportFilters, exportTrainingMatrixCertificate)}
            style={{ btnMarginLeft: pxToRem(0) }}
            disabled={exportLoading}
          />
        </MDBox>
        <MDBox mt={3} mb={3}>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
            showTotalEntries={false}
            noEndBorder
            pagination={{ variant: "gradient", color: "info" }}
            loading={loadingTrainingMatrixStatus}
            groupedData={groupedData}
            keyField="function"
            keyFieldHeader="Function"
          />
        </MDBox>
      </Feature>
    </DashboardLayout>
  );
}

TrainingMatrix.propTypes = {
  handleExportZipFile: PropTypes.func.isRequired,
  exportLoading: PropTypes.bool.isRequired,
};

export default ExportHOC(TrainingMatrix);
