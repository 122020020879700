import { createAsyncThunk } from "@reduxjs/toolkit";
import Sessions from "utils/Sessions";
import ApiService, { fetchAPIService } from "redux/ApiService/ApiService";

const filterThunk = createAsyncThunk("filter-safetycards/api", async (param) => {
  const res = await ApiService.get(`safety-cards?${param}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  });
  return res.data;
});

export const exportSafetyCardThunk = createAsyncThunk("export/api", async (data) => {
  const res = await fetchAPIService(`/safety-cards/export?${data}`, {
    method: "GET",
  }).then((response) => response.blob());
  return res;
});

export const exportIndividualSafetyCardThunk = createAsyncThunk(
  "export-individual-safety-card/api",
  async (id) => {
    const res = await fetchAPIService(`/safety-cards/${id}/export-pdf`, {
      method: "GET",
    }).then((response) => response.blob());
    return res;
  }
);

export const exportShiftPdf = createAsyncThunk("export-shift/api", async (id) => {
  const res = await fetchAPIService(`/shifts/${id}/export-pdf`, {
    method: "GET",
  }).then((response) => response.blob());
  return res;
});

export const exportAllShiftPdf = createAsyncThunk("export-all-shift/api", async (queryParams) => {
  const res = await fetchAPIService(`/shifts/export-excel?${queryParams}`, {
    method: "GET",
  }).then((response) => response.blob());
  return res;
});

export const exportToolBoxTalkPdf = createAsyncThunk("export-toolbox-talk/api", async (id) => {
  const res = await fetchAPIService(`/toolbox-talk/${id}/export-pdf`, {
    method: "GET",
  }).then((response) => response.blob());
  return res;
});

export const exportReportPdf = createAsyncThunk("export-report/api", async (body) => {
  const res = await fetchAPIService(`/report/users/export-report-pdf`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => response.blob());

  return res;
});

export const exportOrderPdf = createAsyncThunk("export-order-pdf/api", async (orderId) => {
  const res = await fetchAPIService(`/wm-order/order/${orderId}/export-pdf`, {
    method: "GET",
  }).then((response) => response.blob());
  return res;
});

export const exportLocationProgressPdf = createAsyncThunk(
  "export-location-progress-pdf/api",
  async (locationId) => {
    const res = await fetchAPIService(`/locations/progress-pdf/${locationId}`, {
      method: "GET",
    }).then((response) => response.blob());
    return res;
  }
);

export const projectListThunk = createAsyncThunk("project/api", async () => {
  const res = await ApiService.get(`projects`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  });
  return res.data;
});

export const locationListThunk = createAsyncThunk("location/api", async () => {
  const res = await ApiService.get(`/locations`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  });
  return res.data;
});

export const severityListThunk = createAsyncThunk("severity/api", async () => {
  const res = await ApiService.get(`severities`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  });
  return res.data;
});

export const likelihoodThunk = createAsyncThunk("likelihood/api", async () => {
  const res = await ApiService.get(`likelihoods`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  });
  return res.data;
});

export const exportThunk = createAsyncThunk("export/api", async () => {
  const res = await ApiService.get(`/safety-card/export`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  });
  return res;
});

export const dalShitFiltersThunk = createAsyncThunk("dal-shift-filter/api", async (param) => {
  const res = await ApiService.get(`shifts?${param}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  const params = new URLSearchParams(param);
  const page = params.get("page");
  return page === "0"
    ? { data: res.data, type: "add", status: res.status }
    : { data: res.data, type: "append", status: res.status };
});

export default filterThunk;
