import { useState } from "react";
import { useDispatch } from "react-redux";
import Constants from "utils/Constants";
import { paramCreater } from "utils/methods/methods";

const useSortHandler = (
  thunkAction,
  setTablePagination,
  setNext,
  loadingStatus,
  setLaodingStatus,
  filters,
  tablePagination
) => {
  const [sortState, setSortState] = useState(null);
  const dispatch = useDispatch();

  const handleHeaderClick = async () => {
    setTablePagination({ ...tablePagination, page: 0 });
    setNext({ group: 0, equiptype: 0, hscode: 0 });

    let newSortState;

    if (!sortState) {
      newSortState = "asc";
    } else if (sortState === "asc") {
      newSortState = "desc";
    } else {
      newSortState = null;
    }

    setSortState(newSortState);

    const searchTerm = filters[0].selectedValue === "All" ? "" : filters[0].selectedValue.trim();
    const data = {
      page: 0,
      perPage: tablePagination.perPage,
      sortOrder: newSortState || "",
      name: searchTerm,
    };

    setLaodingStatus({
      ...loadingStatus,
      group: Constants.PENDING,
      equiptype: Constants.PENDING,
      hscode: Constants.PENDING,
    });
    await dispatch(thunkAction(paramCreater(data)));
    setLaodingStatus({
      ...loadingStatus,
      group: Constants.FULFILLED,
      equiptype: Constants.FULFILLED,
      hscode: Constants.FULFILLED,
    });
  };

  return {
    sortState,
    handleHeaderClick,
  };
};

export default useSortHandler;
