import React, { useEffect, useState } from "react";

// Material components
import MDBox from "components/MDBox";
import { Badge, IconButton } from "@mui/material";

// Components
import Author from "components/Table/Author";
import pxToRem from "assets/theme/functions/pxToRem";
import Status from "components/Table/Status";

// Utils
import { Icons, Colors } from "utils/Constants";

export default function ApproverOrderEquipmentTypeData(
  approverEquipmentTypeList,
  approverOrderList,
  openWarehouseCommentModal,
  openRejectCommentModal
) {
  const [approverEquipmentTyperows, setApproverEquipmentTypeRows] = useState([]);

  useEffect(() => {
    if (approverEquipmentTypeList) {
      const tempRows = approverEquipmentTypeList?.orderEquipmentTypeData?.map((element, index) => {
        const temp = {
          collapseBtn: (
            <Author
              name={{
                approverEquipmentTypeId: element?.equipmentTypeId,
                approverEquipmentdata: element?.linkedEquipment,
              }}
            />
          ),
          srNo: <Author name={index + 1} />,
          equipmentName: (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <img
                src={
                  element?.equipmentTypeImage !== null
                    ? element?.equipmentTypeImage?.url
                    : process.env.REACT_APP_IMAGE_NOT_FOUND
                }
                alt={element?.image}
                key={element?.image?.[0]?.name}
                style={{
                  width: pxToRem(50),
                  height: pxToRem(50),
                  marginRight: pxToRem(10),
                  borderRadius: "8px",
                }}
              />
              <Author name={element?.typeName} />
            </MDBox>
          ),
          requestedQuantity: <Author name={element?.pmRequestedQuantity} />,
          warehouseApprovedQuantity: <Author name={element?.wmApprovedQuantity} />,
          equipmentStatus: (
            <MDBox display="flex" flexDirection="row" justifyContent="space-between">
              {element?.status === "rejected" &&
                element?.remark?.length > 0 &&
                element?.remark[0]?.comments !== null &&
                element?.remark[0]?.comments !== "" && (
                  <MDBox
                    bgColor={Colors.LIGHT_RED}
                    mb={1}
                    borderRadius={pxToRem(16)}
                    sx={{ width: pxToRem(30), height: pxToRem(30) }}
                    display="flex"
                    justifyContent="center"
                    align="center"
                  >
                    <IconButton
                      aria-label="fingerprint"
                      onClick={() => openRejectCommentModal("user", element?.remark[0])}
                    >
                      {Icons.COMMENT_RED}
                    </IconButton>
                  </MDBox>
                )}
              <MDBox sx={{ marginLeft: pxToRem(10) }}>
                <Status title={`${element?.status.replace("-", " ")}`} />
              </MDBox>
            </MDBox>
          ),
          checkedinQuantity: <Author name={element?.pmReceivedQuantity} />,
          checkedOutQuantity: <Author name={element?.pmDispatchQuantity} />,
          action: (
            <IconButton
              aria-label="fingerprint"
              color="info"
              disabled={
                element?.comments?.pmComments?.length === 0 &&
                element?.comments?.wmComments?.length === 0
              }
              onClick={() => openWarehouseCommentModal("user", element?.comments)}
            >
              <Badge
                badgeContent={
                  (element.comments.wmComments?.length || 0) +
                  (element.comments.pmComments?.length || 0)
                }
                color="error"
                max={9}
                sx={{ "& .MuiBadge-badge": { fontSize: 10, height: 15, minWidth: 15 } }}
              >
                {element?.comments?.pmComments?.length === 0 &&
                element?.comments?.wmComments?.length === 0
                  ? Icons.COMMENT_DISABLE
                  : Icons.COMMENT}
              </Badge>
            </IconButton>
          ),
        };
        return temp;
      });
      setApproverEquipmentTypeRows(tempRows);
    }
  }, [approverEquipmentTypeList, approverOrderList]);

  const approverEquipmentTypeColumns = [
    { Header: "", accessor: "collapseBtn", width: "5%" },
    { Header: "No.", accessor: "srNo", width: "5%" },
    { Header: "Equipment Type Name", accessor: "equipmentName" },
    { Header: "Approver Requested Quantity", accessor: "requestedQuantity", align: "center" },
    {
      Header: "Warehouse Approved Quantity",
      accessor: "warehouseApprovedQuantity",
      align: "center",
    },
    { Header: "Status", accessor: "equipmentStatus", align: "center" },
  ];
  if (
    ["check-in", "check-out"].includes(
      approverEquipmentTypeList?.orderEquipmentTypeData?.[0].status
    )
  ) {
    approverEquipmentTypeColumns.push({
      Header: "Checked In Quantity",
      accessor: "checkedinQuantity",
      width: "10%",
      align: "center",
    });
  }
  if (["check-out"].includes(approverEquipmentTypeList?.orderEquipmentTypeData?.[0].status)) {
    approverEquipmentTypeColumns.push({
      Header: "Checked Out Quantity",
      accessor: "checkedOutQuantity",
      width: "10%",
      align: "center",
    });
  }
  approverEquipmentTypeColumns.push({
    Header: "Comments",
    accessor: "action",
    align: "center",
    width: "5%",
  });

  const approverEquipmentTypeData = {
    approverEquipmentTypeColumns,
    approverEquipmentTyperows,
  };

  return approverEquipmentTypeData;
}
