/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from "react";

// Material Components
import MDBox from "components/MDBox";
import { IconButton } from "@mui/material";
import Author from "components/Table/Author";
import FDropdown from "components/Dropdown/FDropdown";

// 3rd party library
import moment from "moment";

// Utils
import Constants, { defaultData, Icons } from "utils/Constants";

// Redux
import { updateReportStatus } from "redux/Thunks/Report";
import { useDispatch } from "react-redux";
import { openSnackbar } from "redux/Slice/Notification";
import { updateReportUserStatus } from "redux/Slice/Report";

export default function UserReportStatusData(
  reportUserStatus,
  handleSignatureView,
  permission,
  handleUserReportDelete
) {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  const handleStatusUpdate = async (event, userReportId) => {
    const { value } = event.target;
    const body = {
      userReports: [userReportId],
      status: value,
    };
    const res = await dispatch(updateReportStatus(body));
    if (res?.payload.status === 200) {
      await dispatch(
        updateReportUserStatus({ status: value, type: "sub", reportId: userReportId })
      );
      dispatch(
        openSnackbar({
          message: Constants.REPORT_STATUS_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: Constants.REPORT_STATUS_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  useEffect(() => {
    if (reportUserStatus?.length > 0) {
      const userCount = {};
      const list = reportUserStatus?.map((item, index) => {
        const userName = `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`;
        userCount[userName] = (userCount[userName] || 0) + 1;
        const displayName =
          userCount[userName] > 1 ? `${userName} (${userCount[userName]})` : userName;

        const temp = {
          srNo: <Author name={index + 1} />,
          userName: <Author name={displayName} />,
          createdAt: <Author name={moment(item?.createdAt).format(defaultData.WEB_DATE_FORMAT)} />,
          status: (
            <MDBox>
              <FDropdown
                variant="standard"
                height={23}
                id="status"
                name="status"
                value={item?.status}
                disabled={!permission?.update}
                menu={["open", "closed", "submitted"]}
                handleChange={(name, value, id) =>
                  handleStatusUpdate({ target: { name, value, id } }, item?.userReportId)
                }
                width="150px"
              />
            </MDBox>
          ),
          sign: item?.signature && item?.signature !== null && (
            <MDBox
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Author
                name={`${item?.signatureBy?.firstName || ""} ${item?.signatureBy?.lastName || ""}`}
              />
              <IconButton
                aria-label="view report detail"
                color="info"
                onClick={() => handleSignatureView(item?.signature)}
              >
                {Icons.VIEW}
              </IconButton>
            </MDBox>
          ),
          action: (
            <IconButton
              aria-label="view report detail"
              color="info"
              onClick={() => handleUserReportDelete(item?.userReportId)}
            >
              {Icons.DELETE}
            </IconButton>
          ),
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [reportUserStatus, permission]);

  return {
    columns: [
      { Header: "User", accessor: "userName", align: "left", width: "40%" },
      { Header: "Created At", accessor: "createdAt", align: "left", width: "10%" },
      { Header: "Status", accessor: "status", align: "left", width: "10%" },
      { Header: "Signature by", accessor: "sign", align: "center", width: "30%" },
      { Header: "Action", accessor: "action", align: "center", width: "10%" },
    ],

    rows,
  };
}
