// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// MUI Components
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

// Custom Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// 3rd party library
import PropTypes from "prop-types";
import FontComponent from "components/Responsive/fonts";

export default function CustomRadio({
  label,
  name,
  list,
  value,
  handleChange,
  defaultValue,
  style = {},
}) {
  const fontSize = FontComponent({ sizes: {} });
  return (
    <MDBox sx={{ ...style }}>
      <MDTypography variant="body" mb={1} sx={{ fontSize, fontWeight: 500, color: "#344054" }}>
        {label}
      </MDTypography>
      <RadioGroup
        row
        aria-labelledby={name}
        name={name}
        value={value}
        onChange={handleChange}
        defaultValue={defaultValue}
      >
        {list.map((item) => (
          <FormControlLabel
            key={item?.label.replace(/\s/g, "")}
            defaultValue={item?.value}
            value={item?.value}
            control={<Radio />}
            label={item?.label}
            sx={{
              "& .MuiFormControlLabel-label": {
                fontWeight: 400,
              },
            }}
          />
        ))}
      </RadioGroup>
    </MDBox>
  );
}

CustomRadio.defaultProps = {
  label: "",
  name: "",
  list: [],
  value: "",
  handleChange: () => {},
  defaultValue: "",
  style: { mt: pxToRem(8) },
};

CustomRadio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.string,
  handleChange: PropTypes.func,
  defaultValue: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
};
