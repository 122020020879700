import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import Constants, { Icons } from "utils/Constants";
import { Icon, IconButton } from "@mui/material";
import Author from "components/Table/Author";

export default function EquipmentTypedata(
  typeList,
  handleOpenNewModal,
  setModalType,
  editLists,
  setEditLists,
  handleDelete,
  handleHeaderClick,
  sorted
) {
  const [rows, setRows] = useState([]);
  const handleEdit = (item) => {
    setModalType("Update");
    setEditLists({ ...editLists, equiptype: item });
    handleOpenNewModal("Equipment Type");
  };

  useEffect(() => {
    if (typeList) {
      const list = typeList?.map((item) => {
        const temp = {
          name: <Author name={item?.type} />,
          category: <Author name={item?.equipmentCategory?.name} />,
          form: <Author name={item?.equipmentUnit?.title} />,
          rent: (
            <Author
              name={`${item?.currencyUnit?.symbol} ${
                item?.price
              } (${item?.quantityType?.priceType?.charAt(0)})`}
            />
          ),
          quantity: <Author name={item?.quantityType?.name} />,
          code: <Author name={`${item?.hsCode?.name} (${item?.hsCode?.code})`} />,
          action: (
            <MDBox>
              <IconButton
                color="secondary"
                fontSize="medium"
                onClick={() => handleEdit(item)}
                sx={{ cursor: "pointer" }}
              >
                {Icons.EDIT}
              </IconButton>{" "}
              &nbsp;
              <IconButton
                color="secondary"
                fontSize="medium"
                sx={{ cursor: "pointer" }}
                onClick={() => handleDelete("equiptype", item[Constants.MONGOOSE_ID])}
              >
                {Icons.DELETE}
              </IconButton>
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [typeList]);

  return {
    typeColumns: [
      {
        Header: () => (
          <div
            onClick={handleHeaderClick}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => e.key === "Enter" && handleHeaderClick()}
            style={{ cursor: "pointer" }}
          >
            Name
            <MDBox
              position="absolute"
              top={-3}
              left="55%"
              color={sorted === "asc" ? "text" : "secondary"}
              opacity={sorted === "asc" ? 1 : 0.5}
            >
              <Icon fontSize="medium">arrow_drop_up</Icon>
            </MDBox>
            <MDBox
              position="absolute"
              top={3}
              left="55%"
              color={sorted === "desc" ? "text" : "secondary"}
              opacity={sorted === "desc" ? 1 : 0.5}
            >
              <Icon fontSize="medium">arrow_drop_down</Icon>
            </MDBox>
          </div>
        ),
        accessor: "name",
        align: "left",
      },
      { Header: "Equipment Category", accessor: "category", align: "left" },
      { Header: "Weight Form", accessor: "form", align: "left" },
      { Header: "Rental Price/Buy Price", accessor: "rent", align: "left" },
      { Header: "Quantity Type", accessor: "quantity", align: "left" },
      { Header: "HS Code", accessor: "code", align: "left" },
      { Header: "Action", accessor: "action", align: "center", width: "10%" },
    ],
    typeRows: rows,
  };
}
