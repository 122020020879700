import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import Constants, { Icons } from "utils/Constants";
import { Icon, IconButton } from "@mui/material";
import Author from "components/Table/Author";

export default function EquipmentGroupdata(
  groupList,
  handleOpenNewModal,
  setModalType,
  editLists,
  setEditLists,
  handleDelete,
  handleSortCategory,
  sorted
) {
  const [rows, setRows] = useState([]);
  const handleEdit = (item) => {
    setModalType("Update");
    setEditLists({ ...editLists, group: item });
    handleOpenNewModal("Equipment Category");
  };
  useEffect(() => {
    if (groupList) {
      const list = groupList?.map((item) => {
        const temp = {
          name: <Author name={item?.name} />,
          abbrevation: <Author name={item?.abbreviation} />,
          action: (
            <MDBox>
              <IconButton
                color="secondary"
                fontSize="medium"
                onClick={() => handleEdit(item)}
                sx={{ cursor: "pointer" }}
              >
                {Icons.EDIT}
              </IconButton>{" "}
              &nbsp;
              <IconButton
                color="secondary"
                fontSize="medium"
                sx={{ cursor: "pointer" }}
                onClick={() => handleDelete("group", item[Constants.MONGOOSE_ID])}
              >
                {Icons.DELETE}
              </IconButton>
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [groupList]);

  return {
    groupColumns: [
      {
        Header: () => (
          <div
            onClick={handleSortCategory}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => e.key === "Enter" && handleSortCategory()}
            style={{ cursor: "pointer" }}
          >
            Name
            <MDBox
              position="absolute"
              top={-3}
              left="15%"
              color={sorted === "asc" ? "text" : "secondary"}
              opacity={sorted === "asc" ? 1 : 0.5}
            >
              <Icon fontSize="medium">arrow_drop_up</Icon>
            </MDBox>
            <MDBox
              position="absolute"
              top={3}
              left="15%"
              color={sorted === "desc" ? "text" : "secondary"}
              opacity={sorted === "desc" ? 1 : 0.5}
            >
              <Icon fontSize="medium">arrow_drop_down</Icon>
            </MDBox>
          </div>
        ),
        width: "65%",
        accessor: "name",
        align: "left",
      },
      {
        Header: "Equipment Nr. Abbreviation",
        accessor: "abbrevation",
        width: "25%",
        align: "left",
      },
      { Header: "Action", accessor: "action", width: "10%", align: "center" },
    ],
    groupRows: rows,
  };
}
