import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { Icons } from "utils/Constants";
import { Icon, IconButton } from "@mui/material";

export default function Locationdata(
  locationList,
  handleOpenNewModal,
  setModalType,
  editLists,
  setEditLists,
  handleDelete,
  handleLocationSort,
  sorted
) {
  const [rows, setRows] = useState([]);
  const mongooseId = "_id";
  const handleEdit = (item) => {
    setModalType("Update");
    setEditLists({ ...editLists, location: item });
    handleOpenNewModal("Location");
  };

  useEffect(() => {
    if (locationList) {
      const list = locationList.map((item) => {
        const temp = {
          name: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {item?.title}
            </MDTypography>
          ),
          action: (
            <MDBox>
              <IconButton
                color="secondary"
                fontSize="medium"
                onClick={() => handleEdit(item)}
                sx={{ cursor: "pointer" }}
                disabled={!item?.isDeletable || false}
              >
                {Icons.EDIT}
              </IconButton>{" "}
              &nbsp;
              <IconButton
                color="secondary"
                fontSize="medium"
                sx={{ cursor: "pointer" }}
                onClick={() => handleDelete("Location", item[mongooseId])}
                disabled={!item?.isDeletable || false}
              >
                {Icons.DELETE}
              </IconButton>
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [locationList]);

  return {
    locationColumns: [
      {
        Header: () => (
          <div
            onClick={handleLocationSort}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => e.key === "Enter" && handleLocationSort()}
            style={{ cursor: "pointer" }}
          >
            Name
            <MDBox
              position="absolute"
              top={-3}
              left="10%"
              color={sorted === "asc" ? "text" : "secondary"}
              opacity={sorted === "asc" ? 1 : 0.5}
            >
              <Icon fontSize="medium">arrow_drop_up</Icon>
            </MDBox>
            <MDBox
              position="absolute"
              top={3}
              left="10%"
              color={sorted === "desc" ? "text" : "secondary"}
              opacity={sorted === "desc" ? 1 : 0.5}
            >
              <Icon fontSize="medium">arrow_drop_down</Icon>
            </MDBox>
          </div>
        ),
        accessor: "name",
        width: "90%",
        align: "left",
      },
      { Header: "Action", accessor: "action", width: "10%", align: "right" },
    ],
    locationRows: rows,
  };
}
