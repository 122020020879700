import React, { useEffect, useState } from "react";

// Custom Components
import Author from "components/Table/Author";

// Constants
import Constants, { Colors, FormFields, defaultData, Icons } from "utils/Constants";

// Functions
import { generateTrainingMatrixCertificateList } from "utils/methods/methods";

// 3rd party library
import moment from "moment";

export default function TraningMatrixDataTable(TrainingMatrixData, certificateTypeList, filter) {
  const [rows, setRows] = useState([]);

  function checkCertificateExpiry(expiryDate, forColor) {
    const expiryDateFormatted = moment(expiryDate);
    const currentDate = moment();
    const daysRemaining = expiryDateFormatted.diff(currentDate, "days");
    let color = "";

    switch (true) {
      case daysRemaining <= 0:
        color = forColor === "background" ? Colors.LIGHT_RED : Colors.DARK_RED;
        break;
      case daysRemaining <= 30:
        color = forColor === "background" ? Colors.LIGHT_ORANGE : Colors.DARK_ORANGE;
        break;
      case daysRemaining <= 60:
        color = forColor === "background" ? Colors.LIGHT_YELLOW : Colors.DARK_YELLOW;
        break;
      default:
        break;
    }

    return color;
  }

  const dynamicColumns = certificateTypeList
    ?.map((element) => ({
      Header: element?.name ? element.name.charAt(0).toUpperCase() + element.name.slice(1) : null,
      accessor: element?.name.toLowerCase().replace(/\s/g, "_"),
      id: element[Constants.MONGOOSE_ID],
    }))
    .flat();

  const requiredColumnIdList = TrainingMatrixData?.requiredCertificate?.map(
    (cid) => cid?.certificate[Constants.MONGOOSE_ID]
  );
  const filteredColumns =
    filter === "all"
      ? dynamicColumns
      : dynamicColumns.filter((element) => requiredColumnIdList?.includes(element?.id));

  useEffect(() => {
    if (TrainingMatrixData) {
      const certificateList = generateTrainingMatrixCertificateList(
        TrainingMatrixData?.trainingMatrix
      );

      const tempRows = certificateList?.map((element) => {
        const dynamicRows = {};

        const functionId = element.function.id;

        const requiredCertsForFunction = TrainingMatrixData?.requiredCertificate
          ?.filter((rc) => rc.function[Constants.MONGOOSE_ID] === functionId)
          .map((rc) => rc.certificate[Constants.MONGOOSE_ID]);

        filteredColumns?.forEach((item) => {
          const data = element?.certificate?.certificates.find(
            (i) => i?.certificateType === item?.id
          )?.endDate;

          const requiredColumnId = TrainingMatrixData?.requiredCertificate?.map(
            (cid) => cid?.certificate[Constants.MONGOOSE_ID]
          );
          const isRequired = requiredCertsForFunction.includes(item?.id);

          if (data !== undefined && data !== null && requiredColumnId.length === 0) {
            const date = data ? moment(data).format(defaultData.WEB_DATE_FORMAT) : Constants.NA;
            dynamicRows[item?.accessor] = (
              <Author
                name={date}
                color={checkCertificateExpiry(data, "background")}
                style={{
                  color: checkCertificateExpiry(data, "color"),
                  fontWeight: "bold",
                }}
              />
            );
          } else if (
            data !== undefined &&
            data !== null &&
            requiredColumnId.includes(item?.id) &&
            isRequired
          ) {
            const date = data ? moment(data).format(defaultData.WEB_DATE_FORMAT) : "";
            dynamicRows[item?.accessor] = (
              <Author
                name={date}
                color={checkCertificateExpiry(data, "background")}
                style={{
                  color: checkCertificateExpiry(data, "color"),
                  fontWeight: "bold",
                }}
              />
            );
          } else if (!requiredColumnId.includes(item?.id) && requiredColumnId.length > 0) {
            const date = data ? moment(data).format(defaultData.WEB_DATE_FORMAT) : "";
            dynamicRows[item?.accessor] = (
              <Author
                name={date}
                color={Colors.LIGHT_GRAY4}
                style={{
                  color: Colors.BLACK,
                }}
              />
            );
          } else if (data === null) {
            dynamicRows[item?.accessor] = <Author icon={Icons.ACCEPT_MEDIUM} />;
          } else if (requiredColumnId.includes(item?.id) && data === undefined && isRequired) {
            dynamicRows[item?.accessor] = (
              <Author
                name={FormFields.NOT_APPLICABLE}
                color={Colors.LIGHT_RED}
                style={{
                  color: Colors.DARK_RED,
                }}
              />
            );
          } else if (requiredColumnId.includes(item?.id) && !isRequired) {
            const date = data ? moment(data).format(defaultData.WEB_DATE_FORMAT) : "";
            dynamicRows[item?.accessor] = (
              <Author
                name={data === undefined ? FormFields.NOT_APPLICABLE : date}
                color={Colors.LIGHT_GRAY4}
                style={{
                  color: Colors.BLACK,
                }}
              />
            );
          } else {
            dynamicRows[item?.accessor] = <Author name={FormFields.NOT_APPLICABLE} />;
          }
        });
        const temp = {
          function: <Author name={element.function.functionName} />,
          name: [
            <Author
              key={element?.certificate?.id ?? element?.function?.id}
              name={element?.certificate?.name}
            />,
          ],
          ...dynamicRows,
        };
        return temp;
      });
      setRows([...tempRows]);
    }
  }, [TrainingMatrixData]);

  const columnLength = filteredColumns.length;

  const [divWidth, setDivWidth] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const divElement = document.getElementById("table-grouped-rows");
      const { width } = divElement.getBoundingClientRect();
      setDivWidth(width);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const widthForCell = (divWidth - 396) / columnLength - 50;
  const remaining = 199 * columnLength;
  const actualWidthOfCell = divWidth - 396;
  let dynamicColumnsWithWidth;

  if (actualWidthOfCell > remaining) {
    dynamicColumnsWithWidth = filteredColumns?.map((item) => {
      const newItem = { ...item, width: `${widthForCell}px` };
      return newItem;
    });
  } else {
    dynamicColumnsWithWidth = filteredColumns;
  }

  return {
    columns: [
      {
        Header: "Function",
        accessor: "function",
        isSticky: true,
        index: 1,
        valign: "center",
        ...(columnLength === 0 && { width: divWidth / 5 }),
      },
      {
        Header: "Name",
        accessor: "name",
        isSticky: true,
        index: 2,
        ...(columnLength === 0 && { width: divWidth / 1.4 }),
      },
      ...dynamicColumnsWithWidth,
    ],
    rows,
  };
}
