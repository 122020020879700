import React, { useState } from "react";

// Material Components
import MDTypography from "components/MDTypography";
import { Box, Icon } from "@mui/material";
import MDBox from "components/MDBox";

// Common Components
import FullScreenImageComponent from "components/ViewFullImage/ViewImage";
import CustomImage from "components/Table/GroupImage";
import pxToRem from "assets/theme/functions/pxToRem";
import Author from "components/Table/Author";

// Utils
import { defaultData, Icons } from "utils/Constants";

// Libraries
import moment from "moment";

// 3rd party libraries
import PropTypes from "prop-types";

export default function DynamicTypeData({ type, data, compressedData }) {
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const handleImageFullView = (imagePreview) => {
    setFullScreenImage(imagePreview);
  };
  const handleCloseFullView = () => {
    setFullScreenImage(null);
  };
  return (
    <MDBox sx={{ paddingX: pxToRem(8) }}>
      {(() => {
        switch (type) {
          case "shortAnswer":
          case "dropdown":
          case "number":
          case "dateTime":
          case "boolean":
          case "range":
          case "checkbox":
            return (
              <MDBox>
                <MDBox
                  display="flex"
                  flexDirection="row"
                  key={data}
                  alignItems="center"
                  gap={1}
                  mt={1}
                >
                  <MDTypography
                    sx={{
                      width: "100%",
                      textTransform: "capitalize",
                      fontWeight: 500,
                      fontSize: pxToRem(16),
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                    }}
                    key={data}
                  >
                    {type === "dateTime"
                      ? moment(data).format(defaultData.WEB_24_HOURS_FORMAT)
                      : `${data.replace(/##/g, ", ")}`}
                  </MDTypography>
                </MDBox>
              </MDBox>
            );
          case "date":
            return (
              <MDBox>
                <MDBox
                  display="flex"
                  flexDirection="row"
                  key={data}
                  alignItems="center"
                  gap={1}
                  mt={1}
                >
                  <MDTypography
                    sx={{
                      width: "100%",
                      textTransform: "capitalize",
                      fontWeight: 500,
                      fontSize: pxToRem(16),
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                    }}
                    key={data}
                  >
                    {moment(data).format(defaultData.WEB_DATE_FORMAT)}
                  </MDTypography>
                </MDBox>
              </MDBox>
            );
          case "image":
          case "signature":
            return (
              <MDBox
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <MDBox
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  position="relative"
                  flexWrap="wrap"
                  sx={{
                    rowGap: pxToRem(10),
                    "&:hover .overlay": {
                      display: "flex",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <CustomImage item={compressedData || data} />
                  <Box
                    display="none"
                    position="absolute"
                    top={0}
                    right={0}
                    bottom={0}
                    left={0}
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="rgba(0, 0, 0, 0.5)"
                    className="overlay"
                    width={50}
                    height={50}
                  >
                    <Icon
                      sx={{ color: "white", width: 30, height: 30, cursor: "pointer" }}
                      onClick={() => handleImageFullView(data)}
                    >
                      {Icons.VIEW2}
                    </Icon>
                  </Box>
                </MDBox>
              </MDBox>
            );
          default:
            return <Author name={data} />;
        }
      })()}
      <FullScreenImageComponent
        fullScreenImage={fullScreenImage}
        handleCloseFullView={handleCloseFullView}
        image={data}
        src={fullScreenImage}
      />
    </MDBox>
  );
}

DynamicTypeData.propTypes = {
  type: PropTypes.string,
  data: PropTypes.string,
  compressedData: PropTypes.string,
};

DynamicTypeData.defaultProps = {
  type: "",
  data: "",
  compressedData: "",
};
