import React from "react";

// MUI components
import { Button, Icon, Tooltip } from "@mui/material";

// Custom components
import MDTypography from "components/MDTypography";
import FontComponent from "components/Responsive/fonts";

// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// Constants
import { Colors } from "utils/Constants";

// 3rd party library
import PropTypes from "prop-types";

export default function BasicButton({
  title = "",
  icon,
  background,
  color,
  action,
  disabled = false,
  border = false,
  tooltipLabel = "",
  borderColor = "var(--gray-300, #D0D5DD)",
  style = {},
  fontWeight,
}) {
  const fontSize = FontComponent({ sizes: {} });

  return (
    <Tooltip title={tooltipLabel} arrow>
      <Button
        disabled={disabled}
        disableRipple
        sx={{
          marginLeft: style.btnMarginLeft ? style.btnMarginLeft : pxToRem(16),
          backgroundColor: background,
          borderRadius: pxToRem(8),
          "&:hover": {
            backgroundColor: background,
          },
          height: pxToRem(40),
          paddingX: pxToRem(16),
          border: border ? `1px solid ${borderColor}` : "none",
        }}
        onClick={() => action()}
        style={style}
      >
        <Icon fontSize={pxToRem(14)} sx={{ color: disabled ? Colors.GREY : color }}>
          {icon}
        </Icon>
        {title && (
          <MDTypography
            fontSize={fontSize}
            fontWeight={fontWeight}
            sx={{
              color: disabled ? Colors.GREY : color,
              marginLeft: pxToRem(8.6),
              lineHeight: pxToRem(20),
            }}
          >
            {title}
          </MDTypography>
        )}
      </Button>
    </Tooltip>
  );
}

BasicButton.defaultProps = {
  title: "",
  icon: "",
  background: "",
  color: "",
  action: () => {},
  disabled: false,
  border: false,
  tooltipLabel: "",
  borderColor: "var(--gray-300, #D0D5DD)",
  style: {},
  fontWeight: "medium",
};

BasicButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  action: PropTypes.func,
  disabled: PropTypes.bool,
  border: PropTypes.bool,
  tooltipLabel: PropTypes.string,
  borderColor: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  fontWeight: PropTypes.string,
};
