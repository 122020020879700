import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import {
  Box,
  Tab,
  Tabs,
  CircularProgress,
  Icon,
  Card,
  Tooltip,
  IconButton,
  styled,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import pxToRem from "assets/theme/functions/pxToRem";

// Overview page components
import { useDispatch } from "react-redux";
import ProfileThunk from "redux/Thunks/Profile";
import Personaldetails from "examples/modal/UpdateProfile/PersonalDetails";
import FullScreenImageComponent from "components/ViewFullImage/ViewImage";
import bgImage from "assets/images/Profilebg.png";
import Pdf from "assets/images/pdf.svg";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import { useNavigate, useParams } from "react-router-dom";
import { UserListbyIdThunk } from "redux/Thunks/UserManagement";
import uploadImageThunk from "redux/Thunks/ImageUpload";
import { updateAdminProfileThunk } from "redux/Thunks/SuperAdmin";
import moment from "moment";
import Constants, { Icons, defaultData } from "utils/Constants";
import { openSnackbar } from "redux/Slice/Notification";
import RoleListThunk from "redux/Thunks/Role";
import getProfileFunctions from "redux/Thunks/ProfileFunctions";
import Status from "components/Table/Status";
import { downloadFile } from "utils/methods/methods";

function Overview() {
  const mongooseID = "_id";
  const [profileData, setProfileData] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [profileFunctionOptions, setProfileFunctionOptions] = useState([]);
  const [update, setUpdate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [certificateValue, setCertificateValue] = useState(0);
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const { id } = useParams();
  const dispatch = useDispatch();

  const CustomTabs = styled(Tabs)({
    backgroundColor: "white",
    border: "1px solid #E0E2E7",
  });

  const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    color: "#667085",
    "&.Mui-selected": {
      color: "#667085",
      fontWeight: 600,
      backgroundColor: "#DEDEFA",
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpenEdit = () => setOpenEdit(true);
  useEffect(() => {
    (async () => {
      if (id) {
        // User Profile
        const res = await dispatch(UserListbyIdThunk(id));
        if (res.payload?.status === 200) {
          setProfileData([res.payload.data.data]);
          if (
            res.payload.data.data?.role.title.toLowerCase() !== defaultData.ADMIN_ROLE &&
            res.payload.data.data?.role.title.toLowerCase() !== defaultData.SUPER_ADMIN_ROLE
          ) {
            const roleRes = await dispatch(RoleListThunk());
            const profileFunctionRes = await dispatch(getProfileFunctions());
            const filteredRoles = roleRes.payload.data.data.filter(
              (element) =>
                element?.title !== defaultData.ADMIN_ROLE &&
                element?.title !== defaultData.SUPER_ADMIN_ROLE
            );
            const filterProfileFunctions = profileFunctionRes.payload.data.data.filter(
              (item) => item.isActive === true
            );
            setRoleOptions(filteredRoles);
            setProfileFunctionOptions(filterProfileFunctions);
          }
        } else {
          navigate("/client/setting/user-management");
        }
      } else {
        // Admin Profile
        const res = await dispatch(ProfileThunk());
        setProfileData([res.payload.data]);
        setRoleOptions([]);
        setProfileFunctionOptions([]);
      }
    })();
  }, [id, update]);

  const handleProfileImageChange = async (event) => {
    const file = event.target.files[0];
    const type = "Profile_Photos";
    const res = await dispatch(uploadImageThunk({ file, type }));
    if (res.error === undefined) {
      const b = {
        body: { profileImage: res.payload.data.iconUrl },
        id: profileData[0]?.[mongooseID],
      };
      const res1 = await dispatch(updateAdminProfileThunk(b));
      if (res1.error === undefined) {
        dispatch(
          openSnackbar({ message: Constants.PROFILE_IMAGE_UPDATE, notificationType: "success" })
        );
        setUpdate(!update);
      }
    } else {
      dispatch(
        openSnackbar({ message: Constants.SOMETHING_WENT_WRONG, notificationType: "error" })
      );
    }
  };
  const formatAddress = (element, profileDatas) => {
    if (profileDatas[0]?.role.accessType === defaultData.WEB_ACCESSTYPE) {
      return element?.address || Constants.NA;
    }
    const addressParts = [
      element?.street,
      element?.area,
      element?.zipCode,
      element?.city,
      element?.state,
    ];

    const address = addressParts.filter((part) => part).join(" ");

    return address || Constants.NA;
  };
  const handleImageFullView = (imagePreview) => {
    setFullScreenImage(imagePreview);
  };
  const handleCloseFullView = () => {
    setFullScreenImage(null);
  };

  const renderCertificates = (certificates) => (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 3 }} mt={2}>
      {certificates.length > 0 ? (
        certificates.map((element) => (
          <Grid key={element?.[Constants.mongooseID]} item xs={10} xl={5}>
            <MDTypography
              display="block"
              variant="caption"
              textTransform="capitalize"
              fontWeight="medium"
              mb={1}
              mt={1}
            >
              {element?.certificateType?.name?.length > defaultData.MEDIUM_CONTENT_LENGTH ? (
                <Tooltip title={element?.certificateType?.name}>
                  <span>
                    {`${element?.certificateType?.name.slice(
                      0,
                      defaultData.MEDIUM_CONTENT_LENGTH
                    )}...`}
                  </span>
                </Tooltip>
              ) : (
                <span>{element?.certificateType?.name}</span>
              )}
            </MDTypography>
            <Box
              display="flex"
              border="1px solid #E0E6F5"
              borderRadius="8px"
              p={1}
              sx={{ cursor: "pointer" }}
              onClick={() => handleImageFullView(element?.link)}
            >
              {!element?.link.includes("pdf") ? (
                <img
                  src={element?.link || process.env.REACT_APP_IMAGE_NOT_FOUND}
                  alt="Preview"
                  height="60px"
                  width="60px"
                  style={{
                    border: "1px solid #D0D5DD",
                    borderRadius: "8px",
                    marginTop: "5px",
                    marginLeft: "4px",
                  }}
                />
              ) : (
                <img
                  src={Pdf}
                  alt="Preview"
                  height="60px"
                  width="60px"
                  style={{
                    marginTop: "5px",
                    marginLeft: "4px",
                  }}
                />
              )}
              <Box ml={2}>
                <MDTypography
                  display="block"
                  variant="caption"
                  sx={{ textTransform: "capitalize" }}
                >
                  {element?.fileName?.length > defaultData.SMALLER_CONTENT_LENGTH ? (
                    <Tooltip title={element?.fileName}>
                      <span>{`${element?.fileName.slice(
                        0,
                        defaultData.SMALLER_CONTENT_LENGTH
                      )}...`}</span>
                    </Tooltip>
                  ) : (
                    <span>{element?.fileName}</span>
                  )}
                </MDTypography>
                {element?.endDate && (
                  <MDTypography display="block" variant="caption" color="text">
                    End date: {moment(element?.endDate).format(defaultData.WEB_DATE_FORMAT)}
                  </MDTypography>
                )}
                <MDTypography display="block" variant="caption" color="text">
                  {element?.size ? `${element?.size} KB` : Constants.NA}
                </MDTypography>
                <Box mt={1}>
                  <Status
                    title={`${
                      certificateValue === 3
                        ? Constants.IN_ACTIVE
                        : element?.status.replace("_", " ")
                    }`}
                  />
                </Box>
              </Box>
              <IconButton
                sx={{ ml: "auto" }}
                onClick={(event) => downloadFile(element?.link, event, element?.fileName)}
              >
                {Icons.DOWNLOAD}
              </IconButton>
            </Box>
          </Grid>
        ))
      ) : (
        <MDTypography
          mt="25%"
          ml="30%"
          variant="h6"
          fontWeight="medium"
          color="text"
          textTransform="capitalize"
        >
          No Certificates Uploaded
        </MDTypography>
      )}
    </Grid>
  );

  const handleCertificateTab = (event, certValue) => {
    setCertificateValue(certValue);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {profileData.length > 0 ? (
        <MDBox position="relative">
          <MDBox
            display="flex"
            alignItems="center"
            position="relative"
            minHeight="14.75rem"
            ml={-4}
            mr={-3.5}
            sx={{
              backgroundImage: ({ functions: { rgba, linearGradient } }) =>
                `${linearGradient(rgba("#FFC9B0", 0.6), rgba("#FFC9B0", 0.6))}, url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              overflow: "hidden",
            }}
          />
          <Card
            sx={{
              position: "relative",
              mt: -24,
              py: 6,
              height: "100%",
            }}
          >
            <Grid
              container
              spacing={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              alignContent={{ xs: "center", md: "flex-start", lg: "flex-start" }}
            >
              <Grid item xs={12} sm={12} md={6} lg={3}>
                {profileData[0]?.profileImage ? (
                  <MDAvatar
                    src={profileData[0]?.profileImage}
                    size="xxl"
                    shadow="sm"
                    sx={{ marginLeft: "35px" }}
                  />
                ) : (
                  <MDAvatar
                    size="xxl"
                    shadow="sm"
                    sx={{ marginLeft: "35px", backgroundColor: "#191A51" }}
                  >
                    <MDTypography color="light" sx={{ fontSize: pxToRem(50), fontWeight: "700" }}>
                      {profileData[0]?.firstName.charAt(0)}
                    </MDTypography>
                  </MDAvatar>
                )}

                {profileData[0]?.role?.title.toLowerCase() === defaultData.ADMIN_ROLE ? (
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <Tooltip title="Change profile image">
                        <IconButton
                          aria-label="change-profile-image"
                          component="label"
                          htmlFor="profile-image-input"
                        >
                          <Icon
                            fontSize="medium"
                            display="flex"
                            sx={{
                              ml: 14,
                              mt: -7,
                              cursor: "pointer",
                              backgroundColor: "#f5f5f5",
                              borderRadius: "50%",
                            }}
                          >
                            {Icons.EDIT}
                          </Icon>
                          <input
                            type="file"
                            id="profile-image-input"
                            accept="image/*"
                            hidden
                            onChange={handleProfileImageChange}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                {profileData?.map((element) => (
                  <MDBox
                    key={element?.[Constants.mongooseID]}
                    height="100%"
                    mt={0.5}
                    lineHeight={1}
                  >
                    <MDBox display="flex" justifyContent="center" alignItems="center">
                      <MDTypography
                        sx={{ fontSize: pxToRem(20), fontWeight: "700", color: "#191D31" }}
                      >
                        {`${element?.firstName} ${element?.lastName}`}
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" justifyContent="center" alignItems="center">
                      <MDTypography
                        sx={{
                          fontSize: pxToRem(16),
                          fontWeight: "500",
                          color: "#191D31",
                          textTransform: "capitalize",
                        }}
                      >
                        {element?.role?.title}
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" justifyContent="center" alignItems="center">
                      <Tooltip title={element?.email}>
                        <MDTypography
                          sx={{
                            fontSize: pxToRem(15),
                            fontWeight: "600",
                            marginLeft: "25px",
                            color: "#191D31",
                            wordBreak: "break-all",
                          }}
                        >
                          {element?.email.length > defaultData.MEDIUM_CONTENT_LENGTH
                            ? `${element?.email.slice(0, defaultData.MEDIUM_CONTENT_LENGTH)}...`
                            : element?.email}
                        </MDTypography>
                      </Tooltip>
                      <Icon
                        fontSize="medium"
                        onClick={handleOpenEdit}
                        sx={{ ml: 2, cursor: "pointer" }}
                      >
                        {Icons.EDIT}
                      </Icon>
                    </MDBox>
                  </MDBox>
                ))}
              </Grid>
            </Grid>
            <MDBox mt={5} mb={3}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 3 }}
                display="flex"
                flexWrap="wrap"
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={3}
                  sx={{
                    display: "flex",
                    height:
                      profileData[0]?.role?.accessType === defaultData.MOBILE_ACCESSTYPE ||
                      profileData[0]?.role?.accessType === defaultData.BOTH_ACCESSTYPE
                        ? "380px"
                        : "120px",
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    orientation="vertical"
                    sx={{
                      backgroundColor: "#ffffff",
                      width: "100%!important",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Tab
                      defaultIndex={0}
                      label="Organization Profile"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        fontSize: pxToRem(14),
                        paddingLeft: "12px",
                        fontWeight: `${value === 0 ? "600" : null}`,
                        borderRadius: 0,
                        width: "500px!important",
                        height: "34px!important",
                        backgroundColor: `${value === 0 ? "#F6F7FF" : "#ffffff"}`,
                        borderLeft: `2px solid ${value === 0 ? "#191A51" : "#ffffff"}`,
                      }}
                    />
                    <Tab
                      label="Personal Details"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: "12px",
                        paddingLeft: "12px",
                        fontSize: pxToRem(14),
                        fontWeight: `${value === 1 ? "600" : null}`,
                        borderRadius: 0,
                        height: "34px!important",
                        backgroundColor: `${value === 1 ? "#F6F7FF" : "#ffffff"}`,
                        borderLeft: `2px solid ${value === 1 ? "#191A51" : "#ffffff"}`,
                        textTransform: "capitalize",
                      }}
                    />
                    {profileData[0]?.role.accessType === defaultData.WEB_ACCESSTYPE ? null : (
                      <Tab
                        label="Certificates"
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginTop: "12px",
                          paddingLeft: "12px",
                          fontSize: pxToRem(14),
                          height: "34px!important",
                          fontWeight: `${value === 2 ? "600" : null}`,
                          borderRadius: 0,
                          backgroundColor: `${value === 2 ? "#F6F7FF" : "#ffffff"}`,
                          borderLeft: `2px solid ${value === 2 ? "#191A51" : "#ffffff"}`,
                        }}
                      />
                    )}
                    {profileData[0]?.role.accessType === defaultData.WEB_ACCESSTYPE ? null : (
                      <Tab
                        label="Contractual Details"
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginTop: "12px",
                          paddingLeft: "12px",
                          fontSize: pxToRem(14),
                          fontWeight: `${value === 3 ? "600" : null}`,
                          borderRadius: 0,
                          height: "34px!important",
                          backgroundColor: `${value === 3 ? "#F6F7FF" : "#ffffff"}`,
                          borderLeft: `2px solid ${value === 3 ? "#191A51" : "#ffffff"}`,
                        }}
                      />
                    )}
                    {profileData[0]?.role.accessType === defaultData.WEB_ACCESSTYPE ? null : (
                      <Tab
                        label="Medical"
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginTop: "12px",
                          paddingLeft: "12px",
                          fontSize: pxToRem(14),
                          height: "34px!important",
                          fontWeight: `${value === 4 ? "600" : null}`,
                          borderRadius: 0,
                          backgroundColor: `${value === 4 ? "#F6F7FF" : "#ffffff"}`,
                          borderLeft: `2px solid ${value === 4 ? "#191A51" : "#ffffff"}`,
                        }}
                      />
                    )}
                    {profileData[0]?.role.accessType === defaultData.WEB_ACCESSTYPE ? null : (
                      <Tab
                        label="GDPR"
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginTop: "12px",
                          paddingLeft: "12px",
                          height: "34px!important",
                          fontSize: pxToRem(14),
                          fontWeight: `${value === 5 ? "600" : null}`,
                          borderRadius: 0,
                          backgroundColor: `${value === 5 ? "#F6F7FF" : "#ffffff"}`,
                          borderLeft: `2px solid ${value === 5 ? "#191A51" : "#ffffff"}`,
                        }}
                      />
                    )}
                  </Tabs>
                </Grid>
                <Grid
                  xs
                  mt={{ xs: 0, sm: 0, md: -30, lg: -30 }}
                  sx={{ borderLeft: "1px solid #E0E6F5" }}
                >
                  {value === 0 && (
                    <Box>
                      {profileData?.map((element) => (
                        <ProfileInfoCard
                          key={element?.[Constants.mongooseID]}
                          title="Organization Profile"
                          info={{
                            Organization: element?.account?.name
                              ? element?.account?.name
                              : Constants.NA,
                            Status: element.isActive ? "Active" : "Not Active",
                            Country: element?.account.organizationCountry
                              ? element?.account.organizationCountry
                              : Constants.NA,
                            address: element.account.organizationAddress
                              ? element.account.organizationAddress
                              : Constants.NA,
                          }}
                          logo={element?.account?.logo}
                          action={{ route: "", tooltip: "Edit Profile" }}
                          shadow={false}
                        />
                      ))}
                    </Box>
                  )}
                  {value === 1 && (
                    <Box>
                      {profileData?.map((element) => (
                        <ProfileInfoCard
                          key={element?.[Constants.mongooseID]}
                          title="Personal Details"
                          info={{
                            "Passport Name": element.callingName
                              ? element.callingName
                              : Constants.NA,
                            Phone: element?.contactNumber?.number
                              ? `${element?.contactNumber?.in}${element?.contactNumber?.number}`
                              : element?.contactNumber,
                            Country: element?.country ? element?.country : Constants.NA,
                            Address: formatAddress(element, profileData),
                            Languages: element?.motherLanguage
                              ? element?.motherLanguage
                              : Constants.NA,
                            ...(profileData[0]?.role.accessType !== defaultData.WEB_ACCESSTYPE && {
                              "Preferred Airport of Departure": element?.prefAirportDeprt
                                ? element?.prefAirportDeprt
                                : Constants.NA,
                              "Second Pref. Airport of Departure":
                                element?.secondaryPrefAirportDeprt
                                  ? element?.secondaryPrefAirportDeprt
                                  : Constants.NA,
                              "Driving License?": element?.drivingLicence ? "Yes" : "No",
                              "Shoe Size": element?.shoeSize ? element?.shoeSize : Constants.NA,
                              "Clothes Size": element?.clothesSize
                                ? element?.clothesSize
                                : Constants.NA,
                            }),
                          }}
                          action={{ route: "", tooltip: "Edit Profile" }}
                          shadow={false}
                        />
                      ))}
                      {profileData[0]?.role?.accessType === defaultData.WEB_ACCESSTYPE ? null : (
                        <MDBox>
                          <MDTypography
                            variant="h5"
                            fontWeight="medium"
                            color="text"
                            textTransform="capitalize"
                            mt={2}
                            ml={2}
                          >
                            {profileData[0]?.nextOfKin.length > 0 ? " Next of Kin" : null}
                          </MDTypography>
                          {profileData[0]?.nextOfKin?.map((kin, index) => (
                            <ProfileInfoCard
                              key={kin?.[Constants.mongooseID]}
                              title={`Kin ${index + 1}`}
                              info={{
                                Name: kin?.kinName || Constants.NA,
                                Relationship: kin?.relationship || Constants.NA,
                                Address: kin
                                  ? `${kin?.kinStreet || ""} ${kin?.kinArea || ""} ${
                                      kin?.kinCity || ""
                                    } ${kin?.kinState || ""} ${kin?.kinCountry || ""} ${
                                      kin?.kinZip || ""
                                    }`
                                  : Constants.NA,
                                Phone: kin?.kinContactNumber
                                  ? `${kin?.kinContactNumber?.in}${kin?.kinContactNumber?.number}`
                                  : Constants.NA,
                              }}
                              action={{ route: "", tooltip: "Edit Profile" }}
                              shadow={false}
                            />
                          ))}
                        </MDBox>
                      )}
                    </Box>
                  )}
                  {profileData[0]?.role.accessType === defaultData.WEB_ACCESSTYPE
                    ? null
                    : value === 2 && (
                        <Box ml={2}>
                          <MDTypography
                            variant="h5"
                            fontWeight="medium"
                            color="text"
                            textTransform="capitalize"
                          >
                            Certificates
                          </MDTypography>
                          <MDBox mt={2}>
                            <CustomTabs
                              value={certificateValue}
                              onChange={handleCertificateTab}
                              aria-label="certificate tabs"
                            >
                              <CustomTab label="All" />
                              <CustomTab label="Approved" />
                              <CustomTab label="Rejected" />
                              <CustomTab label="In Active" />
                            </CustomTabs>
                          </MDBox>
                          {certificateValue === 0 &&
                            renderCertificates(profileData[0]?.userCertificate || [])}
                          {certificateValue === 1 &&
                            renderCertificates(
                              profileData[0]?.userCertificate.filter(
                                (cert) => cert.status === "approved" && cert.isActive
                              )
                            )}
                          {certificateValue === 2 &&
                            renderCertificates(
                              profileData[0]?.userCertificate.filter(
                                (cert) => cert.status === "rejected"
                              )
                            )}
                          {certificateValue === 3 &&
                            renderCertificates(
                              profileData[0]?.userCertificate.filter((cert) => !cert.isActive)
                            )}
                        </Box>
                      )}
                  {profileData[0]?.role.accessType === defaultData.WEB_ACCESSTYPE
                    ? null
                    : value === 3 && (
                        <Box>
                          {profileData?.map((element) => (
                            <ProfileInfoCard
                              key={element?.[Constants.MONGOOSE_ID]}
                              title="Contractual Details"
                              info={{
                                "Passport/ID": {
                                  name: element?.contractualDetail?.passport
                                    ? element?.contractualDetail?.passport
                                    : Constants.NA,
                                },
                                "National Identification Number (BSN/NINO/SSN etc.)": element
                                  ?.contractualDetail?.nationalIdentificationNumber
                                  ? element?.contractualDetail?.nationalIdentificationNumber
                                  : Constants.NA,
                                "Date of Birth": element?.contractualDetail?.birthDate
                                  ? moment(element?.contractualDetail?.birthDate).format(
                                      "DD-MM-YYYY"
                                    )
                                  : Constants.NA,
                                "Place of Birth": element?.contractualDetail?.birthPlace
                                  ? element?.contractualDetail?.birthPlace
                                  : Constants.NA,
                                "Employment Type": element?.contractualDetail?.employmentType
                                  ? element?.contractualDetail?.employmentType
                                  : Constants.NA,
                                ...(profileData[0]?.contractualDetail?.employmentType !==
                                  "internal-employee" && {
                                  "Company Name": element?.contractualDetail?.companyName
                                    ? element?.contractualDetail?.companyName
                                    : Constants.NA,
                                  "Company Registration Nr.": element?.contractualDetail
                                    ?.companyRegistrationNumber
                                    ? element?.contractualDetail?.companyRegistrationNumber
                                    : Constants.NA,
                                  "Company VAT Nr.": element?.contractualDetail?.companyVATNumber
                                    ? element?.contractualDetail?.companyVATNumber
                                    : Constants.NA,
                                  "Company Address": element?.contractualDetail?.companyAddress
                                    ? element?.contractualDetail?.companyAddress
                                    : Constants.NA,
                                }),
                                "Bank Name": element?.contractualDetail?.bankName
                                  ? element?.contractualDetail?.bankName
                                  : Constants.NA,
                                "Account Name Holder": element?.contractualDetail?.accountHolderName
                                  ? element?.contractualDetail?.accountHolderName
                                  : Constants.NA,
                                "Bank Account Nr (IBAN)": element?.contractualDetail?.bankAccount
                                  ? element?.contractualDetail?.bankAccount
                                  : Constants.NA,
                                "BIC/SWIFT": element?.contractualDetail?.bicSwift
                                  ? element?.contractualDetail?.bicSwift
                                  : Constants.NA,
                              }}
                              action={{ route: "", tooltip: "Edit Profile" }}
                              onIconClick={() =>
                                handleImageFullView(
                                  element?.contractualDetail?.identityProof[0]?.url
                                )
                              }
                              shadow={false}
                            />
                          ))}
                        </Box>
                      )}
                  {profileData[0]?.role.accessType === defaultData.WEB_ACCESSTYPE
                    ? null
                    : value === 4 && (
                        <Box>
                          <MDTypography
                            mt={2}
                            ml={2}
                            mb={2}
                            variant="h5"
                            fontWeight="medium"
                            color="text"
                            textTransform="capitalize"
                          >
                            Medical
                          </MDTypography>
                          {profileData[0]?.medical && profileData[0]?.medical.length > 0 ? (
                            profileData[0]?.medical.map((item) => (
                              <MDBox
                                key={item?.questionId}
                                display="flex"
                                alignContent="center"
                                flexDirection="column"
                              >
                                <MDBox
                                  ml={2}
                                  display="flex"
                                  alignContent="center"
                                  py={2}
                                  pr={2}
                                  sx={{ borderBottom: "1px solid #E0E6F5" }}
                                >
                                  <MDBox display="flex" flexDirection="column" width="65%">
                                    <MDTypography
                                      textTransform="capitalize"
                                      sx={{
                                        fontSize: pxToRem(16),
                                        fontWeight: "500",
                                        color: "#667085",
                                      }}
                                    >
                                      {item.title}
                                    </MDTypography>
                                    <MDTypography
                                      textTransform="capitalize"
                                      sx={{
                                        fontSize: pxToRem(14),
                                        fontWeight: "400",
                                        color: "#667085",
                                      }}
                                    >
                                      {item.description}
                                    </MDTypography>
                                  </MDBox>
                                  <MDTypography
                                    sx={{
                                      fontSize: pxToRem(16),
                                      fontWeight: "600",
                                      color: "#191D31",
                                    }}
                                  >
                                    {item.answer ? "Yes" : "No"}
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                            ))
                          ) : (
                            <MDTypography
                              mt={2}
                              ml={2}
                              variant="h6"
                              fontWeight="medium"
                              color="text"
                              textTransform="capitalize"
                            >
                              No Medical data available
                            </MDTypography>
                          )}
                        </Box>
                      )}
                  {profileData[0]?.role.accessType === defaultData.WEB_ACCESSTYPE
                    ? null
                    : value === 5 && (
                        <Box>
                          {profileData?.map((element) => (
                            <ProfileInfoCard
                              key={element?.[Constants.MONGOOSE_ID]}
                              title="GDPR"
                              info={{
                                "Grant permission to use information for business purposes": element
                                  ?.gdpr[0]?.answer
                                  ? "Yes"
                                  : "No",
                                "Grant permission to use e-mail for communication": element?.gdpr[1]
                                  ?.answer
                                  ? "Yes"
                                  : "No",
                              }}
                              action={{ route: "", tooltip: "Edit Profile" }}
                              shadow={false}
                            />
                          ))}
                        </Box>
                      )}
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
      ) : (
        <MDBox py={5} mt={30} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="info" />
        </MDBox>
      )}
      <Personaldetails
        openPersonal={openEdit}
        setOpenPersonal={setOpenEdit}
        title="Update Profile"
        pdata={profileData}
        update={update}
        setUpdate={setUpdate}
        roleOptions={roleOptions}
        profileFunctionOptions={profileFunctionOptions}
      />
      <FullScreenImageComponent
        fullScreenImage={fullScreenImage}
        handleCloseFullView={handleCloseFullView}
        src={fullScreenImage}
      />
    </DashboardLayout>
  );
}

export default Overview;
