import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import { Provider } from "react-redux";
import Store, { persistor } from "redux/Store/Store";
import { PersistGate } from "redux-persist/integration/react";
import { FlagsProvider } from "flagged";
import { FeatureTags } from "utils/Constants";

ReactDOM.render(
  <Provider store={Store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <FlagsProvider
            features={{
              [FeatureTags.SHIFT_DETAILS]: process.env.REACT_APP_DAL_FF === "true",
              [FeatureTags.SETUP_REPORT]: process.env.REACT_APP_SETUP_REPORT === "true",
              [FeatureTags.ROLE_MANAGEMENT]: process.env.REACT_APP_ROLE_MANAGEMENT === "true",
              [FeatureTags.SETUP_EQUIPMENT]: process.env.REACT_APP_SETUP_EQUIPMENT === "true",
              [FeatureTags.WAREHOUSE]: process.env.REACT_APP_SETUP_WAREHOUSE === "true",
              [FeatureTags.NOTIFICATION]: process.env.REACT_APP_SYSTEM_NOTIFICATION === "true",
              [FeatureTags.CERTIFICATE]: process.env.REACT_APP_SYSTEM_CERTIFICATE === "true",
              [FeatureTags.TRAININGMATRIX]: process.env.REACT_APP_SYSTEM_TRAININGMATRIX === "true",
              [FeatureTags.PROJECT_EQUIPMENT]: process.env.REACT_APP_PROJECT_EQUIPMENT === "true",
              [FeatureTags.PROJECT_TRACKER]: process.env.REACT_APP_PROJECT_TRACKER === "true",
              [FeatureTags.TOOLBOX_TALK]: process.env.REACT_APP_TOOLBOX_TALK === "true",
            }}
          >
            <App />
          </FlagsProvider>
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
