import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  report: {},
  shift: {},
  qhse: {
    dynamicFilters: [],
    staticFilters: [],
  },
  trainingMatrix: {},
  projectTracker: {},
  reportSetup: {},
};

const filtersSlice = createSlice({
  name: "filtersSlice",
  initialState,
  reducers: {
    setStoreFilters: (state, action) => {
      const { module, filters } = action.payload;
      state[module] = filters;
    },

    qhseStoreFilters: (state, action) => {
      const { module, filters, filterType } = action.payload;
      if (module === "qhse") {
        if (filterType === "dynamic") {
          state.qhse.dynamicFilters = filters;
        } else if (filterType === "static") {
          state.qhse.staticFilters = filters;
        }
      }
    },

    resetFilters: (state, action) => {
      state[action.payload.module] = {};
    },

    resetAllFilters: () => initialState,
  },
});

// Export actions
export const { setStoreFilters, qhseStoreFilters, resetFilters, resetAllFilters } =
  filtersSlice.actions;

// Export the reducer
export default filtersSlice.reducer;
