import { useEffect, useState } from "react";

// Material Components
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import { Tooltip } from "@mui/material";

// Common Components
import Author from "components/Table/Author";
import MDTypography from "components/MDTypography";

// Assets
import Pdf from "assets/images/pdf.svg";
import Frame from "assets/images/Frame.svg";

// Utils
import Constants, { defaultData, Icons } from "utils/Constants";

// 3rd party Libraries
import moment from "moment";

// Methods
import { downloadFile } from "utils/methods/methods";

export default function ProjectDocumentData(
  documentList,
  handleOpenNewModal,
  setModalType,
  editLists,
  setEditLists,
  handleDelete,
  handleViewImage
) {
  const [rows, setRows] = useState([]);
  const handleEdit = (item) => {
    setModalType("Update");
    setEditLists({ ...editLists, projectDocument: item });
    handleOpenNewModal("Project Document");
  };

  useEffect(() => {
    if (documentList) {
      const list = documentList?.map((item) => {
        const temp = {
          documentName: <Author name={item.title} style={{ textTransform: "capitalize" }} />,
          documentNumber: <Author name={item.documentNumber} />,
          documentType: (
            <Author name={item.type.replace(/_/g, " ")} style={{ textTransform: "capitalize" }} />
          ),
          document: (
            <MDBox
              display="flex"
              sx={{ cursor: "pointer" }}
              onClick={() => handleViewImage(item.document[item.document.length - 1]?.url)}
            >
              <img
                src={
                  item.document?.[item.document.length - 1]?.url.includes(".pdf")
                    ? Pdf
                    : item.document[item.document.length - 1]?.url || Frame
                }
                alt="Preview"
                height="60px"
                width="60px"
                style={{
                  border: "1px solid #D0D5DD",
                  borderRadius: "8px",
                  marginTop: "5px",
                }}
              />

              <MDBox ml={1}>
                {item.document[item.document.length - 1]?.name &&
                item.document[item.document.length - 1]?.name?.length >
                  defaultData.MEDIUM_CONTENT_LENGTH ? (
                  <MDTypography
                    display="block"
                    variant="caption"
                    sx={{ textTransform: "capitalize", marginTop: "5px" }}
                  >
                    <Tooltip title={item.document[item.document.length - 1]?.name}>
                      <span>{`${item.document[item.document.length - 1]?.name.slice(
                        0,
                        defaultData.MEDIUM_CONTENT_LENGTH
                      )}...`}</span>
                    </Tooltip>
                  </MDTypography>
                ) : (
                  <MDTypography
                    display="block"
                    variant="caption"
                    sx={{ textTransform: "capitalize", marginTop: "5px" }}
                  >
                    {item.document[item.document.length - 1]?.name}
                  </MDTypography>
                )}
                {item.document[item.document.length - 1]?.date && (
                  <MDTypography display="block" variant="caption" color="text">
                    {`Upload Date: ${moment(item.document[item.document.length - 1]?.date).format(
                      defaultData.WEB_DATE_FORMAT
                    )}`}
                  </MDTypography>
                )}
                {item.document[item.document.length - 1]?.version && (
                  <MDTypography display="block" variant="caption" color="text">
                    {`Version: ${item.document[item.document.length - 1]?.version}`}
                  </MDTypography>
                )}
              </MDBox>
            </MDBox>
          ),

          action: (
            <MDBox>
              <Icon
                fontSize="medium"
                sx={{ cursor: "pointer" }}
                onClick={(event) =>
                  downloadFile(
                    item.document[item.document.length - 1]?.url,
                    event,
                    item.document[item.document.length - 1]?.name
                  )
                }
              >
                {Icons.DOWNLOAD}
              </Icon>
              &nbsp;
              <Icon
                color="secondary"
                fontSize="medium"
                onClick={() => handleEdit(item)}
                sx={{ cursor: "pointer" }}
              >
                {Icons.EDIT}
              </Icon>{" "}
              &nbsp;
              <Icon
                color="secondary"
                fontSize="medium"
                sx={{ cursor: "pointer" }}
                onClick={() => handleDelete("Project Document", item[Constants.MONGOOSE_ID])}
              >
                {Icons.DELETE}
              </Icon>
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [documentList]);

  return {
    ProjectDocumentColumns: [
      { Header: "Document", accessor: "document", width: "20%", align: "left" },
      { Header: "Document Name", accessor: "documentName", width: "20%", align: "left" },
      { Header: "Document Number", accessor: "documentNumber", width: "20%", align: "left" },
      { Header: "Document Type", accessor: "documentType", width: "20%", align: "left" },
      { Header: "Action", accessor: "action", width: "20%", align: "center" },
    ],
    ProjectDocumentRows: rows,
  };
}
