// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";
import { Colors } from "utils/Constants";
import FontComponent from "components/Responsive/fonts";

function DataTableHeadCell({
  width,
  children,
  sorted,
  align,
  backgroundColor,
  textColor,
  isSticky,
  index,
  ...rest
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const fontSize = FontComponent({ sizes: {} });

  return (
    <MDBox
      component="th"
      width={width}
      py={2}
      px={3}
      sx={({ borders: { borderWidth } }) => ({
        borderRight: !isSticky && `${borderWidth[1]} solid rgba(235, 239, 249, 0.1)`,
        borderBottom: !isSticky && `${borderWidth[1]} solid rgba(235, 239, 249, 0.1)`,
        backgroundColor: backgroundColor || Colors.PRIMARY,
        position: isSticky && "sticky",
        left: isSticky && index === 1 ? 0 : 198,
        zIndex: isSticky ? 10 : 0,
        boxShadow: isSticky && `inset -${borderWidth[1]} 1px 1px #ebeff91a`,
      })}
    >
      <MDBox
        {...rest}
        position="relative"
        textAlign={align}
        color={textColor || (darkMode ? Colors.WHITE : Colors.WHITE)}
        sx={({ typography: { fontWeightBold } }) => ({
          fontSize,
          fontWeight: fontWeightBold,
          cursor: sorted && "pointer",
          userSelect: sorted && "none",
        })}
      >
        {children}
        {sorted && (
          <MDBox
            position="absolute"
            top={0}
            right={align !== "right" ? "16px" : 0}
            left={align === "right" ? "-5px" : "unset"}
            sx={({ typography: { size } }) => ({
              fontSize: size.lg,
            })}
          >
            <MDBox
              position="absolute"
              top={-6}
              color={sorted === "asce" ? "text" : "secondary"}
              opacity={sorted === "asce" ? 1 : 0.5}
            >
              <Icon>arrow_drop_up</Icon>
            </MDBox>
            <MDBox
              position="absolute"
              top={0}
              color={sorted === "desc" ? "text" : "secondary"}
              opacity={sorted === "desc" ? 1 : 0.5}
            >
              <Icon>arrow_drop_down</Icon>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
  backgroundColor: Colors.PRIMARY,
  textColor: Colors.WHITE,
  isSticky: false,
  index: 1,
};

// Typechecking props for the DataTableHeadCell
DataTableHeadCell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  sorted: PropTypes.oneOf([false, "none", "asce", "desc"]),
  align: PropTypes.oneOf(["left", "right", "center"]),
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  isSticky: PropTypes.bool,
  index: PropTypes.number,
};

export default DataTableHeadCell;
