/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from "react";

// Custom Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Author from "components/Table/Author";
import FontComponent from "components/Responsive/fonts";

// MUI Components
import { IconButton } from "@mui/material";

// Constants
import Constants, { Icons, defaultData } from "utils/Constants";

export default function Data(setupReport, handleView, handleEdit, handleDelete, published) {
  const [rows, setRows] = useState([]);
  const fontSize = FontComponent({ sizes: {} });

  useEffect(() => {
    if (setupReport.loading === Constants.FULFILLED) {
      const list = setupReport.report.reportQuestions.map((item, index) => {
        const tempAnswers = item.answerTypes.map((a) => a.parameterType.name).join(", ");
        const temp = {
          srNo: (
            <MDTypography variant="caption" color="text" sx={{ fontSize }}>
              {index + 1}
            </MDTypography>
          ),
          question: <Author name={item?.title} maxContent={defaultData.MEDIUM_CONTENT_LENGTH_2} />,
          answers: <Author name={tempAnswers} />,
          sortOrder: <Author name={item?.sortOrder} />,
          duration: <Author name={item?.duration} />,
          weightage: <Author name={item?.weight} />,
          action: (
            <MDBox>
              <IconButton
                aria-label="edit setupReport type"
                color="info"
                onClick={() => handleView(item)}
              >
                {Icons.VIEW}
              </IconButton>
              <IconButton
                aria-label="edit setupReport type"
                color="error"
                onClick={() => handleEdit(item)}
                disabled={published === 1}
                style={{ opacity: published === 1 ? 0.5 : 1 }}
              >
                {Icons.EDIT}
              </IconButton>
              <IconButton
                aria-label="delete setupReport type"
                color="error"
                onClick={() => handleDelete(item[Constants.MONGOOSE_ID])}
                disabled={published === 1}
                style={{ opacity: published === 1 ? 0.5 : 1 }}
              >
                {Icons.DELETE}
              </IconButton>
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [setupReport]);

  return {
    columns: [
      { Header: "No.", accessor: "srNo", width: "5%", align: "left" },
      { Header: "Question", accessor: "question", width: "40%", align: "left" },
      { Header: "Answers", accessor: "answers", align: "left" },
      { Header: "Sort Order", accessor: "sortOrder", width: "5%", align: "center" },
      { Header: "Duration (hrs)", accessor: "duration", width: "5%", align: "center" },
      { Header: "Weightage (%)", accessor: "weightage", width: "2%", align: "center" },
      { Header: "Action", accessor: "action", width: "10%", align: "left" },
    ],
    rows,
  };
}
