import pxToRem from "assets/theme/functions/pxToRem";

const TitleBreakPoint = {
  baseTitleBreakPoint: {
    xs: pxToRem(14),
    sm: pxToRem(16),
    md: pxToRem(16),
    lg: pxToRem(16),
    xl: pxToRem(16),
    xxl: pxToRem(16),
    fhd: pxToRem(18),
    uhd: pxToRem(18),
  },
  smallTitleBreakPoint: {
    xs: pxToRem(12),
    sm: pxToRem(14),
    md: pxToRem(14),
    lg: pxToRem(14),
    xl: pxToRem(14),
    xxl: pxToRem(14),
    fhd: pxToRem(16),
    uhd: pxToRem(16),
  },
  smallTitleBreakPoint1: {
    xs: pxToRem(13),
    sm: pxToRem(15),
    md: pxToRem(15),
    lg: pxToRem(15),
    xl: pxToRem(15),
    xxl: pxToRem(15),
    fhd: pxToRem(17),
    uhd: pxToRem(17),
  },
  extraSmallTitleBreakPoint: {
    xs: pxToRem(10),
    sm: pxToRem(12),
    md: pxToRem(12),
    lg: pxToRem(12),
    xl: pxToRem(12),
    xxl: pxToRem(12),
    fhd: pxToRem(14),
    uhd: pxToRem(14),
  },
};

export default TitleBreakPoint;
