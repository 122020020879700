import { createSlice } from "@reduxjs/toolkit";
import { resetStateThunk } from "redux/Thunks/Authentication";
import {
  EquipmentTypeThunk,
  EquipmentCategoryThunk,
  EquipmentHSCodeThunk,
  certificateListTypeThunk,
  weightformListThunk,
  quantitytypeListThunk,
  currencyunitListThunk,
} from "redux/Thunks/Equipment";
import Constants from "utils/Constants";

const initialState = {
  loading: Constants.IDLE,
  typeList: [],
  categoryList: [],
  currencyUnitList: [],
  weightFormList: [],
  quantityTypeList: [],
  hsCodeList: [],
  certificateTypeList: [],
};

export const equipmentSetupSlice = createSlice({
  name: "equipmentSetupSlice",
  initialState,
  reducers: {
    updateEquipmentType(state, action) {
      const updatedList = state.typeList.map((item) => {
        if (item[Constants.MONGOOSE_ID] === action.payload[Constants.MONGOOSE_ID]) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });
      return {
        ...state,
        typeList: updatedList,
      };
    },

    updateSetupEquipment(state, action) {
      let cardType = "";

      switch (action.payload.cardType) {
        case "equipmentCategory":
          cardType = "categoryList";
          break;
        case "hsCode":
          cardType = "hsCodeList";
          break;
        case "certificatetype":
          cardType = "certificateTypeList";
          break;
        case "weightForm":
          cardType = "weightFormList";
          break;
        case "quantityType":
          cardType = "quantityTypeList";
          break;
        case "currencyUnit":
          cardType = "currencyUnitList";
          break;
        default:
          return state;
      }
      const stateData = JSON.parse(JSON.stringify(state));
      const updatedList = stateData[cardType].map((item) => {
        if (item[Constants.MONGOOSE_ID] === action.payload.id) {
          return {
            ...item,
            ...action.payload.body,
          };
        }
        return item;
      });
      return {
        ...state,
        [cardType]: updatedList,
      };
    },

    deleteEquipmentType: (state, action) => {
      let cardType = "";
      switch (action.payload.cardType) {
        case "equipmentCategory":
          cardType = "categoryList";
          break;
        case "equiptype":
          cardType = "typeList";
          break;
        case "hsCode":
          cardType = "hsCodeList";
          break;
        case "certificatetype":
          cardType = "certificateTypeList";
          break;
        case "weightForm":
          cardType = "weightFormList";
          break;
        case "quantityType":
          cardType = "quantityTypeList";
          break;
        case "currencyUnit":
          cardType = "currencyUnitList";
          break;
        default:
          return state;
      }
      state[cardType] = state[cardType].filter(
        (item) => item?.[Constants.MONGOOSE_ID] !== action.payload.id
      );
      return state;
    },

    reloadData(state) {
      state.loading = Constants.PENDING;
    },
  },

  extraReducers: {
    // Equipment type
    [EquipmentTypeThunk.pending]: (state) => {
      if (state.typeList.length === 0) state.loading = Constants.PENDING;
    },
    [EquipmentTypeThunk.fulfilled]: (state, { payload }) => {
      state.loading = Constants.FULFILLED;
      if (payload.type === "add") state.typeList = payload.data.data;
      else state.typeList.push(...payload.data.data);
    },
    [EquipmentTypeThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },

    // Equipment category
    [EquipmentCategoryThunk.pending]: (state) => {
      if (state.categoryList.length === 0) state.loading = Constants.PENDING;
    },
    [EquipmentCategoryThunk.fulfilled]: (state, { payload }) => {
      state.loading = Constants.FULFILLED;
      if (payload.type === "add") state.categoryList = payload.data.data;
      else state.categoryList.push(...payload.data.data);
    },
    [EquipmentCategoryThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },

    // HSCode
    [EquipmentHSCodeThunk.pending]: (state) => {
      if (state.hsCodeList.length === 0) state.loading = Constants.PENDING;
    },
    [EquipmentHSCodeThunk.fulfilled]: (state, { payload }) => {
      state.loading = Constants.FULFILLED;
      if (payload.type === "add") state.hsCodeList = payload.data.data;
      else state.hsCodeList.push(...payload.data.data);
    },
    [EquipmentHSCodeThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },

    // Certificate type
    [certificateListTypeThunk.pending]: (state) => {
      if (state.certificateTypeList.length === 0) state.loading = Constants.PENDING;
    },
    [certificateListTypeThunk.fulfilled]: (state, { payload }) => {
      state.loading = Constants.FULFILLED;
      if (payload.type === "add") state.certificateTypeList = payload.data.data;
      else state.certificateTypeList.push(...payload.data.data);
    },
    [certificateListTypeThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },

    // Weight form
    [weightformListThunk.pending]: (state) => {
      if (state.weightFormList.length === 0) state.loading = Constants.PENDING;
    },
    [weightformListThunk.fulfilled]: (state, { payload }) => {
      state.loading = Constants.FULFILLED;
      if (payload.type === "add") state.weightFormList = payload.data.data;
      else state.weightFormList.push(...payload.data.data);
    },
    [weightformListThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },

    // Quantity type
    [quantitytypeListThunk.pending]: (state) => {
      if (state.quantityTypeList.length === 0) state.loading = Constants.PENDING;
    },
    [quantitytypeListThunk.fulfilled]: (state, { payload }) => {
      state.loading = Constants.FULFILLED;
      if (payload.type === "add") state.quantityTypeList = payload.data.data;
      else state.quantityTypeList.push(...payload.data.data);
    },
    [quantitytypeListThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },

    // Currency Unit
    [currencyunitListThunk.pending]: (state) => {
      if (state.currencyUnitList.length === 0) state.loading = Constants.PENDING;
    },
    [currencyunitListThunk.fulfilled]: (state, { payload }) => {
      state.loading = Constants.FULFILLED;
      if (payload.type === "add") state.currencyUnitList = payload.data.data;
      else state.currencyUnitList.push(...payload.data.data);
    },
    [currencyunitListThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },
  },

  [resetStateThunk.fulfilled]: (state) => {
    state.loading = Constants.IDLE;
    state.typeList = [];
    state.categoryList = [];
    state.hsCodeList = [];
    state.certificateTypeList = [];
    state.weightFormList = [];
    state.quantityTypeList = [];
    state.currencyUnitList = [];
  },
});

export const { reloadData, updateEquipmentType, deleteEquipmentType, updateSetupEquipment } =
  equipmentSetupSlice.actions;
export default equipmentSetupSlice.reducer;
